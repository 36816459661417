import React, { Component } from 'react';
import Header1 from '../directives/header1'
// import Leftsidebar from '../directives/leftsidebar'
// import Footer from '../directives/footer'
import axios from 'axios'
import config from '../config/config'
import Cookies from 'js-cookie';
import ReactDatatable from '@ashvin27/react-datatable'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Link } from 'react-router-dom';
// import { ToastContainer, toast } from 'react-toastify';
import copy from 'copy-to-clipboard';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';

export default class transaction extends Component {

    constructor(props) {
        super(props)
        this.state = {
            dashboard_list: '',
            user_name: '',
            getUserPurchaseData: [],
            copied: false,
            tokenPrice:0,
        }
        this.loginData = (!Cookies.get('loginSuccess')) ? [] : JSON.parse(Cookies.get('loginSuccess'));

        this.columns = [
            // {
            //     key: '#',
            //     text: 'Sr. No.',
            //     cell: (row, index) => index + 1


            // },
            //   {
            //     key: "status",
            //     text: "Status",
            //     cell: (item) => {
            //         return (
            //             <p style={{paddingLeft:'20px'}}>  {(item.status=== 0)?
            //                 <img src="assets/images/watch.png" width="34px"  />: 
            //   item.status=== 1? <img src="assets/images/check.png" width="34px" />:<img src="assets/images/alert.png" width="34px" />}

            //             </p>
            //             );
            //     }
            // },


            {
                key: "datetime",
                text: "Date",
                cell: (item) => {
                    return (
                        <span className="sub sub-s2">{(item.datetime.replace('.000Z', '')).replace('T', ' ')}</span>

                    );
                }
            },
            {
                key: "token",
                text: "Token",
                cell: (item) => {
                    return (
                        <p style={{ paddingLeft: '22px' }}>{parseFloat(item.token).toFixed(2)}

                        </p>
                    );
                }
            },
            {
                key: "transaction_type",
                text: "Transaction Type",
                cell: (item) => {
                    return (
                        <p style={{ paddingLeft: '22px' }}>{item.transaction_type}</p>
                    );
                }
            },
            {
                key: "amount",
                text: "Amount",
                cell: (item) => {
                    return (
                        <span>

                            {/* {item.token == 0 ?
                                <>
                                    {parseFloat(item.amount_eth).toFixed(6) + ' ' + item.currency + " ("}
                                    <span className="lead">${!parseFloat(item.usd_amount).toFixed(2) || item.usd_amount === '' ? '0.00' : parseFloat(item.usd_amount).toFixed(2)})</span>
                                </>
                                :
                                <>
                                    <span className="lead">${!parseFloat(item.usd_amount).toFixed(2) || item.usd_amount === '' ? '0.00' : parseFloat(item.usd_amount).toFixed(2)}</span>
                                </>
                            } */}
                            ${item.usd_amount}
                        </span>
                    );
                }
            },
            // {
            //     key: "to_address",
            //     text: "To Address",
            //     cell: (item) => {

            //         return (
            //             <>
            //                 {item.to_address === '' || item.to_address === null || item.to_address === undefined
            //                     ? <span style={{paddingLeft:'50px'}}>-</span>  :
            //                     <span title={item.to_address}>{item?.to_address.toString().substring(0, 5) + '...' + item?.to_address.toString().substr(item?.to_address.length - 5)}
            //                         &nbsp;    <i onClick={this.copyToClipboard.bind(this, item.to_address)} className="fa fa-copy" style={{ color: 'purple' }}></i>

            //                     </span>}

            //             </>
            //         )
            //     }

            // },
            {
                key: "token",
                text: "Payment Status",
                cell: (item) => {
                    return (
                        <>
                            <span style={{ paddingLeft: '36px' }} >
                                {item.status === 0 ? <span style={{ color: '#f4b425' }}>In-progress</span> :
                                    item.status === 1 ? <span style={{ color: 'green' }}>Completed</span> :
                                        <span style={{ color: 'red', paddingLeft: '18px' }}>Failed</span>}
                            </span>
                        </>
                    );
                }
            },

            {
                key: "hash",
                text: "Reference",
                cell: (item) => {

                    return (

                        <>
                            <span className=" " >

                                {item.hash === '' || item.hash === null || item.hash === undefined
                                    ?
                                    <span>
                                        {/* {(item?.receipt_url)?item?.receipt_url.toString().substring(0, 6) + '..' + item?.receipt_url.toString().substr(item?.receipt_url.length - 6):'-'}
            &nbsp;    <i onClick={this.copyToClipboard.bind(this, item.receipt_url)} className="fa fa-copy" style={{ color: 'purple' }}></i>  */}
                                    </span>

                                    :

                                    item.currency === 'ETH' ?
                                        <a href={`https://etherscan.io/tx/${item.hash}`} target="_blank">
                                            <span title={item.hash}>{item?.hash.toString().substring(0, 4) + '...' + item?.hash.toString().substr(item?.hash.length - 6)}
                                                &nbsp; <i className="fa fa-external-link"></i>
                                            </span>
                                        </a>
                                        :
                                        <a href={`https://bscscan.com/tx/${item.hash}`} target="_blank">
                                            <span title={item.hash}>{item?.hash.toString().substring(0, 4) + '...' + item?.hash.toString().substr(item?.hash.length - 4)}
                                                &nbsp; <i className="fa fa-external-link"></i>
                                            </span>
                                        </a>

                                }
                            </span>
                        </>
                    )
                }
            },

            // {
            //     key: "name",
            //     text: "Transaction Type"
            // },

            // {
            //     key: "status",
            //     text: "Status",
            //     cell: (item) => {
            //         return (
            //             <>
            //                 {(item.status === 0) ? 'Pending' : 
            //                 (item.status === 1) ? 'Completed' : 
            //                 (item.status === 2) ? 'Cancelled' : 

            //                 ""}


            //             </>
            //         );
            //     }
            // },


            // {
            //     key: "Action",
            //     text: "Action",
            //     cell: (item) => {
            //         return (
            //             <>

            //          <button type="submit"   onClick={this.deleteUser.bind(this,item)}  data-toggle="tooltip" data-target="#responsive-modal1"  data-original-title="Close" className=" btn-danger"> <i className="fa fa-close m-r-10"></i> </button>

            //             </>
            //         );
            //     }
            // },


        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            pagination: 'advance',
            button: {
                excel: false,
                print: false

            }
        }

    }

    async componentDidMount() {
        if (!Cookies.get('loginSuccess')) {
            window.location.href = `${config.baseUrl}`
            return false;
        }
        this.dashboardList();
        // this.getProfilePicAPI();
        this.getuserTransactionListAPI();
        this.getBNBAPI()
        this.getUSDAPI()
        this.getTokenPriceAPI()

        if (Cookies.get('buyNowProcess')) {
            Cookies.set('buyNowProcess', "");
            var external_id = Cookies.get('external_id');
            Cookies.set('external_id', '');
            if (window.location.href.split('=')[1] > 0) {
                this.successPayment();
            }
            else if (window.location.href.split('?')[1]) {
                this.failedPayment(external_id);
            }

        }
    }

    async failedPayment(external_id) {
        await axios({
            method: 'post',
            url: `${config.apiUrl}onlineTrxCanceled`,
            headers: { "Authorization": this.loginData?.Token },
            data: { "user_id": this.loginData.data.id, 'external_id': external_id, 'email' : this.loginData.data.user_email }
        });
        var willSearch = await Swal.fire({
            title: 'Payment declined!',
            text: 'Something went wrong! please try again.',
            icon: 'error',
            width: 500,
            confirmButtonColor: '#3085d6',
            allowOutsideClick: false,
            confirmButtonText: 'Continue',
        });
        window.location.href = `${config.baseUrl}transaction`;
    }
    async successPayment() {
        var willSearch = await Swal.fire({
            title: 'Payment successful!',
            text: 'Congratulations, you are successfully completed the payment.',
            icon: 'success',
            width: 500,
            confirmButtonColor: '#3085d6',
            allowOutsideClick: false,
            confirmButtonText: 'View Transactions',
        });
        window.location.href = `${config.baseUrl}transaction`;
    }

    async dashboardList() {
        await axios({
            method: 'post',
            url: `${config.apiUrl}getuserbalance`,
            data: { "user_id": this.loginData.data.id, 'email' : this.loginData.data.user_email },
            headers: { "Authorization": this.loginData?.Token }  
        })
            .then(result => {
                console.log(result.data);
                if (result.data.success === true) {
                    this.setState({
                        dashboard_list: result.data.response
                    })

                }

                else if (result.data.success === false) {

                }
            })

            .catch(err => {
            })
    }

    //=======================================  User details  =====================

    async getuserTransactionListAPI() {
        await axios({
            method: 'post',
            url: `${config.apiUrl}getUserTransactionDetail`,
            data: { "user_id": this.loginData.data.id, 'email' : this.loginData.data.user_email },
            headers: { "Authorization": this.loginData?.Token }  
        })
            .then(result => {
                if (result.data.success === true) {
                    this.setState({
                        getUserPurchaseData: result.data.response,

                    })

                }
                else if (result.data.success === false) {
                }
            }).catch(err => {
            });
    }

    //=======================================  get 1 Token Price Amount   =====================


    async getTokenPriceAPI() {
        await axios({
            method: 'post',
            url: `${config.apiUrl}getfeeDetails`,
            headers: { "Authorization": this.loginData?.Token },
            data: { "email": this.loginData.data.user_email, "user_id": this.loginData.data?.id },
        }).then(response => {
            if (response.data.success === true) {
                this.setState({
                    tokenPrice: response.data.response.token_price,
                    tokenfee: response.data.response.token_fee
                })

            }
        })
    }

    //=======================================  BNB Price  =====================

    async getBNBAPI() {

        // await axios({
        //     method: 'get',
        //     url: `${config.apiUrl}bnbprice`,

        // }).then(response => {
        //     if (response.data.success === true) {
        //         this.setState({
        //             bnbprice: response.data.response.BNB
        //         })
        //     }
        // })
    }

    //===================================  get USD Amount    =============================

    async getUSDAPI() {
        // await axios({
        //     method: 'get',
        //     url: `${config.apiUrl}usdprice`,

        // }).then(response => {
        //     if (response.data.success === true) {
        //         this.setState({
        //             ethprice: response.data.response.ETH
        //         })

        //     }
        // })
    }





    // async getProfilePicAPI() {
    //     await axios({
    //         method: 'post',
    //         url: `${config.apiUrl}getuserprofile`,
    //         headers: { "Authorization": this.loginData?.Token },
    //         data: { "email": this.loginData.data.user_email }
    //     }).then(response => {
    //         if (response.data.success === true) {
    //             this.setState({
    //                 profileData: response.data.response
    //             })
    //         }
    //     })
    // }

    //================================================  Update information API integrate  =============
    handleImagePreviewAvatar = (e) => {
        let image_as_base64 = URL.createObjectURL(e.target.files[0])
        let image_as_files = e.target.files[0];
        this.setState({
            image_preview: image_as_base64,
            image_file: image_as_files,
        })
    }

    //================================================  Update information API integrate  =============
    handleImagePreviewBanner = (e) => {
        let image_as_base64 = URL.createObjectURL(e.target.files[0])
        let image_as_files = e.target.files[0];
        this.setState({
            image_preview1: image_as_base64,
            image_file1: image_as_files,
        })
    }

    // async submitForm(e) {
    //     e.preventDefault()
    //     let formData = new FormData();
    //     formData.append('email', this.state.profileData.email);
    //     formData.append('profile_pic', this.state.image_file);
    //     formData.append('banner', this.state.image_file1);
    //     formData.append('user_name', this.state.profileData.user_name);

    //     axios({
    //         method: 'post',
    //         url: `${config.apiUrl}updateuserprofile`,
    //         headers: { "Authorization": this.loginData?.Token },
    //         data: formData
    //     })
    //         .then(response => {
    //             if (response.data.success === true) {
    //                 toast.success(response.data.msg, {
    //                     position: toast.POSITION.TOP_CENTER
    //                 });
    //                 this.getProfilePicAPI()
    //                 setTimeout(() => {
    //                     window.location.reload()
    //                 }, 1000);
    //             }

    //             else if (response.data.success === false) {
    //                 toast.error(response.data.msg, {
    //                     position: toast.POSITION.TOP_CENTER
    //                 });
    //             }
    //         })

    //         .catch(err => {
    //             toast.error(err?.response?.data?.msg, {
    //                 position: toast.POSITION.TOP_CENTER
    //             });

    //         })
    // }

    //================================================= Update password ========================

    // handleSubmit(e) {
    //     e.preventDefault();
    //     axios({
    //         method: 'post',
    //         url: `${config.apiUrl}changepassword`,
    //         headers: { "Authorization": this.loginData.message },
    //         data: {
    //             email: this.state.profileData.email, currentPassword: this.state.currentPassword,
    //             password: this.state.password, password2: this.state.password2
    //         }
    //     })
    //         .then(result => {

    //             if (result.data.success === true) {
    //                 toast.success(result.data.msg, {
    //                     position: toast.POSITION.TOP_CENTER, autoClose: 1500
    //                 });
    //                 window.location.reload()
    //             }
    //             else if (result.data.success === false) {
    //                 toast.error(result.data.msg, {
    //                     position: toast.POSITION.TOP_CENTER
    //                 });
    //             }
    //         }).catch(err => {
    //             toast.error(err.response.data?.msg, {
    //                 position: toast.POSITION.TOP_CENTER
    //             })
    //         })
    // }



    // async twoAuthenticationAPI() {
    //     await axios({
    //         method: 'post',
    //         url: `${config.apiUrl}getQR`,
    //         headers: { "Authorization": this.loginData?.message },
    //         data: { "user_id": this.loginData.data.id }
    //     }).then(response => {
    //         if (response.data.success === true) {
    //             this.setState({
    //                 twoAuthenticationData: response.data.response
    //             })
    //         }
    //     })
    // }

    //==================================  twoupdateAuthentication ========================

    // async twoAuthenticationVerifyAPI() {
    //     await axios({
    //         method: 'post',
    //         url: `${config.apiUrl}twoAuthenticationVerify `,
    //         headers: { "Authorization": this.loginData?.message },
    //         data: { "user_id": this.loginData.data.id, 'SecretKey': this.state.twoAuthenticationData.SecretKey, 'enableTwoFactor': this.state.twoAuthenticationData.enableTwoFactor }
    //     }).then(response => {
    //         if (response.data.success === true) {
    //             toast.success('2FA Authentication has been enabled successfully!', {
    //                 position: toast.POSITION.TOP_CENTER
    //             });
    //             window.location.reload()
    //         }
    //     }).catch(err => {
    //         toast.error('Token mismatch', {
    //             position: toast.POSITION.TOP_CENTER
    //         })
    //     })
    // }

    copyToClipboard(id) {
        copy(id);

        toast.success("Copied", {

        });


    }


    render() {

        return (


            <>
                <Header1 />
                <Toaster
                    position="top-right"
                    reverseOrder={false} />
                <section className="page-login newdashboard" style={{ backgroundColor: "#e0e8f3" }}>
                    <div id="content-block">

                        <div className="row">
                            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                                <div className="header-menu-tabs">
                                    <a href="" className="aside_logo"><img className="logo-c active be_logo aside_logo" src="images/YUSE_Logo_purple.png" alt="logo" /></a>
                                    <aside className="web_view">
                                        <nav>
                                            <ul>
                                                <Link to={`${config.baseUrl}dashboard`}><li><i class="fa fa-th" aria-hidden="true"></i>Dashboard</li></Link>
                                                <Link to={`${config.baseUrl}transaction`}><li className="active"><i class="fal fa-money-bill-alt"></i>Transactions</li></Link>
                                                <Link to={`${config.baseUrl}authoredit`}><li ><i class="fa fa-user-circle-o" aria-hidden="true"></i>My Profile</li></Link>
                                                <Link to={`${config.baseUrl}supportRequest`}><li><i class="fal fa-user-headset"></i>Support</li></Link>
                                                <Link to={`${config.baseUrl}withdraw`}><li ><i className="fal fa-money"></i>Withdraw</li></Link>
                                                <Link to={`${config.baseUrl}staking`}><li className={this.state.lastSegment == 'staking'? 'active' : '' }><i className="fa fa-object-ungroup"></i>Staking</li></Link>
                                                <Link to={`${config.baseUrl}`}><li><i class="fal fa-home-alt"></i>Home</li></Link>
                                            </ul>
                                        </nav>
                                    </aside>
                                </div>
                            </div>
                            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12">
                                <div className="container-fluid">
                                    <div className="transaction_heading">
                                        <h6 className="heading_anchor">
                                            <a href={`${config.baseUrl}dashboard`}>Dashboard</a> <span style={{ color: '#707070' }}>/ Transaction</span>
                                        </h6>                                        
                                    </div>
                                    <div className="token-transaction bg-white bg-shadow card-full-height" id="transaction_table">

                                        <div className="card-innr p-4">
                                            <div className="card-head has-aside pb-4">
                                                <h4 className="card-title">User Transactions</h4>

                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-12">
                                                    <div className="table-responsive">
                                                        <ReactDatatable
                                                            config={this.config}
                                                            records={this.state.getUserPurchaseData}
                                                            columns={this.columns}
                                                        />

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>


            </>

        )

    }
}