import React, { Component } from 'react';
import axios from 'axios';
import Header from '../directives/header'
import Footer1 from '../directives/footer1'
import config from '../config/config'
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
// import publicIp from 'public-ip';
import toast, { Toaster } from 'react-hot-toast';
import MetaTags from 'react-meta-tags';
const headers = {
  'Content-Type': 'application/json'
};



export default class login extends Component {

  constructor(props) {
    super(props)
    this.state = {
      email: '',
      ip: '',
      password: "",
      passwordIcon: '1',
      spinLoader: '0',
      otpSendPopup: 0,
      sendOTPBtn: 0,
      otpCode: '',
    };
  }

  componentDidMount() {

  }

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({
      spinLoader: '1'
    })

    // const getIp = await axios.get('https://geolocation-db.com/json/')

    // this.setState({
    //   ip: getIp.data.IPv4
    // })

    const { email, password, ip } = this.state;
    const data = this.state
    axios.post(`${config.apiUrl}login`, data, { email, password, ip })
      .then(result => {
        if (result.data.success === true) {

          toast.success(result.data.msg);

          // setTimeout(() => {
            this.setState({
              otpSendPopup: 1,
              spinLoader: '0'
            })
          // }, 2000);
        }
        else if (result.data.success === false) {
          toast.error(result.data?.msg);
          this.setState({
            email: '',
            password: '',
            spinLoader: '0'
          })
        }
      }).catch(err => {
        toast.error(err.response.data?.msg);
        this.setState({
          spinLoader: '0'
        })
      })
  }

  checkOtp = async event => {
    event.preventDefault();
    this.setState({
      sendOTPBtn: '1'
    })

    // const getIp = await axios.get('https://geolocation-db.com/json/')

    // this.setState({
    //   ip: getIp.data.IPv4
    // })

    await axios({
      method: 'post',
      url: `${config.apiUrl}checkOTPForLogin`,
      data: { "email": this.state.email, 'otpCode': this.state.otpCode, 'ip' : this.state.ip }
    }).then(result => {
      if (result.data.success === true) {

        if (!result.data.data.enableTwoFactor) {
          setTimeout(() => {
            toast.success('Login Successfully.');
            setTimeout(() => {
              window.location.href = `${config.baseUrl}dashboard`
              Cookies.set('loginSuccess', result.data);
            }, 1000);
          }, 2500);
        }
        else if (result.data.data.enableTwoFactor === 1) {
          setTimeout(() => {
            window.location.href = `${config.baseUrl}googleauthentication`
          }, 2500);
          Cookies.set('loginSuccessAuth', result.data);
        }
      }

      else if (result.data.success === false) {
        this.setState({
          sendOTPBtn: '0'
        });

        toast.error(result.data?.msg);
      }
    }).catch(err => {
      toast.error(err.response.data?.msg);
      this.setState({
        sendOTPBtn: '0'
      });
    })
  }


  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }


  passwordEye(id) {

    if (id == 1) {
      id = 0
    }
    else if (id == 0) {
      id = 1
    }
    this.setState({
      passwordIcon: id
    })
  }

  closeModel() {
    this.setState({
      otpSendPopup: 0
    })
  }

  render() {

    const { email, password, ip } = this.state;
    return (

      <>


        <div className={this.state.otpSendPopup === 0 ? "modal fade" : "modal fade show"} id="putOnSale" style={{ display: this.state.otpSendPopup === 0 ? 'none' : 'block' }} >
          <div className="modal-dialog send">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="btn-sm close" onClick={this.closeModel.bind(this)}>&times;</button>
              </div>
              <div className="modal-body">
                <div className="card-innr">
                  <div className="row">
                    <div className="col-md-12 ">
                      <div className="input-item input-with-label"><label for="full-name" className="input-item-label">OTP</label>
                        <input className="form-control otp" onChange={this.handleChange.bind(this)} autocomplete="off" name="otpCode" type="text" value={this.state.otpCode} /></div>


                      <div className="gaps-1x"></div>

                      <div className="col-sm-12 text-center">
                        {this.state.sendOTPBtn == 0 ?
                          <button onClick={this.checkOtp} className="btn-primary btn-lg mt-3" >Submit</button>
                          :
                          <button className="btn-primary btn-lg mt-3" disabled>
                            Loading<i className="fa fa-spinner fa-spin validat"></i>
                          </button>
                        }
                      </div>
                      <div className="gaps-2x d-sm-none"></div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ backgroundImage: "url('images/Background_img.png')" }}>
          <Header activeTab="login" />
          <Toaster
            position="top-right"
            reverseOrder={false} />
          <MetaTags>
            <meta name="keywords" content="yuse,yuse ico,yuse token,yuse crypto,yuse token price" />
            <title>Login - Yuse Token</title>
            <meta name="description" content="Welcome to Yuse Token!. Login to with your email ID and password to explore the Yuse token with its price. Visit now!" />
            <h1>Login & Welcome Yuse</h1>

            <link rel='canonica' href='https://yusetoken.io/login' />

          </MetaTags>
          <div id="root" style={{ backgroundImage: 'url(assets/images/Intersection1.png)', backgroundPositionX: 'left' }}  ></div>
          <div id="content-block" style={{ backgroundImage: '' }} >
            <br /><br />

            <div className="container-fluid">
              <div className="container-fluid pad-spc mt-5 mb-0" >
                <div className="container">
                  <div className="row">
                    <div className="limiter">
                      <div className="container-login100" style={{ minHeight: "54vh" }}>
                        <div className="">
                          <form className="login100-form validate-form" autoComplete="on" id="login_form">
                            {/*   <div className="text-center mb-2">
          <img src="images/logo-new.png" align="center" width="196px">
        </div>  */}
                            <span className="login100-form-title p-b-20" style={{ color: 'purple' }}> Welcome <br />
                              <small style={{ fontSize: "17px" }}>Please login to continue</small>
                            </span>
                            <div className="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                              <input className={this.state.email ? "input100 no-autofill-bkg has-val" : "input100 no-autofill-bkg"} type="email" autoComplete="off" name="email" onChange={this.handleChange} value={email} />
                              <span className="focus-input100"></span>
                              <span className="label-input100">Email</span>
                            </div>


                            <div className="wrap-input100 validate-input" data-validate="Password is required"  >
                              <input className={this.state.password ? "input100 no-autofill-bkg has-val" : "input100 no-autofill-bkg"} autoComplete="off" id="password-field" type={this.state.passwordIcon == 1 ? 'password' : 'text'} name="password" onChange={this.handleChange} value={password} />
                              <span className="focus-input100"></span>
                              <span className="label-input100">Password</span>
                              {this.state.passwordIcon == 1 ?
                                <span onClick={this.passwordEye.bind(this, '1')} toggle="#password-field" className="fa fa-fw fa-eye field-icon toggle-password"></span>
                                :
                                <span onClick={this.passwordEye.bind(this, '0')} toggle="#password-field" className="fa fa-fw fa-eye-slash field-icon toggle-password"></span>

                              }
                            </div>



                            <div className="container-login100-form-btn">
                              {this.state.spinLoader === '0' ?
                                <button className="login100-form-btn" type="submit" onClick={this.handleSubmit} disabled={!this.state.email || !this.state.password === ''} >
                                  Login
                                </button> :
                                <button className="login100-form-btn" disabled>
                                  Loading<i className="fa fa-spinner fa-spin validat"></i>
                                </button>
                              }
                            </div>
                            <p className="text-white mb-3">Didn't get the activation email ? Please click on
                              &nbsp;<Link to={`${config.baseUrl}verifyaccount`} target="_blank" style={{ color: "red" }}>Re-Send</Link>.</p>

                            <div className="flex-sb-m w-full p-t-3 p-b-32 mt-2">

                              <a href={`${config.baseUrl}generatepassword`} className="txt1">
                                Forgot Password?
                              </a>
                              <div>
                                <div href="#" className="txt1 mt-2">
                                  Don't have an account? <a href={`${config.baseUrl}register`}>Register</a>
                                </div>
                              </div>
                            </div>

                          </form>
                        </div>
                      </div>
                    </div>



                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
      </>
    )
  }
}