import React, { Component } from 'react';
import axios from 'axios';
import Header1 from '../directives/header1'
import config from '../config/config'
import 'react-toastify/dist/ReactToastify.css';
import toast, { Toaster } from 'react-hot-toast';
import Cookies from 'js-cookie';
import ReactDatatable from '@ashvin27/react-datatable'
import WalletConnectProvider from "@walletconnect/web3-provider";
import { Link } from 'react-router-dom';
import Web3 from 'web3';
import { sendToken } from '../directives/sendToken'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import MetaTags from 'react-meta-tags';
import moment from 'moment';
const headers = {
    'Content-Type': 'application/json'
};

const providerOptions = {
    connector: async (ProviderPackage, options) => {
        const provider = new ProviderPackage(options);
        await provider.enable()
        return provider;
    }
};

const provider = new WalletConnectProvider({
    rpc: {
        97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
        56: "https://bsc-dataseed.binance.org/",
        3: "https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
        1: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
        4: "https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"
    },
});

export default class staking extends Component {
    constructor(props) {
        super(props)
        this.loginData = (!Cookies.get('loginSuccess')) ? [] : JSON.parse(Cookies.get('loginSuccess'));

        // console.log(this.loginData);
        this.state = {
            token_amount: '',
            usd_amount: '',
            staking_period_id: '2',
            spinLoader: '0',
            getStakingList: [],
            myPortfolio: '0',
            AdminWallet: '',
            tokenPrice: 0,
            error: [],
            dashboard_list: '',
            selectType: 1,
            ConnectWalletAddress: [],
            getStakingPeriod: [],
            trxHash : '',
            ip : ''
        };

        this.columns = [
            {
                key: "token_amount",
                text: "Amount",
                cell: (item) => {
                    return (
                        <span>{parseFloat(item.usd_amount / item.token_price).toFixed(2)} YUSE({item.usd_amount}$)</span>
                    );
                }
            },

            {
                key: "staking_duration",
                text: "Period",
                cell: (item) => {
                    return (
                        <span>{item.staking_duration} Days</span>
                    );
                }
            },

            {
                key: "staking_percentage",
                text: "Staking(%)",
                cell: (item) => {
                    return (
                        <span>{item.staking_percentage}% APR</span>
                    );
                }
            },

            {
                key: "total_income",
                text: "Income",
                cell: (item) => {
                    return (
                        <span>${item.totalIncome == null ? 0 : item.totalIncome} </span>
                    );
                }
            },
            {
                key: "created_date",
                text: "Date",
                sortable: true
            }
            ,
            {
                key: "action",
                text: "Status",
                cell: (item) => {
                    return (
                        <>
                            {item.status == 1 ?
                                <kbd class="bg-success">Active</kbd>
                                : <kbd class="bg-success">Complete</kbd>
                            }
                            &nbsp;&nbsp;
                            <a href={`${config.baseUrl}incomeList/` + item.id}>
                                {/* <button className="btn btn-income">Income</button> */}
                                <span class="copy_id" title="copy" style={{ color: '#fff' }}>Income</span>
                            </a>
                        </>
                    );
                }
            },

        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            pagination: 'advance',
            button: {
                excel: false,
                print: false

            }
        }


    }
    componentDidMount() {
        this.getTokenPriceAPI();
        this.getStakingListAPI();
        this.getStakingPeriodAPI();
        this.getTotalPortfolioAPI();
        this.getAdminWalletDetails();
        this.dashboardList()
    }


    async getAdminWalletDetails() {
        await axios({
            method: 'post',
            url: `${config.apiUrl}/getWalletDetails`,
            headers: { "Authorization": this.loginData?.Token },
            data: { "email": this.loginData.data.user_email, "user_id": this.loginData.data?.id }
        }).then(response => {
            if (response.data.success === true) {
                this.setState({
                    AdminWallet: response.data?.response
                })

            }
        })
    }

    async getTotalPortfolioAPI() {
        await axios({
            method: 'post',
            url: `${config.apiUrl}getTotalPortfolio`,
            data: { 'email': this.loginData.data.user_email, "user_id": this.loginData.data.id },
            headers: { "Authorization": this.loginData?.Token },
        }).then(response => {
            if (response.data.success === true) {
                this.setState({
                    myPortfolio: response.data.response,
                })
            }
        })
    }

    async getTokenPriceAPI() {
        await axios({
            method: 'post',
            url: `${config.apiUrl}getfeeDetails`,
            data: { "email": this.loginData.data.user_email, "user_id": this.loginData.data?.id },
            headers: { "Authorization": this.loginData?.Token },
        }).then(response => {
            if (response.data.success === true) {
                this.setState({
                    tokenPrice: response.data.response.token_price,
                    tokenPriceInUSD: parseFloat(1 / response.data.response.token_price)
                })

            }
        })
    }

    async getStakingPeriodAPI() {
        await axios({
            method: 'post',
            url: `${config.apiUrl}getStakingPeriod`,
            data: { "email": this.loginData.data.user_email, "user_id": this.loginData.data?.id },
            headers: { "Authorization": this.loginData?.Token },
        }).then(response => {
            if (response.data.success === true) {
                this.setState({
                    getStakingPeriod: response.data.response
                })

            }
        })
    }

    async getStakingListAPI() {
        await axios({
            method: 'post',
            url: `${config.apiUrl}getStakingList`,
            data: { 'email': this.loginData.data.user_email, "user_id": this.loginData.data.id },
            headers: { "Authorization": this.loginData?.Token },
        }).then(response => {
            if (response.data.success === true) {
                this.setState({
                    getStakingList: response.data.response
                })

            }
        })
    }


    validatePassword = () => {
        var p = this.state
        let errors = [];

        if (this.state.token_amount == '') {
            errors.push({ name: 'token_amount', err: "Please enter token amount!" });
        }

        // console.log(this.loginData)

        if (this.loginData.data.user_email != 'kunika.espsofttech@gmail.com' && this.state.usd_amount < 100) {
            errors.push({ name: 'usd_amount', err: "Minimum staking amount : 100 USD" });
        }

        if (this.state.staking_period_id == '0') {
            errors.push({ name: 'staking_period_id', err: "Please Select Staking Period!" });
        }


        if (errors.length > 0) {

            this.setState({ error: errors })

            return false;
        }
        this.setState({ error: '' })
        //  setPasserr('')
        return true;
    }

    async connectMetasmask(e) {
        e.preventDefault()

        this.setState({
            spinLoader: '1'
        })
        var isvalid = this.validatePassword()
        this.setState({ spinLoader: '1' })
        
        if(this.state.selectType == 2){
            if (!isvalid) {
                this.setState({ spinLoader: '0' })
            }
            else if (window.ethereum) {
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                this.setState({
                    ConnectWalletAddress: accounts[0]
                })
                var web3 = new Web3(window.ethereum);
                var currentNetwork = web3.currentProvider.chainId;
    
                if (currentNetwork != '0x38') {
                    toast.error(`Please select Bnb smartchain!!`, {
    
                    });
                    this.setState({
                        spinLoader: '0'
                    })
    
                    return false;
                }
                else {
                    var chainId = '0x38';
                    let obj = {
                        symbol: 'YUSE',
                        amount: this.state.token_amount,
                        to_address: this.state.AdminWallet?.public_key,
                        from_address: accounts[0],
                        paymentType : 2
                    }
    
                    const responsee = await sendToken(obj)
                    console.log('responsee<<<<<<<<<<<', responsee);
                    if (responsee.success == true) {
                        this.setState({
                           trxHash : responsee.hash
                        })
                        this.addStakingRequest(e);
                    } else {
                        toast.error(responsee.msg)
                        this.setState({
                            spinLoader: '0'
                        })
                    }
                }
            }
            else {
                toast.error(`Please use dApp browser to connect wallet!`);
            }
        }else{
            if (!isvalid) {
                this.setState({ spinLoader: '0' })
            }
            else{

                try {
                    localStorage.clear('walletconnect')
                    const web3 = new Web3(provider);
        
                    await provider.enable();
                    const accounts = await web3.eth.getAccounts();
                    this.setState({
                        ConnectWalletAddress: accounts[0]
                    })
             
                    if(accounts[0]){
                        var currentNetwork = web3.currentProvider.chainId;
                        if (currentNetwork != '0x38') {
                            toast.error(`Please select Bnb smartchain!!`, {
            
                            });
                            this.setState({
                                spinLoader: '0'
                            })
            
                            return false;
                        }
                        else {
                            let obj = {
                                symbol: 'YUSE',
                                amount: this.state.token_amount,
                                to_address: this.state.AdminWallet?.public_key,
                                from_address: accounts[0],
                                paymentType : 3
                            }
            
                            const responsee = await sendToken(obj)
            
                            if (responsee.success == true) {
                                this.addStakingRequest(e);
                            } else {
                                toast.error(responsee.msg)
                                this.setState({
                                    spinLoader: '0'
                                })
                            }
                        }
                    }else{
                        toast.error(`Please use dApp browser to connect wallet!`);
                    }

                    localStorage.clear('walletconnect')
                } catch (error) {
                    localStorage.clear('walletconnect')
                    console.log('walletconnecterror', error)
                    window.location.reload()
                }

            }         
        }
    }

    async connectWallet(e) {
        e.preventDefault()
        try {
            localStorage.clear('walletconnect')
            const web3 = new Web3(provider);

            await provider.enable();
            const accounts = await web3.eth.getAccounts();
            this.setState({
                ConnectWalletAddress: accounts[0]
            })
            localStorage.clear('walletconnect')
        } catch (error) {
            localStorage.clear('walletconnect')
            console.log('walletconnecterror', error)
            window.location.reload()
        }
    }    


    async dashboardList() {
        await axios({
            method: 'post',
            url: `${config.apiUrl}getuserbalance`,
            data: { "user_id": this.loginData.data.id, 'email': this.loginData.data.user_email },
            headers: { "Authorization": this.loginData?.Token }
        })
            .then(result => {
                // console.log(result.data);
                if (result.data.success === true) {
                    this.setState({
                        dashboard_list: result.data.response
                    })

                }

                else if (result.data.success === false) {

                }
            })

            .catch(err => {
            })
    }



    addStakingRequest = async event => {
        event.preventDefault();
        this.setState({
            spinLoader: '1'
        })

        const getIp = await axios.get('https://geolocation-db.com/json/')

        this.setState({
          ip : getIp.data.IPv4
        })  

        if (this.state.usd_amount == '' || this.state.usd_amount == null) {
            toast.error('Please enter staking amount!');
            this.setState({
                spinLoader: '0',
                error: ''
            })
        }
        else if (this.loginData.data.user_email != 'kunika.espsofttech@gmail.com' && this.state.usd_amount < 100) {
            toast.error('Minimum staking amount : 100 USD');
            this.setState({
                spinLoader: '0',
                error: ''
            })
        } else {
            axios({
                method: 'post',
                headers: { "Authorization": this.loginData?.Token },
                url: `${config.apiUrl}addStakingRequest`,
                data: { 'email': this.loginData.data.user_email, "user_id": this.loginData.data.id, 'token_amount': this.state.token_amount, token_price: this.state.tokenPrice, 'usd_amount': this.state.usd_amount, 'staking_period_id': this.state.staking_period_id, 'selectType': this.state.selectType, 'trx_hash' : this.state.trxHash, 'ip' : this.state.ip }
            }).then(response => {
                if (response.data.success === true) {
                    toast.success(response.data.msg);
                    setTimeout(() => {
                        window.location.reload()
                    }, 2000);
                }
                if (response.data.success === false) {
                    toast.error(response.data.msg);
                    this.setState({
                        spinLoader: '0',
                        error: ''
                    })
                }
            }).catch(err => {
                toast.error(err.response.data.msg);
                this.setState({
                    spinLoader: '0',
                    error: ''
                })
            })
        }
    }

    RadioChange = e => {
        this.setState({ selectType: e.target.value })
        // console.log(this.state.selectType)
    }

    handleChange = e => {

        console.log({ [e.target.name]: e.target.value })

        if (e.target.name == 'token_amount') {
            var token_amount = e.target.value
            console.log(this.state.tokenPrice)
            var usdAmount = token_amount * this.state.tokenPrice

            this.setState({
                usd_amount: parseFloat(usdAmount).toFixed(2)
            })

        }

        if (e.target.name == 'usd_amount') {
            var usd_amount = e.target.value
            var tokenAmount = usd_amount / this.state.tokenPrice
            this.setState({
                token_amount: parseInt(tokenAmount)
            })
        }

        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {
        return (
            <>
                <Header1 />
                <Toaster
                    position="top-right"
                    reverseOrder={false} />
                <section className="page-login personal-setting" style={{ backgroundColor: "#e0e8f3" }}>
                    <div className="row">
                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                            <div className="header-menu-tabs">
                                <a href="" className="aside_logo"><img className="logo-c active be_logo aside_logo" src="images/YUSE_Logo_purple.png" alt="logo" /></a>
                                <aside className="web_view">
                                    <nav>
                                        <ul>
                                            <Link to={`${config.baseUrl}dashboard`}><li><i class="fa fa-th" aria-hidden="true"></i>Dashboard</li></Link>
                                            <Link to={`${config.baseUrl}transaction`}><li><i class="fal fa-money-bill-alt"></i>Transactions</li></Link>
                                            <Link to={`${config.baseUrl}authoredit`}><li><i class="fa fa-user-circle-o" aria-hidden="true"></i>My Profile</li></Link>
                                            <Link to={`${config.baseUrl}supportRequest`}><li className=""><i class="fal fa-user-headset"></i>Support</li></Link>
                                            <Link to={`${config.baseUrl}withdraw`}><li className=""><i className="fal fa-money"></i>Withdraw</li></Link>
                                            <Link to={`${config.baseUrl}staking`}><li className="active"><i className="fa fa-object-ungroup"></i>Staking</li></Link>
                                            <Link to={`${config.baseUrl}`}><li><i class="fal fa-home-alt"></i>Home</li></Link>
                                        </ul>
                                    </nav>
                                </aside>
                            </div>
                        </div>

                        <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12">
                            <div className="container-fluid mb-5">
                                <div className="transaction_heading mb-4">
                                    <h6 className="heading_anchor">
                                        <a href={`${config.baseUrl}dashboard`}>Dashboard</a> <span style={{ color: '#707070' }}>/ Staking with us</span>
                                    </h6>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <div className="content-area bg-white card bg-shadow">
                                            <div className="card-innr">

                                                <div className="tab-content" id="profile-details">
                                                    <div className="tab-pane fade show active" id="personal-data2">
                                                        <form>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="input-item input-with-label"><label for="full-name" className="input-item-label">Amount(YUSE)</label>
                                                                        <input onKeyPress={(event) => { if (!/^\d*[.]?\d{0,1}$/.test(event.key)) { event.preventDefault(); } }} className="input-bordered" autocomplete="off" type="text" id="user-name" onChange={this.handleChange} value={this.state.token_amount} name="token_amount" /></div>
                                                                    {this.state.error.length > 0 && this.state.error[0].name == 'token_amount' ? <div><span className='alert_validation'>{this.state.error[0].err}</span></div> : ''}
                                                                </div>

                                                                <div className="col-md-12">
                                                                    <div className="input-item input-with-label"><label for="full-name" className="input-item-label">Amount (Minimum 100 USD)</label>
                                                                        <input onKeyPress={(event) => { if (!/^\d*[.]?\d{0,1}$/.test(event.key)) { event.preventDefault(); } }} className="input-bordered" autocomplete="off" type="text" id="user-name" name="usd_amount" onChange={this.handleChange} value={this.state.usd_amount} /></div>
                                                                    {this.state.error.length > 0 && this.state.error[0].name == 'usd_amount' ? <div><span className='alert_validation'>{this.state.error[0].err}</span></div> : ''}

                                                                </div>

                                                                <div className="col-md-12">
                                                                    <div className="input-item input-with-label"><label for="full-name" className="input-item-label">Staking period (APR)</label>
                                                                        <select onChange={this.handleChange} value={this.state.staking_period_id} name="staking_period_id" className="form-control">
                                                                            <option value="">Select staking period</option>
                                                                            {this.state.getStakingPeriod.map(item => {
                                                                                return (
                                                                                    <option value={item.id}>{item.duration} Days ({item.percentage}%)</option>
                                                                                )
                                                                            })}
                                                                        </select>
                                                                        {this.state.error.length > 0 && this.state.error[0].name == 'staking_period_id' ? <div><span className='alert_validation'>{this.state.error[0].err}</span></div> : ''}

                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className=""><label for="full-name" className="input-item-label">Staking with</label>

                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className=""><label for="full-name" className="input-item-label"></label>

                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">

                                                                    <div className="col-md-6 col-12 text-center">
                                                                        <label className="containers purple-color" >
                                                                            Yuse wallet
                                                                            <input checked={(this.state.selectType == 1) ? "checked" : ""} onChange={e => this.RadioChange(e)} type="radio" value={1} name="cryptoCurrencyType" />
                                                                            <span className="checkmark"></span>
                                                                        </label>

                                                                    </div>
                                                                    <div className="col-md-6 col-12  text-center">
                                                                        <label class="containers grey-color" >
                                                                            Metamask / Trust wallet
                                                                            <input checked={(this.state.selectType == 2) ? "checked" : ""} onChange={e => this.RadioChange(e)} type="radio" value={2} name="cryptoCurrencyType" />
                                                                            <span class="checkmark"></span>
                                                                        </label>
                                                                    </div>

                                                                    {/* <div className="col-md-8 col-12  text-center">
                                                                        <label class="containers grey-color" >
                                                                            Wallet Connect
                                                                            <input checked={(this.state.selectType == 3) ? "checked" : ""} onChange={e => this.RadioChange(e)} type="radio" value={3} name="cryptoCurrencyType" />
                                                                            <span class="checkmark"></span>
                                                                        </label>
                                                                    </div> */}

                                                                </div>

                                                            </div>
                                                            <div className="gaps-1x"></div>
                                                            <div className="d-sm-flex justify-content-between align-items-center">
                                                                {this.state.spinLoader == '0' ?
                                                                    this.state.selectType == 2 || this.state.selectType == 3 ?
                                                                        <button className="btn " onClick={e => this.connectMetasmask(e)} id="staking_btn">Staking</button> :

                                                                        <button className="btn " disabled={this.state.usd_amount < 100 && this.loginData.data.user_email != 'kunika.espsofttech@gmail.com' ? true : false} onClick={this.addStakingRequest} id="staking_btn">Staking</button>
                                                                    :
                                                                    <button disabled className="btn">Loading <i className="fa fa-spinner fa-spin validat"></i></button>
                                                                }
                                                                <div className="gaps-2x d-sm-none"></div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='col-sm-8'>
                                        <div className="content-area bg-white card bg-shadow">
                                            <div className="card-innr staking">
                                                <div className='row'>
                                                    <h3 className="col-md-6 text-black mb-4">Staking history</h3>
                                                    {/* <h3 className="col-md-6 text-black mb-4" style={{ textAlign: 'right', fontSize: '20px' }}>Portfolio :<span style={{ fontWeight: 200 }}> {parseFloat(this.state.myPortfolio).toFixed(2)} YUSE </span></h3> */}
                                                    <h3 className="col-md-6 text-black mb-4"></h3>
                                                    <h3 className="col-md-6 text-black mb-4" style={{ textAlign: 'right', fontSize: '20px' }}>Token Balance :<span style={{ fontWeight: 200 }}> {parseFloat(!this.state.dashboard_list.NumberOftoken ? '0' : this.state.dashboard_list.NumberOftoken)} YUSE </span></h3>

                                                </div>
                                                <ReactDatatable
                                                    config={this.config}
                                                    records={this.state.getStakingList}
                                                    columns={this.columns}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                    <div className="faq staking_faq  col-md-12 col-sm-12 col-xl-12  pb-110 mobile-pt-60 mobile-pb-20 overflow-hidden">
                                        <div className="container">



                                            <div className="col-md-12 col-sm-12 col-xl-12 mb-3">
                                                <ul className="nav nav-pills flex-column" id="myTab" role="tablist">
                                                    {/* <li className="">
                              <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">General</a>
                           </li> */}
                                                    {/* <li className="">
                              <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Pre-ICO & ICC</a>
                           </li>
                           <li className="">
                              <a className="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">ICO Tokens</a>
                           </li>
                           <li className="">
                              <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Client</a>
                           </li>
                           <li className="">
                              <a className="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Team</a>
                           </li> */}
                                                </ul>
                                            </div>

                                            <div className="col-md-12 pl-0 pr-0">
                                                <div className="tab-content" id="myTabContent">
                                                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                        <br /><div align="center">  <h3 class="text-black">Frequently Asked Questions </h3></div>

                                                        <br />
                                                        <Accordion className='accordion ' allowZeroExpanded={true}>
                                                            <AccordionItem className="card">
                                                                <AccordionItemHeading >
                                                                    <AccordionItemButton className="card-header bg-pink shadow-sm border-0">
                                                                        1. What is the Minimum Amount for Staking?
                                                                    </AccordionItemButton>
                                                                </AccordionItemHeading>
                                                                <AccordionItemPanel className="card-body p-5 collapse in show">
                                                                    <p className="font-weight-light m-0">
                                                                        The minimum amount for staking is 100 USD or Equivalent of YUSE Tokens.
                                                                    </p>
                                                                </AccordionItemPanel>
                                                            </AccordionItem>

                                                            <AccordionItem className="card">
                                                                <AccordionItemHeading >
                                                                    <AccordionItemButton className="card-header bg-pink shadow-sm border-0">
                                                                        2. Why only a 50% amount is claimed when I redeem the staking rewards?
                                                                    </AccordionItemButton>
                                                                </AccordionItemHeading>
                                                                <AccordionItemPanel className="card-body p-5 collapse in show">
                                                                    <p className="font-weight-light m-0">
                                                                        Only 50% rewards will be available for redemption and pending 50% will be credited to your wallet after the staking period ends.
                                                                    </p>
                                                                </AccordionItemPanel>
                                                            </AccordionItem>


                                                            <AccordionItem className="card">
                                                                <AccordionItemHeading >
                                                                    <AccordionItemButton className="card-header bg-pink shadow-sm border-0">
                                                                        3. What is APR?
                                                                    </AccordionItemButton>
                                                                </AccordionItemHeading>
                                                                <AccordionItemPanel className="card-body p-5 collapse in show">
                                                                    <p className="font-weight-light m-0">
                                                                        APR stands for Annual Percentage Rate.
                                                                        <br />
                                                                        If the staking amount is $1000 USD for 300% APR then the daily return stands at 1% i.e. $10 USD.<br />
                                                                        Let us check the below table to understand how APR works.
                                                                    </p>
                                                                    <br />
                                                                    <div className='table-responsive'>
                                                                        <table className='table table-striped' width="100%" >
                                                                            <tr className='bg-pink'>
                                                                                <td ><div  >Amount Staked</div></td>
                                                                                <td ><div  >APR Days</div></td>
                                                                                <td ><div  >Total Reward %</div></td>
                                                                                <td ><div  >Daily Reward %</div></td>
                                                                                <td ><div  >Daily Rewards Amt</div></td>
                                                                                <td ><div  >Token Current Price</div></td>
                                                                                <td ><div  >Daily Token Credits</div></td>
                                                                                <td ><div  >Price Incease/Decrease</div></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><div >$1,000</div></td>
                                                                                <td><div >300</div></td>
                                                                                <td><div >300%</div></td>
                                                                                <td><div >1%</div></td>
                                                                                <td><div >$10</div></td>
                                                                                <td><div >$0.010</div></td>
                                                                                <td><div >1000</div></td>
                                                                                <td><div >Constant</div></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><div >$1,000</div></td>
                                                                                <td><div >300</div></td>
                                                                                <td><div >300%</div></td>
                                                                                <td><div >1%</div></td>
                                                                                <td><div >$10</div></td>
                                                                                <td><div >$0.020</div></td>
                                                                                <td><div >500</div></td>
                                                                                <td><div className='green'>Increase</div></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><div >$1,000</div></td>
                                                                                <td><div >300</div></td>
                                                                                <td><div >300%</div></td>
                                                                                <td><div >1%</div></td>
                                                                                <td><div >$10</div></td>
                                                                                <td><div >$0.050</div></td>
                                                                                <td><div >200</div></td>
                                                                                <td><div className='green' >Increase</div></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><div >$1,000</div></td>
                                                                                <td><div >300</div></td>
                                                                                <td><div >300%</div></td>
                                                                                <td><div >1%</div></td>
                                                                                <td><div >$10</div></td>
                                                                                <td><div >$0.005</div></td>
                                                                                <td><div >2000</div></td>
                                                                                <td><div className='red'>Decrease</div></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><div >$1,000</div></td>
                                                                                <td><div >300</div></td>
                                                                                <td><div >300%</div></td>
                                                                                <td><div >1%</div></td>
                                                                                <td><div >$10</div></td>
                                                                                <td><div >$0.001</div></td>
                                                                                <td><div >10000</div></td>
                                                                                <td><div className='red' >Decrease</div></td>
                                                                            </tr>
                                                                        </table>
                                                                    </div>
                                                                    <br />
                                                                    <p className="font-weight-light m-0">
                                                                        This shows that the amount of the daily rewards will be the same. However, the number of coins may change based on the token current price.</p>
                                                                </AccordionItemPanel>
                                                            </AccordionItem>



                                                            {/* <AccordionItem className="card">
                                    <AccordionItemHeading >
                                       <AccordionItemButton className="card-header bg-pink shadow-sm border-0">
                                     4. What is the highest amount I can invest during Pre-Sale?
                                       </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body p-5 collapse in show">
                                       <p className="font-weight-light m-0">
                                       The highest amount that one can invest during Pre-sale  is $50,000
                                      </p>
                                    </AccordionItemPanel>
                                 </AccordionItem> */}



                                                            {/* <AccordionItem className="card">
                                    <AccordionItemHeading >
                                       <AccordionItemButton className="card-header bg-pink shadow-sm border-0">
                                      5. What is the lowest amount I can invest during Pre-Sale?
                                       </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body p-5 collapse in show">
                                       <p className="font-weight-light m-0">
                                       The lowest amount that one can invest during Pre-sale is $10.
                                       Our Mission is to reduce the entry-level barrier for our community members. 
                                       </p>
                                    </AccordionItemPanel>
                                 </AccordionItem> */}
                                                        </Accordion>
                                                    </div>




                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                </section>
            </>
        )
    }
}