const config = {

  // baseUrl:'/yuse-ico/',
  baseUrl: '/',

  //  apiUrl : "http://localhost:8000/api/",
  //  apiUrl : "https://d33999lphlm9xs.cloudfront.net/api/" ,  //for new Server
  // apiUrl : "https://espsofttech.org:8000/api/" ,  //for server
  apiUrl: "https://app.yusetoken.io/api/",   //for server
  imageUrl: "https://ipfs.io/ipfs/",
  //  imageUrl1: "https://espsofttech.in/yuse-ico/backend/uploads/",
  imageUrl1: "https://app.yusetoken.io/backend/uploads/",
  blockchinUrl: "https://etherscan.io/",

};
export default config;

