import React, { Component } from 'react';
import Header from '../directives/header';
import config from '../config/config'
import Footer1 from '../directives/footer1';


export default class home2 extends Component {




    render() {

        return (

            <>
                <Header />
                <div id="content" className="loginpage">
                    <div className="breadcrumb-wrap bg-f br-4" >
                        <div className="container">
                            <div className="breadcrumb-title text-center">
                                <h2 className='headings'>Gallery</h2>
                            </div>
                        </div>
                    </div>
                    <section className="gallery-tabs">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-4 col-12 mobile-mb-1 mb-5">
                                    <div class="portfolio-wrapper">
                                        <div class="portfolio-image">
                                            <img src="images/2 Yuse NFT.png" alt="..." />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-12 mobile-mb-1 mb-5">
                                    <div class="portfolio-wrapper">
                                        <div class="portfolio-image">
                                            <img src="images/1 Yuse wallet.png" alt="..." />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-12 mobile-mb-1 mb-5">
                                    <div class="portfolio-wrapper">
                                        <div class="portfolio-image">
                                            <img src="images/3 Yuse Funtasy.png" alt="..." />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-12 mobile-mb-1 mb-5">
                                    <div class="portfolio-wrapper">
                                        <div class="portfolio-image">
                                            <img src="images/5 Yuse Circle.png" alt="..." />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-12 mobile-mb-1 mb-5">
                                    <div class="portfolio-wrapper">
                                        <div class="portfolio-image">
                                            <img src="images/4 Meta yuse.png" alt="..." />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-12 mobile-mb-1 mb-5">
                                    <div class="portfolio-wrapper">
                                        <div class="portfolio-image">
                                            <img src="images/6 Yuse gaming.png" alt="..." />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4 col-12 mobile-mb-1 mb-5">
                                    <div class="portfolio-wrapper">
                                        <div class="portfolio-image">
                                            <img src="images/7 Yuse Blockchain.png" alt="..." />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-12 mobile-mb-1 mb-5">
                                    <div class="portfolio-wrapper">
                                        <div class="portfolio-image">
                                            <img src="images/8 Yuse Cloudstorage.png" alt="..." />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-12 mobile-mb-1 mb-5">
                                    <div class="portfolio-wrapper">
                                        <div class="portfolio-image">
                                            <img src="images/9 Yuse Travel.png" alt="..." />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-12 mobile-mb-1 mb-5">
                                    <div class="portfolio-wrapper">
                                        <div class="portfolio-image">
                                            <img src="images/10 Yuse Education.png" alt="..." />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-12 mobile-mb-1 mb-5">
                                    <div class="portfolio-wrapper">
                                        <div class="portfolio-image">
                                            <img src="images/4 Meta yuse.png" alt="..." />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-12 mobile-mb-1 mb-5">
                                    <div class="portfolio-wrapper">
                                        <div class="portfolio-image">
                                            <img src="images/6 Yuse gaming.png" alt="..." />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4 col-12 mobile-mb-1 mb-5">
                                    <div class="portfolio-wrapper">
                                        <div class="portfolio-image">
                                            <img src="images/1 Yuse wallet.png" alt="..." />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-12 mobile-mb-1 mb-5">
                                    <div class="portfolio-wrapper">
                                        <div class="portfolio-image">
                                            <img src="images/2 Yuse NFT.png" alt="..." />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-12 mobile-mb-1 mb-5">
                                    <div class="portfolio-wrapper">
                                        <div class="portfolio-image">
                                            <img src="images/3 Yuse Funtasy.png" alt="..." />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-12 mobile-mb-1 mb-5">
                                    <div class="portfolio-wrapper">
                                        <div class="portfolio-image">
                                            <img src="images/5 Yuse Circle.png" alt="..." />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-12 mobile-mb-1 mb-5">
                                    <div class="portfolio-wrapper">
                                        <div class="portfolio-image">
                                            <img src="images/4 Meta yuse.png" alt="..." />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-12 mobile-mb-1 mb-5">
                                    <div class="portfolio-wrapper">
                                        <div class="portfolio-image">
                                            <img src="images/6 Yuse gaming.png" alt="..." />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                </div>
                <Footer1 />
            </>

        )
    }
}