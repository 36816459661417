import React, { Component } from 'react';
import config from '../config/config'
import {Link} from 'react-router-dom'
import axios from 'axios';
import Cookies from 'js-cookie'

export default class footer1 extends Component {

    constructor(props) {
        super(props)
        this.loginData = (!Cookies.get('loginSuccess'))?[]:JSON.parse(Cookies.get('loginSuccess'))
        this.state={
         getitemData:[],
         recentworkData:[]
        }
    }
    componentDidMount() {
      this.getitemAPI()
      this.recentworkAPI()
    }

     //=======================================  getitem  =====================

async getitemAPI() {
   await axios({
      method: 'post',
      url: `${config.apiUrl}getCategoryById`,
      headers: { "Authorization": this.loginData?.msg },
      data: {'item_category_id':'0','limit':18}
   })
      .then(result => {
         if (result.data.success === true) {
            this.setState({
               getitemData: result.data.response,
            })
         }
         else if (result.data.success === false) {
         }
      }).catch(err => {
      });
}

    //=======================================  recent work  =====================

    async recentworkAPI() {
      await axios({
         method: 'get',
         url: `${config.apiUrl}getRecentWorks`,
         headers: { "Authorization": this.loginData?.msg },
      })
         .then(result => {
            if (result.data.success === true) {
               this.setState({
                  recentworkData: result.data.response,
               })
            }
            else if (result.data.success === false) {
            }
         }).catch(err => {
         });
   }

    loading(){
       setTimeout(() => {
          window.location.reload()
       }, );
    }

    render() {
        return (    

            <>
              <footer className="footer-container footer-curve"  style={{backgroundImage: "url('assets/images/Background_img.png')"}}>
         <div className="container" >
            <div className="row d-block pb-0 mobile-pb-20 footer-top-content overflow-hidden">
               <div className="col-12 col-md-6 col-lg-5 footer-col wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
                  <div className="footer-Yuse ico">
                     <div className="footer-logo">
                     <Link to={`${config.baseUrl}`}>
                        <img src="assets/images/logo.png" alt="" />
                        </Link>
                     </div>
                     <p>Yuse is the world's first immersive ecosystem powered by a single multipurpose community token . </p>
                     
                  </div>
               </div>
               <div className="col-12 col-md-6 col-lg-2 footer-col link-hover wow fadeInUp" data-wow-duration="1s" data-wow-delay=".6s">
                  <h4>Company</h4>
                  <ul>
                  <li><Link to={`${config.baseUrl}about`}>About</Link></li>
                     {/* <li><a href="#">Service</a></li> */}
                     {/* <li><a href="#">Community</a></li> */}
                     {Cookies.get('loginSuccess') ?  <li><a href={`${config.baseUrl}dashboard`}>Buy Token</a></li>: <li><a href={`${config.baseUrl}login`}>Buy Token</a></li>}
                     {/* <li><a href="#">Contact</a></li> */}
                  </ul>
               </div>
               <div className="col-12 col-md-6 col-lg-2 footer-col link-hover wow fadeInUp" data-wow-duration="1s" data-wow-delay=".4s">
                  <h4>Support</h4>
                  <ul>
                     <li><a href={`${config.baseUrl}faq`}>FAQ</a></li>
                     <li><a href={`${config.baseUrl}termsandcondition`}>Terms and conditions</a></li>
                     <li><a href={`${config.baseUrl}privacyandpolicy`}>Privacy Policy</a></li>
                     <li><Link to={`${config.baseUrl}support_request`}>Support Request</Link></li>
                     {/* <li><a href="#">Community</a></li> */}
              
                  </ul>
               </div>
               <div className="col-12 col-md-6 col-lg-3 footer-col wow fadeInUp" data-wow-duration="1s" data-wow-delay=".8s">
                  <h4>Social Media</h4>
                  <ul className=" d-flex flex-wrap social-link mt-0">
                  <li><a href="https://www.youtube.com/watch?v=1KYrwn8cQSU&ab_channel=YuseToken" target="_blank"><i className="fab fa-youtube"></i></a></li>
                  <li><a href="https://www.linkedin.com/company/yuse-technologies/" target="_blank"><i className="fab fa-linkedin"></i></a></li>
                  <li><a href="https://www.facebook.com/yusetoken/" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                        <li><a href="https://twitter.com/YuseToken" target="_blank"><i className="fab fa-twitter"></i></a></li>
                        <li><a href="https://www.instagram.com/yusetoken/" target="_blank"><i className="fab fa-instagram"></i></a></li>
                        <li><a href="https://t.me/joinchat/2h2s6lfji-83Zjdl" target="_blank"><i className="fab fa-telegram"></i></a></li>
                     </ul> 
               </div>
            </div>
            <div className="row">
               <div className="col-12">
                  <div className="pt-40 pb-20 text-center footer-bottom-content">
                     <p className="">All rights reserved (c) 2021</p>
                     <ul className="d-flex flex-wrap order-1 order-sm-1 order-md-1 order-lg-2">
                        {/* <li><a href="#">Terms & Condition</a></li>
                        <li><a href="#">Privacy Policy</a></li>
                        <li><a href="#">Legal</a></li> */}
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </footer>
            </>
        )
    }
}