import React, { Component } from 'react';
import axios from 'axios';
import Header2 from '../directives/header2'
import Header from '../directives/header'
import Footer1 from '../directives/footer1'
import config from '../config/config'
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'js-cookie';
// import Countdown from 'react-countdown';
import Countdown from '../Countdown.js';
import DonutChart from 'react-donut-chart';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactTooltip from 'react-tooltip';


import {
   Accordion,
   AccordionItem,
   AccordionItemHeading,
   AccordionItemButton,
   AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

import MetaTags from 'react-meta-tags';
import {Helmet} from "react-helmet";

export default class home extends Component {

   constructor(props) {
      super(props)
      this.state = {
         email: '',
         username: '',
         subject: '',
         comments: '',
         tokenDis: [],
         dashboard_list: '',
         tokenPrice: '',
         //   email1 : ''

      }
      this.state = { time: {}, seconds: 259200 };
      this.timer = 0;
      this.startTimer = this.startTimer.bind(this);
      this.countDown = this.countDown.bind(this);
      this.loginData = (!Cookies.get('loginSuccess')) ? [] : JSON.parse(Cookies.get('loginSuccess'));
      this.handleChange = this.handleChange.bind(this);
      this.next = this.next.bind(this);
      this.previous = this.previous.bind(this);
   }
   next() {
      this.slider.slickNext();
   }
   previous() {
      this.slider.slickPrev();
   }

   handleSubmit = event => {
      event.preventDefault();

      const { email } = this.state;
      const data = this.state

      axios.post(`${config.apiUrl}addsubscriber`, data, { email })
         .then(result => {
            // alert(' ALL field');
            if (result.data.success === true) {

               toast.success('You Subscribe successfully!!', {
                  position: toast.POSITION.TOP_CENTER
               });
               window.location.href = `${config.baseUrl}`
            }
            else if (result.data.success === false) {



               this.setState({
                  email: '',
                  password: '',
                  spinLoader: '0'
               })

            }
         }).catch(err => {
            toast.error(err.response?.data?.msg, {
               position: toast.POSITION.TOP_CENTER
            })
            this.setState({
               spinLoader: '0'
            })
         })
   }


   handleSubmit1 = event => {
      event.preventDefault();

      const { email, username, subject, comments } = this.state;
      const data = this.state

      axios.post(`${config.apiUrl}insertcontact`, data, { username, email, subject, comments })
         .then(result => {
            // alert(' ALL field');
            if (result.data.success === true) {

               setTimeout(() => {
                  toast.success('Your request submitted successfully!! ', {
                     position: toast.POSITION.TOP_CENTER
                  });
               }, 2500);
               window.location.href = `${config.baseUrl}`
            }
            else if (result.data.success === false) {



               this.setState({
                  email: '',
                  password: '',
                  spinLoader: '0'
               })

            }
         }).catch(err => {
            toast.error(err.response?.data?.msg, {
               position: toast.POSITION.TOP_CENTER
            })
            this.setState({
               spinLoader: '0'
            })
         })
   }

   handleChange = e => {

      this.setState({
         [e.target.name]: e.target.value
      })
   }


   handleChange1 = e => {

      this.setState({
         [e.target.name]: e.target.value
      })
   }


   secondsToTime(secs) {

      let days = Math.floor(secs / (60 * 60 * 24));

      let divisor_for_hours = secs % (60 * 60 * 24);
      let hours = Math.floor(divisor_for_hours / (60 * 60));


      let divisor_for_minutes = secs % (60 * 60);
      let minutes = Math.floor(divisor_for_minutes / 60);

      let divisor_for_seconds = divisor_for_minutes % 60;
      let seconds = Math.ceil(divisor_for_seconds);

      let obj = {
         "d": days,
         "h": hours,
         "m": minutes,
         "s": seconds
      };
      return obj;
   }


   componentDidMount() {
      let timeLeftVar = this.secondsToTime(this.state.seconds);
      this.setState({ time: timeLeftVar });
      this.startTimer()
      this.getTokenPriceAPI();
      this.getTokenDistributionAPI()
   }

   startTimer() {
      if (this.timer == 0 && this.state.seconds > 0) {
         this.timer = setInterval(this.countDown, 1000);
      }
   }

   countDown() {
      // Remove one second, set state so a re-render happens.
      let seconds = this.state.seconds - 1;
      this.setState({
         time: this.secondsToTime(seconds),
         seconds: seconds,
      });

      // Check if we're at zero.
      if (seconds == 0) {
         clearInterval(this.timer);
      }
   }


   async getTokenPriceAPI() {
      await axios({
         method: 'get',
         url: `${config.apiUrl}getfee`,

      }).then(response => {
         if (response.data.success === true) {
            this.setState({
               tokenPrice: response.data.response.token_price,
               tokenfee: response.data.response.token_fee
            })

         }
      })
   }

   async getTokenDistributionAPI() {
      await axios({
         method: 'get',
         url: `${config.apiUrl}getTokenDistribution`,

      }).then(response => {
         if (response.data.success === true) {
            this.setState({
               tokenDis: response.data.response
            })
         }
      })
   }
   render() {
      const settings = {
         dots: true,
         infinite: true,
         speed: 500,
         slidesToShow: 1,
         slidesToScroll: 1
      };

      const currentDate = new Date();
      const currentDate1 = (new Date()).toLocaleDateString('en-US', { day: "numeric", month: "long", year: 'numeric' })
      const year = (currentDate.getMonth() === 11 && currentDate.getDate() > 23) ? currentDate.getFullYear() + 1 : currentDate.getFullYear();
      return (

         <>
            <div style={{ backgroundImage: "url('images/Background_img.png')" }}>


               <Header />
               <ToastContainer />
               <ReactTooltip />
               <Helmet>
                <meta charSet="utf-8" />
                <title>Yuse Token - Multi Utility Crypto Currency Token</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
               {/* <MetaTags>
            <title>Yuse Token - Multi Utility Crypto Currency Token</title>
            <meta name="description" content="Buy Yuse Crypto token which is one multipurpose token with different use cases. Visit and register yourself to buy Yuse token in Pre-sale now!" />
            <meta property="og:title" content="MyApp" />
            <meta property="og:image" content="path/to/image.jpg" />
          </MetaTags> */}

               {/* <section style={{ backgroundImage: "url('assets/images/Intersection1.png')", backgroundPositionX: "center" }}> */}

               <section style={{ backgroundImage: "", backgroundPositionX: "center" }}>
                  <div className="banner-container">
                     <div className="container">
                        <div className="row banner-content-area justify-content-between">
                           <div className="col-12 col-md-7 col-lg-7 banner-content">

                              <div className="banner-cont-info text-white wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                                 <h5 class="text-white">Welcome to</h5>
                                 <h1 class="text-white">YUSE ICO</h1>
                                 <h6 style={{ fontSize: "21px", lineHeight: "31px" }} className="text-white">What is better than having ONE multipurpose token with different use cases?</h6>
                                 {/* <h1 className="text-white">YUSE ICO</h1> */}
                                 {/* <p className="text-white">YUSE becomes a Transformational Agent, helps YOU Dream Big and Achieve Bigger.</p> */}
                                 <p className="text-white"><b className="text-white">Introducing YUSE, the first multipurpose community token for everyday life.</b></p>
                                 <a className="unc-btn-outline text-white" href="#">Whitepaper</a>&nbsp;
                                 {/* <a className="unc-btn text-white" href={`${config.baseUrl}buynow`}>Buy Now</a> */}
                                 <a className="unc-btn text-white" >Buy Now</a>
                              </div>


                           </div>
                           <div className="col-12 col-md-5 col-lg-5 banner-img">

                              <div className=" text-center">
                                 <h2 className="text-white">Pre-Sale Starts </h2>
                                 <br />
                                <Countdown date={`${year}-09-10T00:00:00`} />
                             </div>

                              <div className="col-sm-12 mt-3">
                                 <div className="row text-white">
                                    <div className="col-sm-8">
                                       <p>TOKEN PRICE:</p>
                                       <h2>1 YUSE = {this.state.tokenPrice} USD</h2>
                                       {/* <h2>1 YUSE= 0.01 USD</h2> */}
                                    </div>
                                    <div className="col-sm-4">
                                       <p>WE ACCEPT:</p>
                                       <h5>ETH, BTC, BUSD</h5>
                                    </div>
                                 </div>
                              </div>

                              <div className="col-sm-12 mt-2 mb-3">
                                 <div className="row text-white">
                                    <div className="col-sm-6">
                                       <p>TOKEN AVAILABLE ON <br />PRE-SALE</p>
                                    </div>
                                    <div className="col-sm-6">
                                       <h3>55,555,555.55 YUSE</h3>
                                    </div>
                                 </div>
                                 <hr style={{ borderTop: '1px solid #76638b' }} />
                              </div>

                              <div className="col-sm-12 ">
                                 <div className="row text-white">
                                    {/* <div className="col-sm-7">
                                       <a href={`${config.baseUrl}buynow`} className="btn btn-pink">BUY TOKEN WITH 20% OFF</a>
                                    </div> */}
                                      <div className="col-sm-7">
                                       <a  className="btn btn-pink">BUY TOKEN WITH 20% OFF</a>
                                    </div>
                                    <div className="col-sm-5">
                                       <h6>MINIMUM PURCHASE</h6>
                                       <h3>1000 YUSE</h3>
                                    </div>
                                 </div>
                              </div>
                           </div>

                        </div>
                        <ul className=" d-flex flex-wrap social-link social-link-banner pl-0">
                           <li><a href="https://www.facebook.com/yusetoken/" target="_blank"><img src="assets/images/fa-facebook.png" width="35px" /></a></li>
                           <li><a href="https://www.instagram.com/yusetoken/" target="_blank"><img src="assets/images/fa-instagram.png" width="45px" /></a></li>
                           <li><a href="https://twitter.com/YuseToken" target="_blank"><img src="assets/images/fa-twitter.png" width="45px" /></a></li>
                           <li><a href="https://t.me/joinchat/f4jxc9SAgbcxMTdl" target="_blank"><img src="assets/images/fa-telegram.png" width="45px" /></a></li>
                        </ul>
                        <a className="btn-scroll skip_swing" href="#section0"><span></span></a>
                     </div>
                  </div>
                  <div className="three-col-container ecosystem" style={{ padding: "40px 0" }}>


                     <div className="container">
                        <div className="row">
                          

                           <div className="col-sm-12">
                              <div className="heading  text-center">
                                 <h6 className="sub-title text-center">Ecosystem</h6>
                                 <h1 className="text-center">Welcome to YUSE Ecosystem</h1>
                                 
                                 
                                 </div>
                                 <div className="row">
                                    <div className="col-sm-6">
                                        {/* <img src="assets/images/yuse-cycle.gif" width="100%" /> */}
<video src='https://www.youtube.com/watch?v=1KYrwn8cQSU&t=1s'></video>
                                    </div>
                                    <div className="col-sm-6">
                                       <div className="mt-5 pt-5 text-white">
                                       <p>With an immersive ecosystem of YUSE, users will be able to utilize the token for multiple purposes. From a wide spectrum of our ecosystem, Communication is one such product where users will be able to chat with their loved ones, date their potential matches, and will be able to share and earn rewards.</p>
                                       
                                     
                                       <p>Cloud storage provides an extensive feature of dedicated blockchain servers where your data would be stored and secured without any worry. While the travel space gives users the free will to share and earn tokens as they travel to their favorite destinations. </p>
                                      <p> And above everything, the YUSE university will be a mammoth of education for learning about blockchain and cryptocurrency. Students can take up specific courses they wish and become wiser in the blockchain and cryptocurrency world.</p>

                                          </div>
                                 

                                    </div>

                                 </div>
                                 
                                 

                              </div>
                           
                        </div>
                     </div>
                  </div>
               </section>

               <section className="unc-inventment-container overflow-hidden" id="about-id">
                  <div className="container">
                     <div className="row pt-20 pb-0 mobile-pb-20">
                        <div className="col-12 col-md-12 col-lg-6 mobile-pb-0">
                           <div className="unc-inventment-content pt-110 mobile-pt-40 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".7s">
                              <h6 className="sub-title3 text-left">Mobile APP</h6>

                              <h1>MOBILE APP</h1>
                              {/* <p>Yuse Mobile App will include various applications like</p>
                              <ul className=" pl-5">
                                 <li>Block chain based chat application</li>
                                 <li>AI based Virtual Gaming </li>
                                 <li> Secured Cloud storage</li>
                                 <li>Travel Booking App</li>
                                 <li>Social Entertainment </li>
                                 <li>YUSE University for Blockchain & Crypto Education</li>
                              </ul> */}
                              <p>Earn YUSE Token for using applications, creating content, doing challenges, sharing content with friends and much more. Use YUSE Token to tip your favorite creators, and buy services from in-app stores.</p>                              
                              <a className="unc-btn text-white mt-5" style={{ background: "#4c1267" }} href="#"><img src="assets/images/apple.png" style={{ width: '20px', marginTop: '-6px' }} />&nbsp;Comming soon</a>
                              <a className="unc-btn text-white mt-5" style={{ background: "#4c1267" }} href="#"><img src="assets/images/android.png" style={{ width: '20px', marginTop: '-6px' }} />&nbsp; Comming soon</a>

                           </div>

                        </div>
                        <div className="col-12 col-md-12 col-lg-6">
                           <div className="mobile-image">
                              <img src="assets/images/mobile.png" width="100%" />

                           </div>
                        </div>
                     </div>
                  </div>
               </section>

               <div className="unc-happenning-container overflow-hidden">
                  <div className="container-fluid">
                     <div className="row pt-110 pb-50 mobile-pt-60 mobile-pb-0">
                        <div className="col-12 big-title text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s">

                           <h6 className="sub-title2 text-center">YUSE TOKEN ECONOMICS</h6>
                           <h1 className="text-white">YUSE TOKEN ECONOMICS</h1>
                           <p className="text-white">Breakdown of our Token Recipients.</p>
                        </div>
                     </div>
                     <div className="container text-center mb-5">
                        <div className="row">
                           <div className="col-sm-4">
                              <div className="token-list">
                                 <h3>1,111,111,111.00</h3>
                                 <h4>TOTAL TOKEN SUPPLY</h4>
                              </div>
                           </div>
                           <div className="col-sm-4">
                              <div className="token-list">
                                 <h3>20,000,000</h3>
                                 <h4>HARDCAP (ICO)</h4>
                              </div>
                           </div>
                           <div className="col-sm-4">
                              <div className="token-list">
                                 <h3>5,000,000</h3>
                                 <h4>SOFTCAP (ICO)</h4>
                              </div>
                           </div>
                           <div className="col-sm-4">
                              <div className="token-list">
                                 <h3>222,222,222.20</h3>
                                 <h4>TOKEN FOR PUBLIC</h4>
                              </div>
                           </div>
                           <div className="col-sm-4">
                              <div className="token-list">
                                 <h3>$0.01 USD</h3>
                                 <h4>YUSE TOKEN PRICE</h4>
                              </div>
                           </div>
                           <div className="col-sm-4">
                              <div className="token-list">
                                 <h3>ETH, BTC, BUSD</h3>
                                 <h4>ACCEPTED CURRENCY</h4>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="container">
                        <div className="row">
                           <div className="col-sm-12 text-center">
                              <h3>Token Distribution</h3>
                              <DonutChart height="600" className="tokenChart"
                                 data={[
                                    {
                                       label: 'Pre Sales(%)',
                                       value: parseInt(this.state.tokenDis?.pre_sale_percentage),
                                       colors:'#000',
                                    },
                                    {
                                       label: 'Public Sales(%)',
                                       value: parseInt(this.state.tokenDis?.public_sales_percentage),
                                       innerRadius:50,
                                       className : "publicsale"
                                    },
                                    {
                                       label: 'Marketing & Development(%)',
                                       value: parseInt(this.state.tokenDis?.marketing_development_percentage),
                                       innerRadius:100,
                                       outerRadius:100,


                                    },
                                    {
                                       label: 'Team & Foundation(%)',
                                       value: parseInt(this.state.tokenDis?.team_foundation_percentage),
                                       width:250,
                                       height:250
                                    },
                                    {
                                       label: 'Rewards(%)',
                                       value: parseInt(this.state.tokenDis?.rewards_percentage),
                                    },
                                    {
                                       label: 'Tie ups(%)',
                                       value: parseInt(this.state.tokenDis?.tie_ups_percentage),
                                       
                                    },
                                    {
                                       label: 'Liquidity Pool(%)',
                                       value: parseInt(this.state.tokenDis?.liquidity_pool_percentage),
                                    },
                                    {
                                       label: 'charity(%)',
                                       value: parseInt(this.state.tokenDis?.charity_percentage),
                                    },
                                 ]} />


                              {/* <img src="assets/images/token_distribution.png" className="img-fluid" /> */}
                           </div>
                           {/* <div className="vl"></div>
                           <div className="col-sm-6 text-center">
                              <h3>Uses of Fund</h3>
                              <img src="assets/images/token_fund.png" className="img-fluid" />
                           </div> */}
                        </div>
                     </div>

                  </div>
               </div>

               <section id="roadmap_06" style={{ zIndex: '-1px !important' }}>
                  
                  <div className="container">

                     <div className="row">
                        <div className="col-12 big-title text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">

                           <h6 className="sub-title2" >Road Map</h6>
                           <h1 className="text-black">ROAD MAP</h1>
                           <p className="text-black">Breakdown our Token Recipients.</p>
                        </div>
                     </div>
                     <div className="row">
                     <div className="location_04">
                           <Slider ref={c => (this.slider = c)} {...settings}>

                              <div key={1}>
                                 <div className="col-sm-12 col-md-12 col-lg-12">

                                    <div className="location_wrapper">
                                       <div className="roadmap_position"></div>

                                       <div className="location location_top location_1  wow fadeInUp" data-wow-duration="2s" data-wow-delay=".2s" >
                                          <div className="map_date">
                                             <h5 >Q1-2021</h5>
                                          </div>
                                          {/* <p data-tip="hello world">Tooltip</p> */}

                                          <span data-html={true} data-background-color="rgb(76, 18, 103)" data-tip="
                                             •	Creative & Digital Marketing team on-boarding completed <br/>
                                             •	YUSE Logo Launched<br/>
                                             •	Market analysis <br/>
                                             •	Branding guideline issued<br/>"
                                             ></span>
                                          <div className="location_title">
                                          
                                          <p>  Initiation of a Multi-utility token YUSE</p>
                                          <p>  Operation Team formation </p>
                                          </div>

                                          {/* <div class="tooltiptext"  ref={ref => this.fooRef = ref}>•	Initiation of a Multi-utility token YUSE<br/>
                                          •	Operation Team formation <br/>
                                          •	Creative & Digital Marketing team on-boarding completed <br/>
                                          •	YUSE Logo Launched<br/>
                                          •	Branding guideline issued<br/>
                                          •	Technical team on boarding completed<br/>
                                          •	YUSE Ecosystem development initiated <br/>
                                          •	 Core team’s Approval of various upcoming products completed
                                          </div> */}
                                       </div>
                                       <div className="location location_bttom location_2  wow fadeInUp" data-wow-duration="2s" data-wow-delay=".3s">
                                          <div className="map_date">
                                             <h5>Q2-2021</h5>
                                          </div>
                                          <span data-html={true} data-background-color="rgb(76, 18, 103)" data-tip="
                                             •	 Core team’s Approval of various upcoming products completed<br/>
                                             •	 Tokenomics approved.<br/>
                                                "></span>
                                          <div className="location_title">
                                          <p>Technical team on boarding completed</p>
                                          <p>	YUSE Ecosystem development initiated </p>
                                                                                   </div>
                                       </div>
                                       <div className="location location_top location_3  wow fadeInUp" data-wow-duration="2s" data-wow-delay=".4s">
                                          <div className="map_date">
                                             <h5>Q3-2021</h5>
                                          </div>
                                          <span data-html={true} data-background-color="rgb(76, 18, 103)" data-tip="
                                          •	Team expansion Chief Advisor on boarding completed  <br/>
                                          •	 Content writer on boarding completed<br/>
                                          •	Blue print of the Ecosystem prepared & approved<br/>
                                          •	6 different product approved for phase 1<br/>
                                          •	YUSE ecosystem:- <br/>
                                          o	Communication & dating app- CIRCLE <br/>
                                          o	Cloud storage- AIRLIFT <br/>
                                          o	Social entertainment app- REELS<br/>
                                          o	Gaming app- ▶️ PLAY<br/>
                                          o	Travel app -Miles <br/>
                                          o	YUSE University<br/>
                                          •	Website Launched<br/>
                                          •	1ST Teaser Video completed<br/>
                                          •	Pitch deck completed <br/>
                                          •	Private Sales initiated<br/>
                                          •	Social Media campaign Initiated on:<br/>
                                          o	Face book <br/>
                                          o	LinkedIn<br/>
                                          o	Instagram <br/>
                                          o	Twitter<br/>
                                          o	Reddit<br/>
                                          o	Discord<br/>
                                          •	Private Sale initiated<br/>
                                          •	Offline marketing to commence <br/>
                                          •	Circle team on boarding<br/>
                                          •	Circle Logo finalization<br/>
                                          •	Wallet Creation <br/>
                                          •	Technical team expansion <br/>
                                          •	Launching Circle app <br/>
                                          •	YUSE University team on boarding<br/>
                                          •	Academic calendar to be initiated<br/> 
                                          •	 Course content creation begins<br/>
                                          •	Awareness Campaign<br/>
                                          •	1st Phase ICO to commence<br/>
                                          •	Tie up with various global university <br/>
                                          •	New Marketing team on boarding<br/>
                                          •	Digital content creation <br/>
                                          •	50,000 participants in YUSE university<br/>
                                          "
                                          ></span>
                                          <div className="location_title">
                                          <p>	Smart contract creation on test net</p> 
                                          <p>	Seeders sale finalization & completion </p>
                                          </div>
                                       </div>
                                       <div className="location location_bttom location_4  wow fadeInUp" data-wow-duration="2s" data-wow-delay=".5s">
                                          <div className="map_date">
                                             <h5>Q4-2021</h5>
                                          </div>
                                          <span data-html={true} data-background-color="rgb(76, 18, 103)" data-tip="
                                             •	Token Listing  <br/>
                                             •	Airlift  team on boarding<br/>
                                             •	Airlift Logo finalization<br/>
                                             •	Technical team expansion <br/>
                                             •	Launching Airlift app <br/>
                                             •	Launching of Premium Circle plus app<br/>
                                             •	Awareness Campaign<br/>
                                             •	50,000 user of CIRCLE Plus app<br/>
                                             •	More CEX Listings<br/>
                                             " ></span>
                                          <div className="location_title">
                                             <p>Token Listing </p>
                                             <p>Airlift  team on boarding</p>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>

                              <div key={2}>
                                 <div className="col-sm-12 col-md-12 col-lg-12">

                                    <div className="location_wrapper">
                                       <div className="roadmap_position"></div>

                                       <div className="location location_top location_1  wow fadeInUp" data-wow-duration="2s" data-wow-delay=".2s">
                                          <div className="map_date">
                                             <h5>Q1-2022</h5>
                                          </div>
                                          <span data-html={true} data-background-color="rgb(76, 18, 103)" data-tip="
                                             •	Creative & Digital Marketing team on-boarding completed <br/>
                                             •	YUSE Logo Launched<br/>
                                             •	Branding guideline issued<br/>
                                             •	Technical team on boarding completed<br/>
                                             •	YUSE Ecosystem development initiated <br/>
                                             •	 Core team’s Approval of various upcoming products completed" ></span>
                                          <div className="location_title">
                                             <p>	Initiation of a Multi-utility token YUSE</p>
                                             <p>		Operation Team formation </p>
                                          </div>
                                       </div>
                                       <div className="location location_bttom location_2  wow fadeInUp" data-wow-duration="2s" data-wow-delay=".3s">
                                          <div className="map_date">
                                             <h5>Q2-2022</h5>
                                          </div>
                                          <span data-html={true} data-background-color="rgb(76, 18, 103)" data-tip="

•	Play Technical team expansion<br/> 
•	Launching Play app <br/>
•	In-house game development<br/> 
•	10,00,000 user of REEL users<br/>
•	7,50,000 user of CIRCLE Plus app<br/>
•	Block chain Development<br/>
•	5,00,000 active players in PLAY<br/>

                                                " ></span>
                                          <div className="location_title">
                                         <p> Play (Gaming) team on boarding</p>
                                         <p>	Play Logo finalization</p>
                                          </div>
                                       </div>
                                       <div className="location location_top location_3  wow fadeInUp" data-wow-duration="2s" data-wow-delay=".4s">
                                          <div className="map_date">
                                             <h5>Q3- 2022</h5>
                                          </div>
                                          <span data-html={true} data-background-color="rgb(76, 18, 103)" data-tip="•	Miles Logo finalization<br/>
•	Miles Technical team expansion <br/>
•	Launching Miles app <br/>
•	Launching of Premium Circle plus app<br/>
•	50,000 Premium Circle plus app users<br/>
•	Awareness Campaign<br/>
•	More CEX Listings<br/>
" ></span>
                                          <div className="location_title">
                                          <p>	Token Listing  </p>
<p>	Miles  team on boarding</p>

                                          </div>
                                       </div>
                                       <div className="location location_bttom location_4  wow fadeInUp" data-wow-duration="2s" data-wow-delay=".5s">
                                          <div className="map_date">
                                             <h5>Q4- 2022</h5>
                                          </div>
                                          <span data-html={true} data-background-color="rgb(76, 18, 103)" data-tip="•	30,00,000 active user of REEL users<br/>
•	50,00,000  active user of CIRCLE Plus app<br/>
•	Block chain Development<br/>
•	10,00,000 active players in PLAY<br/>
•	5,00,000 active Premium Circle plus app users<br/>
" ></span>
                                          <div className="location_title">
                                          <p>Project review </p>
<p>	New Product launch
</p>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>


                              {/* <div key={3}>
                                 <div className="col-sm-12 col-md-12 col-lg-12">

                                    <div className="location_wrapper">
                                       <div className="roadmap_position"></div>

                                       <div className="location location_top location_1  wow fadeInUp" data-wow-duration="2s" data-wow-delay=".2s">
                                          <div className="map_date">
                                             <h5>Sept, 2021</h5>
                                          </div>
                                          <span data-html={true} data-background-color="rgb(76, 18, 103)" data-tip="•	Initiation of a Multi-utility token YUSE<br/>
                                             •	Operation Team formation <br/>
                                             •	Creative & Digital Marketing team on-boarding completed <br/>
                                             •	YUSE Logo Launched<br/>
                                             •	Branding guideline issued<br/>
                                             •	Technical team on boarding completed<br/>
                                             •	YUSE Ecosystem development initiated <br/>
                                             •	 Core team’s Approval of various upcoming products completed" ></span>
                                          <div className="location_title">
                                             <h3>Pre-Sale</h3>
                                             <p>It is a long established fact that a reader will be looking at its layout.</p>
                                          </div>
                                       </div>
                                       <div className="location location_bttom location_2  wow fadeInUp" data-wow-duration="2s" data-wow-delay=".3s">
                                          <div className="map_date">
                                             <h5>Oct, 2021</h5>
                                          </div>
                                          <span data-html={true} data-background-color="rgb(76, 18, 103)" data-tip="•	Initiation of a Multi-utility token YUSE<br/>
                                             •	Operation Team formation <br/>
                                             •	Creative & Digital Marketing team on-boarding completed <br/>
                                             •	YUSE Logo Launched<br/>
                                             •	Branding guideline issued<br/>
                                             •	Technical team on boarding completed<br/>
                                             •	YUSE Ecosystem development initiated <br/>
                                             •	 Core team’s Approval of various upcoming products completed" ></span>
                                          <div className="location_title">
                                             <h3>Research</h3>
                                             <p>Established fact that a reader will<br /> be looking at its layout.</p>
                                          </div>
                                       </div>
                                       <div className="location location_top location_3  wow fadeInUp" data-wow-duration="2s" data-wow-delay=".4s">
                                          <div className="map_date">
                                             <h5>Nov, 2021</h5>
                                          </div>
                                          <span data-html={true} data-background-color="rgb(76, 18, 103)" data-tip="•	Initiation of a Multi-utility token YUSE<br/>
                                             •	Operation Team formation <br/>
                                             •	Creative & Digital Marketing team on-boarding completed <br/>
                                             •	YUSE Logo Launched<br/>
                                             •	Branding guideline issued<br/>
                                             •	Technical team on boarding completed<br/>
                                             •	YUSE Ecosystem development initiated <br/>
                                             •	 Core team’s Approval of various upcoming products completed" ></span>
                                          <div className="location_title">
                                             <h3>Concept</h3>
                                             <p>Established fact that a reader will<br /> be looking at its layout.</p>
                                          </div>
                                       </div>
                                       <div className="location location_bttom location_4  wow fadeInUp" data-wow-duration="2s" data-wow-delay=".5s">
                                          <div className="map_date">
                                             <h5>Dec, 2021</h5>
                                          </div>
                                          <span data-html={true} data-background-color="rgb(76, 18, 103)" data-tip="•	Initiation of a Multi-utility token YUSE<br/>
                                             •	Operation Team formation <br/>
                                             •	Creative & Digital Marketing team on-boarding completed <br/>
                                             •	YUSE Logo Launched<br/>
                                             •	Branding guideline issued<br/>
                                             •	Technical team on boarding completed<br/>
                                             •	YUSE Ecosystem development initiated <br/>
                                             •	 Core team’s Approval of various upcoming products completed" ></span>
                                          <div className="location_title">
                                             <h3>Coinbit</h3>
                                             <p>Established fact that a reader will<br /> be looking at its layout.</p>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div> */}


                           </Slider>
                        </div>
                     </div>
                  </div>
                  <a class="left btn-left carousel-control" onClick={e => this.previous()} style={{ zIndex: '9999999' }}  role="button" data-slide="prev">
                     <span class="fa fa-chevron-left" aria-hidden="true"></span>
                     <span class="sr-only">Previous</span>
                  </a>
                  <a class="right btn-right carousel-control" onClick={e => this.next()} style={{ zIndex: '9999999' }}  role="button" data-slide="next">
                     <span class="fa fa-chevron-right" aria-hidden="true"></span>
                     <span class="sr-only">Next</span>
                  </a>
               </section>

               {/* <div className="faq pt-110 pb-110 mobile-pt-60 mobile-pb-20 overflow-hidden">
               <div className="container">
                  <div className="row">
                     <div className="col-12 big-title text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">

                        <h6 className="sub-title2 text-center">Frequently Asked Questions</h6>
                        <h1 className="text-white">FREQUENTLY ASKED QUESTIONS</h1>
                        <p className="text-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the</p>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-md-4 mb-3">
                        <ul className="nav nav-pills flex-column" id="myTab" role="tablist">
                           <li className="">
                              <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">General</a>
                           </li>
                           <li className="">
                              <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Pre-ICO & ICC</a>
                           </li>
                           <li className="">
                              <a className="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">ICO Tokens</a>
                           </li>
                           <li className="">
                              <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Client</a>
                           </li>
                           <li className="">
                              <a className="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Legal</a>
                           </li>
                        </ul>
                     </div>

                     <div className="col-md-8">
                        <div className="tab-content" id="myTabContent">
                           <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                              <Accordion className='accordion shadow' allowZeroExpanded={true}>
                                 <AccordionItem className="card">
                                    <AccordionItemHeading >
                                       <AccordionItemButton className="card-header bg-pink shadow-sm border-0">
                                          What services does Yuse Offer?
                                       </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body p-5 collapse in show">
                                       <p className="font-weight-light m-0">
                                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                                       </p>
                                    </AccordionItemPanel>
                                 </AccordionItem>

                                 <AccordionItem className="card">
                                    <AccordionItemHeading >
                                       <AccordionItemButton className="card-header bg-pink shadow-sm border-0">
                                          Why should i choose a Design studio like Yuse over full-service agency?
                                       </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body p-5 collapse in show">
                                       <p className="font-weight-light m-0">
                                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                                       </p>
                                    </AccordionItemPanel>
                                 </AccordionItem>


                                 <AccordionItem className="card">
                                    <AccordionItemHeading >
                                       <AccordionItemButton className="card-header bg-pink shadow-sm border-0">
                                          How does Yuse create website content without knowing our Business plan?
                                       </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body p-5 collapse in show">
                                       <p className="font-weight-light m-0">
                                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                                       </p>
                                    </AccordionItemPanel>
                                 </AccordionItem>



                                 <AccordionItem className="card">
                                    <AccordionItemHeading >
                                       <AccordionItemButton className="card-header bg-pink shadow-sm border-0">
                                          What will be delivered? And When?
                                       </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body p-5 collapse in show">
                                       <p className="font-weight-light m-0">
                                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                                       </p>
                                    </AccordionItemPanel>
                                 </AccordionItem>



                                 <AccordionItem className="card">
                                    <AccordionItemHeading >
                                       <AccordionItemButton className="card-header bg-pink shadow-sm border-0">
                                          What often will results be reported?
                                       </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body p-5 collapse in show">
                                       <p className="font-weight-light m-0">
                                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                                       </p>
                                    </AccordionItemPanel>
                                 </AccordionItem>
                              </Accordion>


                           </div>
                           <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                              <Accordion className='accordion shadow' allowZeroExpanded={true}>
                                 <AccordionItem className="card">
                                    <AccordionItemHeading >
                                       <AccordionItemButton className="card-header bg-pink shadow-sm border-0">
                                          What services does Yuse Offer?
                                       </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body p-5 collapse in show">
                                       <p className="font-weight-light m-0">
                                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                                       </p>
                                    </AccordionItemPanel>
                                 </AccordionItem>

                                 <AccordionItem className="card">
                                    <AccordionItemHeading >
                                       <AccordionItemButton className="card-header bg-pink shadow-sm border-0">
                                          Why should i choose a Design studio like Yuse over full-service agency?
                                       </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body p-5 collapse in show">
                                       <p className="font-weight-light m-0">
                                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                                       </p>
                                    </AccordionItemPanel>
                                 </AccordionItem>


                                 <AccordionItem className="card">
                                    <AccordionItemHeading >
                                       <AccordionItemButton className="card-header bg-pink shadow-sm border-0">
                                          How does Yuse create website content without knowing our Business plan?
                                       </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body p-5 collapse in show">
                                       <p className="font-weight-light m-0">
                                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                                       </p>
                                    </AccordionItemPanel>
                                 </AccordionItem>



                                 <AccordionItem className="card">
                                    <AccordionItemHeading >
                                       <AccordionItemButton className="card-header bg-pink shadow-sm border-0">
                                          What will be delivered? And When?
                                       </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body p-5 collapse in show">
                                       <p className="font-weight-light m-0">
                                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                                       </p>
                                    </AccordionItemPanel>
                                 </AccordionItem>



                                 <AccordionItem className="card">
                                    <AccordionItemHeading >
                                       <AccordionItemButton className="card-header bg-pink shadow-sm border-0">
                                          What often will results be reported?
                                       </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body p-5 collapse in show">
                                       <p className="font-weight-light m-0">
                                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                                       </p>
                                    </AccordionItemPanel>
                                 </AccordionItem>



                              </Accordion>


                           </div>
                           <div className="tab-pane fade " id="contact" role="tabpanel" aria-labelledby="contact-tab">
                              <Accordion className='accordion shadow' allowZeroExpanded={true}>
                                 <AccordionItem className="card">
                                    <AccordionItemHeading >
                                       <AccordionItemButton className="card-header bg-pink shadow-sm border-0">
                                          What services does Yuse Offer?
                                       </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body p-5 collapse in show">
                                       <p className="font-weight-light m-0">
                                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                                       </p>
                                    </AccordionItemPanel>
                                 </AccordionItem>

                                 <AccordionItem className="card">
                                    <AccordionItemHeading >
                                       <AccordionItemButton className="card-header bg-pink shadow-sm border-0">
                                          Why should i choose a Design studio like Yuse over full-service agency?
                                       </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body p-5 collapse in show">
                                       <p className="font-weight-light m-0">
                                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                                       </p>
                                    </AccordionItemPanel>
                                 </AccordionItem>


                                 <AccordionItem className="card">
                                    <AccordionItemHeading >
                                       <AccordionItemButton className="card-header bg-pink shadow-sm border-0">
                                          How does Yuse create website content without knowing our Business plan?
                                       </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body p-5 collapse in show">
                                       <p className="font-weight-light m-0">
                                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                                       </p>
                                    </AccordionItemPanel>
                                 </AccordionItem>



                                 <AccordionItem className="card">
                                    <AccordionItemHeading >
                                       <AccordionItemButton className="card-header bg-pink shadow-sm border-0">
                                          What will be delivered? And When?
                                       </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body p-5 collapse in show">
                                       <p className="font-weight-light m-0">
                                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                                       </p>
                                    </AccordionItemPanel>
                                 </AccordionItem>



                                 <AccordionItem className="card">
                                    <AccordionItemHeading >
                                       <AccordionItemButton className="card-header bg-pink shadow-sm border-0">
                                          What often will results be reported?
                                       </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body p-5 collapse in show">
                                       <p className="font-weight-light m-0">
                                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                                       </p>
                                    </AccordionItemPanel>
                                 </AccordionItem>



                              </Accordion>


                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div> */}

               <div className="partner">
                  <div className="container">

                     <div className="row ">
                        <div className=" col-12 big-title  text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">

                           <h6 className="sub-title2">TECHNOLOGY STACK</h6>
                           <h1 className="text-white">TECHNOLOGY STACK</h1>
                           {/* <p className="text-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p> */}
                        </div>
                     </div>
                     <div className="row justify-content-center partner-area mobile-pb-20 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".5s">

                        <div className="col-6 col-md-3 col-lg-2 mt-30 mb-50 partner-logo">
                           <a href="#">
                              <img src="assets/images/1.png" alt="" />
                           </a>
                        </div>
                        <div className="col-6 col-md-3 col-lg-2 mt-30 mb-50 partner-logo">
                           <a href="#">
                              <img src="assets/images/2.png" alt="" style={{ paddingTop: "25px" }} />
                           </a>
                        </div>
                        <div className="col-6 col-md-3 col-lg-2 mt-30 mb-50 partner-logo">
                           <a href="#">
                              <img src="assets/images/3.png" alt="" />
                           </a>
                        </div>
                        <div className="col-6 col-md-3 col-lg-2 mt-30 mb-50 partner-logo">
                           <a href="#">
                              <img src="assets/images/4.png" alt="" style={{ paddingTop: "25px" }} />
                           </a>
                        </div>
                        <div className="col-6 col-md-3 col-lg-2 mt-30 mb-50 partner-logo">
                           <a href="#">
                              <img src="assets/images/5.png" alt="" />
                           </a>
                        </div>


                     </div>
                  </div>
               </div>

               <div className="team overflow-hidden" id="team-id">
                  <div className="container">
                     <div className="row pt-110 mobile-pt-60 ">
                        <div className="col-12 big-title white-text text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s">

                           <h6 className="sub-title2 text-center">Our Team</h6>
                           <h1 className="text-white">OUR TEAM</h1>
                           {/* <p className="text-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the</p> */}
                        </div>
                     </div>
                     <div className="row justify-content-center team-col-area pt-40 pb-60 mobile-pt-10 mobile-pb-20">
                        <div className="col-12 col-md-4 col-lg-3 team-col pb-50 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                           <div className="team-bg">
                              <div className="team-mem">
                                 <div className="team-mem-info">
                                    <a href="#"><img src="assets/images/favicon4.png" alt="" /></a>
                                 </div>
                                 <div className="team_social_s2 list_none">
                                    <ul>
                                       <li><a href="https://www.facebook.com/yusetoken/"><i className="fab fa-facebook"></i></a></li>
                                       <li><a href="https://twitter.com/YuseToken"><i className="fab fa-twitter"></i></a></li>
                                       <li><a href="https://www.instagram.com/yusetoken/"><i className="fab fa-instagram"></i></a></li>
                                       <li><a href="https://t.me/joinchat/f4jxc9SAgbcxMTdl"><i className="fab fa-telegram"></i></a></li>
                                    </ul>
                                 </div>
                              </div>
                              <div className="team-cont">
                                 <h3><a href="google.com">Umesh Pandey</a></h3>
                                 <small>COO</small>
                              </div>

                           </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-3 team-col pb-50 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                           <div className="team-bg">
                              <div className="team-mem">
                                 <div className="team-mem-info">
                                    <a href="#"><img src="assets/images/team1.jpeg" alt="" /></a>
                                 </div>
                                 <div className="team_social_s2 list_none">
                                    <ul>
                                       <li><a href="#"><i className="fab fa-facebook"></i></a></li>
                                       <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                       <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                                       <li><a href="#"><i className="fab fa-linkedin"></i></a></li>
                                    </ul>
                                 </div>
                              </div>
                              <div className="team-cont">
                                 <h3><a href="#">Allan Jackson</a></h3>
                                 <small>CMO</small>
                              </div>
                           </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-3 team-col pb-50 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                           <div className="team-bg">
                              <div className="team-mem">
                                 <div className="team-mem-info">
                                    <a href="#"><img src="assets/images/Timo Trippler.png" alt="" /></a>
                                 </div>
                                 <div className="team_social_s2 list_none">
                                    <ul>
                                       <li><a href="#"><i className="fab fa-facebook"></i></a></li>
                                       <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                       <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                                       <li><a href="#"><i className="fab fa-linkedin"></i></a></li>
                                    </ul>
                                 </div>
                              </div>
                              <div className="team-cont">
                                 <h3><a href="#">Timo Trippler</a></h3>
                                 <small>Project Advisor</small>
                              </div>
                           </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-3 team-col pb-50 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                           <div className="team-bg">
                              <div className="team-mem">
                                 <div className="team-mem-info">
                                    <a href="#"><img src="assets/images/1630332485073.JPEG" alt="" /></a>
                                 </div>
                                 <div className="team_social_s2 list_none">
                                    <ul>
                                       <li><a href="#"><i className="fab fa-facebook"></i></a></li>
                                       <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                       <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                                       <li><a href="#"><i className="fab fa-linkedin"></i></a></li>
                                    </ul>
                                 </div>
                              </div>
                              <div className="team-cont">
                                 <h3><a href="#"> Ehindero Kingdavid</a></h3>
                                 <small>Sr. Content Manager</small>
                              </div>
                           </div>
                        </div>

                     </div>
                  </div>
               </div>
               <Footer1 />
            </div>
         </>

      )
   }
}