import React, { Component } from 'react';
import axios from 'axios';
import Header from '../directives/header'
import Footer1 from '../directives/footer1'
import config from '../config/config'
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'js-cookie';
// import Countdown from 'react-countdown';
import Countdown from '../Countdown.js';

import {
   Accordion,
   AccordionItem,
   AccordionItemHeading,
   AccordionItemButton,
   AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import MetaTags from 'react-meta-tags';

export default class termsandcondition extends Component {

   constructor(props) {
      super(props)


   }

   render() {
      const currentDate = new Date();
      const currentDate1 = (new Date()).toLocaleDateString('en-US', { day: "numeric", month: "long", year: 'numeric' })
      const year = (currentDate.getMonth() === 11 && currentDate.getDate() > 23) ? currentDate.getFullYear() + 1 : currentDate.getFullYear();
      return (

         <>
            <div className="circle-bg">
               <Header />
               <ToastContainer />
               <MetaTags>
                  <meta name="keywords" content="yuse,yuse ico,yuse token,yuse crypto,yuse token price" />
                  <title>Term & Condition - Yuse Token</title>
                  <meta name="description" content="Read out all the term & condition of Yuse Token here. Explore the agreement carefully which was last updated on 16th September 2021. Visit now to know more." />
                  <h1>Terms and Condition</h1>
                  {/* <h2>About us</h2> */}
                  {/* <h2>Mission & Vission</h2> */}
                  <link rel='canonica' href='https://yusetoken.io/termsandcondition' />

               </MetaTags>
               <br /><br />
               <br /><br />
               <br />

               {/* <section className="pages_banner_area" style={{paddingTop:'0%'}}> 
                <img src="images/parallax-layer/banner-layer-3.png" alt="" className="layer layer_1" data-depth="0.10" style={{transform: "translate3d(12.4512px, -47.6487px, 0px)", transformtyle: "preserve-3d", backfaceVisibility: "hidden" ,position: "relative", display: "block", left:" 0px", top:" 0px" }} />   
                <div className="container">
                    <h2>About us</h2>
                    
                </div>
            </section> */}


               {/* <section>

               <div className="about-container ">
                  <div className="container">
                     <div className="row  justify-content-between">
                        
                        <div className="col-12 col-md-12 col-lg-12 ">

                        <p className="pb-4 text-white" >YUSE is looking to be the leading innovator in the block chain industry, powered by some of the most experienced players in the business. Our ecosystem consists of Multiple Digital Products which is back by a single multi-utility token YUSE. For us, quality in delivery is what matters and we make it an imperative point to deliver the best while being in step with the trends in the industry.</p>
                       <p className="text-white">YUSE is definitely the right choice for the community who appreciate the most efficient system to expand the scope of their interest.  Committed to brilliance, maintaining integrity and high performance, you can’t go wrong in the business with YUSE.</p>
                      </div>
                     </div>
                  </div>
               </div>
            </section> */}
            <div className="header-banner bg-theme-grad-s2 has-ovm">
                  <div className="nk-banner">
                     <div className="banner banner-page">
                        <div className="banner-wrap">
                        <div className="container">
                           <div className="row justify-content-center align-items-center">
                              <div className="col-xl-6 col-lg-9">
                              <div className="banner-caption cpn tc-light text-center">
                                 <div className="cpn-head"><h2 className="title ttu">Terms and Condition</h2></div>
                              </div>
                              </div>
                           </div>
                        </div>
                        </div>
                     </div>
                  </div>
                  {/* .nk-banner */}
                  <div className="nk-ovm shape-z6-sm" />
                  </div>
               <section className="about_banner">



                  <div className="col-12 ">
                     <div className=" mt-3 tab-card aboutus">

                        {/* <div className="row">
                           <div className="col-sm-3">

                           </div>
                           <div className="col-sm-6">
                              <div className="card-header tab-card-header">
                                 <ul className="nav nav-tabs card-header-tabs" id="myTab" role="tablist">
                                    <li className="nav-item active">
                                       <a className="nav-link active" id="one-tab" data-toggle="tab" href="#one" role="tab" aria-controls="One" aria-selected="true">About Us</a>
                                    </li>
                                    <li className="nav-item">
                                       <a className="nav-link" id="two-tab" data-toggle="tab" href="#two" role="tab" aria-controls="Two" aria-selected="false">Mission & Vision</a>
                                    </li>
                                   
                                 </ul>
                              </div>

                           </div>

                           <div className="col-sm-3">

                           </div>


                        </div> */}


                        <div className="tab-content" id="myTabContent">
                           <div className="tab-pane fade show active p-3" id="one" role="tabpanel" aria-labelledby="one-tab">
                              <div className="container">
                                 <div className="row">
                                    <div className="col-sm-12">
                                       {/* <h5 className="card-title text-center">Terms and Condition</h5> */}
                                         {/* <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                           <a href="#" className="btn btn-primary">Go somewhere</a>   */}
                                       <div className="row gutter-vr-15px justify-content-center">
                                          <div className="col-lg-12 col-mb-10">
                                             <div className="nk-block-text">
                                                <p className="text-white">
                                                TERMS OF USE
Last updated on 16th September 2021
AGREEMENT TO TERMS
These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and
("Company," "we," "us," or “our”), concerning your access to and use of theYUSE Token website as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the "Site"). You agree that by accessing the Site, you have read, understood, and agreed to be bound by all of these Terms of Use. IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY.
Supplemental terms and conditions or documents that may be posted on the Site from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Terms of Use at any time and for any reason. We will alert you about any changes by updating the “Last updated" date of these Terms of Use, and you waive any right to receive specific notice of each such change. Please ensure that you check the applicable Terms every time you use our Site so that you
have accepted the changes in any revised Terms of Use by your continued use of the Site after the date such revised Terms of Use are posted.
The information provided on the Site is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Site from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.
                                                  </p>
                                                  <br/>
                                                <p className="text-white">
                                                INTELLECTUAL PROPERTY RIGHTS
Unless otherwise indicated, the Site is our proprietary property and all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics on the Site (collectively, the "Content") and the trademarks, service marks, and logos contained therein (the “Marks") are owned or controlled by us or licensed to us, and are protected by copyright and trademark laws and various other intellectual property rights and unfair competition laws of the United States, international copyright laws, and international conventions. The Content and the Marks are provided on the Site “AS IS” for your information and personal use only. Except as expressly provided in these Terms of Use, no part of the Site and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.
Provided that you are eligible to use the Site, you are granted a limited license to access and use the Site and to download or print a copy of any portion of the Content to which you have properly gained access solely for your personal, non-commercial use. We reserve all rights not expressly granted to you in and to the Site, the Content and the Marks.
                                                    </p>
                                           
                                               
                                                <br/>
                                    
                                         <p className="text-white">
                                         USER REPRESENTATIONS
By using the Site, you represent and warrant that:<br/>
 (1) you have the legal capacity and you agree to comply with these Terms of Use; <br/>
 (2) you are not a minor in the jurisdiction in which you reside;<br/>
  (3) you will not access the Site through automated or non-human means, whether through a bot, script, or otherwise; <br/>
  (4) you will not use the Site for any illegal or unauthorized purpose; and <br/>
  (5) your use of the Site will not violate any applicable law or regulation.
If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Site (or any portion thereof).
                                              </p>
  <br/>
        <p className="text-white">  
                                              PROHIBITED ACTIVITIES
You may not access or use the Site for any purpose other than that for which we make the Site available. The Site may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us.
As a user of the Site, you agree not to:<br/>
1. Systematically retrieve data or other content from the Site to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.<br/>
 2. Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords. <br/>
  3. Circumvent, disable, or otherwise interfere with security-related features of the Site, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Site and/or the Content contained therein. <br/>
4. Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Site. <br/>
5. Use any information obtained from the Site in order to harass, abuse, or harm another person. <br/>
6. Make improper use of our support services or submit false reports of abuse or misconduct. <br/> 
7. Use the Site in a manner inconsistent with any applicable laws or regulations. <br/>
8. Engage in unauthorized framing of or linking to the Site. <br/>
9. Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any party's uninterrupted use and enjoyment of the Site or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Site. <br/>
10. Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools. <br/>
11. Delete the copyright or other proprietary rights notice from any content. <br/>
12. Attempt to impersonate another user or person or use the username of another user. <br/>
13. Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including without limitation, clear graphics interchange formats (“gifs”), 1x1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as “spyware" or “passive collection mechanisms” or “pcms”). <br/>

14. Interfere with, disrupt, or create an undue burden on the Site or the networks or services connected to the Site. <br/> 
15. Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the Site to you. <br/>
16. Attempt to bypass any measures of the Site designed to prevent or restrict access to the Site, or any portion of the Site. <br/>
17. Copy or adapt the Site's software, including but not limited to Flash, PHP, HTML, JavaScript, or other code. <br/>
18. Except as permitted by applicable law, decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Site. <br/>
19. Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses the Site, or using or launching any unauthorized script or other software. <br/>
20. Use a buying agent or purchasing agent to make purchases on the Site. <br/>
21. Make any unauthorized use of the Site, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretenses. <br/>
22. Use the Site as part of any effort to compete with us or otherwise use the Site and/or the Content for any revenue-generating endeavor or commercial enterprise. <br/>
<br/>
USER GENERATED CONTRIBUTIONS
The Site does not offer users to submit or post content. We may provide you with the opportunity to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us or on the Site, including but not limited to text, writings, video, audio, photographs, graphics, comments, suggestions, or personal information or other material (collectively, "Contributions"). Contributions may be viewable by other users of the Site and through third-party websites. As such, any Contributions you transmit may be treated in accordance with the Site Privacy Policy. When you create or make available any Contributions, you thereby represent and warrant that: <br/>

1. The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or copying of your contributions do not and will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark, trade secret, or moral rights of any third party. <br/>
 2. You are the creator and owner of or have the necessary licenses, rights, consents, releases, and permissions to use and to authorize us, the Site, and other users of the Site to use your contributions in any manner contemplated by the Site and these Terms of Use. <br/>
3. You have the written consent, release, and/or permission of each and every identifiable individual person in your contributions to use the name or likeness of each and every such identifiable individual person to enable inclusion and use of your Contributions in any manner contemplated by the Site and these Terms of Use. <br/>
4. Your Contributions are not false, inaccurate, or misleading. <br/>
5. Your Contributions are not unsolicited or unauthorized advertising, promotional materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of solicitation. <br/>
6. Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libelous, slanderous, or otherwise objectionable (as determined by us). <br/>
7. Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone. <br/>
8. Your Contributions are not used to harass or threaten (in the legal sense of those terms) any other person and to promote violence against a specific person or class of people. <br/>
9. Your Contributions do not violate any applicable law, regulation, or rule. <br/>
10. Your Contributions do not violate the privacy or publicity rights of any third party. <br/>
11. Your Contributions do not violate any applicable law concerning child pornography, or otherwise intended to protect the health or well-being of minors.<br/>
12. Your Contributions do not include any offensive comments that are connected to race, national origin, gender, sexual preference, or physical handicap. <br/>
13. Your Contributions do not otherwise violate, or link to material that violates, any provision of these Terms of Use, or any applicable law or regulation. <br/>
Any use of the Site in violation of the foregoing violates these Terms of Use and may result in, among other things, termination or suspension of your rights to use the Site.


Any use of the Site in violation of the foregoing violates these Terms of Use and may result in, among other things, termination or suspension of your rights to use the Site.    
</p>  
<br/>
<p className="text-white"> 
CONTRIBUTION LICENSE
You and the Site agree that we may access, store, process, and use any information and personal data that you provide following the terms of the Privacy Policy and your choices (including settings).
By submitting suggestions or other feedback regarding the Site, you agree that we can use and share such feedback for any purpose without compensation to you.
We do not assert any ownership over your Contributions. You retain full ownership of all of your contributions and any intellectual property rights or other proprietary rights associated with your contributions. We are not liable for any statements or representations in your contributions provided by you in any area on the Site. You are solely responsible for your contributions to the Site and you expressly agree to exonerate us from any and all responsibility and to refrain from any legal action against us regarding your Contributions.
</p> 
<br/>
<p className="text-white">
SUBMISSIONS
You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, or other information regarding the Site ("Submissions") provided by you to us are non-confidential and shall become our sole property. We shall own exclusive rights, including all intellectual property rights, and shall be entitled to the unrestricted use and dissemination of these Submissions for any lawful purpose, commercial or otherwise, without acknowledgment or compensation to you. You hereby waive all moral rights to any such Submissions, and you hereby warrant that any such Submissions are original with you or that you have the right to submit such Submissions. You agree there shall be no recourse against us for any alleged or actual infringement or misappropriation of any proprietary right in your Submissions.
</p>
<br/>
<p className="text-white">
SITE MANAGEMENT
We reserve the right, but not the obligation, to: (1) monitor the Site for violations of these Terms of Use; (2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms of Use, including without limitation, reporting such user to law enforcement authorities; (3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your contributions or any portion thereof; (4) in our sole discretion and without limitation, notice, or liability, to remove from the Site or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems; and (5) otherwise manage the Site in a manner designed to protect our rights and property and to facilitate the proper functioning of the Site.
</p>
<br/>
<p className="text-white">
TERM AND TERMINATION
These Terms of Use shall remain in full force and effect while you use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR DELETE ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party. In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.
</p>
<br/>
<p className="text-white">
MODIFICATIONS AND INTERRUPTIONS
We reserve the right to change, modify, or remove the contents of the Site at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our site. We also reserve the right to modify or discontinue all or part of the Site without notice at any time. We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Site.
We cannot guarantee the Site will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the Site, resulting in interruptions, delays, or errors. We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Site at any time or for any reason without notice to you. You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the Site during any downtime or discontinuance of the Site. Nothing in these Terms of Use will be construed to obligate us to maintain and support the Site or to supply any corrections, updates, or releases in connection therewith.
</p>
<br/>
<p className="text-white">
MISCELLANEOUS
These Terms of Use and any policies or operating rules posted by us on the Site or in respect to the Site constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of these Terms of Use shall not operate as a waiver of such right or provision. These Terms of Use operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control. If any provision or part of a provision of these Terms of Use is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these Terms of Use and does not affect the validity and enforceability of any remaining provisions. There is no joint venture, partnership, employment or agency relationship created between you and us as a result of these Terms of Use or use of the Site. You agree that these Terms of Use will not be construed against us by virtue of having drafted them. You hereby waive any and all defenses you may have based on the electronic form of these Terms of Use and the lack of signing by the parties hereto to execute these Terms of Use.
</p>
<br/>
<p className="text-white">
CONTACT US
In order to resolve a complaint regarding the Site or to receive further information regarding use of the Site, please contact us at: support@yusetoken.io

</p>
                                       </div>
                                       <br />
                                     
                                       </div>
                                       </div>
                                    </div>

                                 </div>
                              </div>

                           </div>
                         


                        </div>
                     </div>
                  </div>
               </section>






               <Footer1 />
            </div>
         </>

      )
   }
}