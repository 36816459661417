import './App.css';
import React, { components } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import config from './config/config'
import Header from './directives/header'
import Header1 from './directives/header1'
import Header2 from './directives/header2'
// import Footer from './directives/footer'

import home2 from './components/home2'
import home from './components/home'
import login from './components/login'
import register from './components/register'
import verifyaccount from './components/verify-account'
import VerifyAccount from './components/register'
import dashboard from './components/dashboard'
// import dashboard2 from './components/dashboard2'
import transaction from './components/transaction'
import authoredit from './components/authoredit'

import resetpassword from './components/resetpassword'

import generatepassword from './components/generatepassword'
// import buynow from './components/buynow'
import twoauthsecurity from './components/twoauthsecurity'
import about from './components/about'
import faq from './components/faq'
import demo from './components/demo'
import regthankyou from './components/reg-thankyou'
import support_request from './components/support_request'
import termsandcondition from './components/termsandcondition'
import privacyandpolicy from './components/privacyandpolicy'
import supportRequest from './components/supportRequest'
import withdraw from './components/withdraw'
import staking from './components/staking'
import incomeList from './components/incomeList'
import Cookies from 'js-cookie';
import Gallery from './components/gallery'
function App() {
  const loginData = (!Cookies.get('loginSuccess')) ? '' : JSON.parse(Cookies.get('loginSuccess'));
  const loginId = (!loginData.data) ? 0 : loginData.data.id;
  
  return (
    <BrowserRouter>

      <Switch>
        {loginId == 0 ?
          <>
            <Route path={`${config.baseUrl}`} exact component={login} />
            <Route path={`${config.baseUrl}gallery`} exact component={Gallery} />
            <Route path={`${config.baseUrl}home2`} exact component={login} />
            <Route path={`${config.baseUrl}login`} exact component={login} />
            <Route path={`${config.baseUrl}register`} exact component={register} />
            <Route path={`${config.baseUrl}verifyaccount`} exact component={verifyaccount} />
            <Route path={`${config.baseUrl}support_request`} component={support_request} />
            <Route path={`${config.baseUrl}supportRequest`} component={supportRequest} />
            <Route path={`${config.baseUrl}termsandcondition`} component={termsandcondition} />
            <Route path={`${config.baseUrl}privacyandpolicy`} component={privacyandpolicy} />
            <Route path={`${config.baseUrl}register/:referral_code`} exact component={register} />
            <Route path={`${config.baseUrl}verifyAccount/:token`} component={VerifyAccount} />
            <Route path={`${config.baseUrl}authoredit`} exact component={authoredit} />
            <Route path={`${config.baseUrl}dashboard`} exact component={login} />
            <Route path={`${config.baseUrl}transaction`} exact component={login} />
            <Route path={`${config.baseUrl}staking`} component={login} />
            <Route path={`${config.baseUrl}incomeList/:id`} component={login} />
            <Route path={`${config.baseUrl}generatepassword`} exact component={generatepassword} />
            <Route path={`${config.baseUrl}resetpassword`} exact component={resetpassword} />
            <Route path={`${config.baseUrl}resetpassword/:token`} component={resetpassword} />
            {/* <Route path={`${config.baseUrl}buynow`}  component={buynow} />  */}
            <Route path={`${config.baseUrl}about`} component={about} />
            <Route path={`${config.baseUrl}regthankyou`} component={regthankyou} />
            <Route path={`${config.baseUrl}googleauthentication`} component={twoauthsecurity} />
            <Route path={`${config.baseUrl}faq`} component={faq} />

            <Route path={`${config.baseUrl}demo`} component={demo} />

            <Route path={`${config.baseUrl}withdraw`} component={login} /></> :
          <>

            <Route path={`${config.baseUrl}`} exact component={login} />
            <Route path={`${config.baseUrl}home2`} exact component={login} />
            <Route path={`${config.baseUrl}login`} exact component={dashboard} />
            <Route path={`${config.baseUrl}register`} exact component={dashboard} />
            <Route path={`${config.baseUrl}verifyaccount`} exact component={dashboard} />

            <Route path={`${config.baseUrl}register/:referral_code`} exact component={dashboard} />
            <Route path={`${config.baseUrl}verifyAccount/:token`} component={VerifyAccount} />
            <Route path={`${config.baseUrl}authoredit`} exact component={authoredit} />
            <Route path={`${config.baseUrl}dashboard`} exact component={dashboard} />
            <Route path={`${config.baseUrl}transaction`} exact component={transaction} />
            <Route path={`${config.baseUrl}staking`} component={staking} />
            <Route path={`${config.baseUrl}incomeList/:id`} component={incomeList} />
            <Route path={`${config.baseUrl}generatepassword`} exact component={generatepassword} />
            <Route path={`${config.baseUrl}resetpassword`} exact component={dashboard} />
            <Route path={`${config.baseUrl}resetpassword/:token`} component={dashboard} />
            {/* <Route path={`${config.baseUrl}buynow`}  component={buynow} />  */}
            <Route path={`${config.baseUrl}about`} component={about} />
            <Route path={`${config.baseUrl}regthankyou`} component={regthankyou} />
            <Route path={`${config.baseUrl}googleauthentication`} component={dashboard} />
            <Route path={`${config.baseUrl}faq`} component={faq} />

            <Route path={`${config.baseUrl}demo`} component={demo} />
            <Route path={`${config.baseUrl}support_request`} component={support_request} />
            <Route path={`${config.baseUrl}supportRequest`} component={supportRequest} />
            <Route path={`${config.baseUrl}termsandcondition`} component={termsandcondition} />
            <Route path={`${config.baseUrl}privacyandpolicy`} component={privacyandpolicy} />
            <Route path={`${config.baseUrl}withdraw`} component={withdraw} />
          </>}

      </Switch>

    </BrowserRouter>
  );
}

export default App;




