import React, { Component } from 'react';
import axios from 'axios';
import Header1 from '../directives/header1'
// import Footer from '../directives/footer'
import config from '../config/config'
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
// import { ToastContainer, toast } from 'react-toastify';
import toast, { Toaster } from 'react-hot-toast';
import Cookies from 'js-cookie';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import copy from 'copy-to-clipboard';

const headers = {
   'Content-Type': 'application/json'
};
export default class authoredit extends Component {
   constructor(props) {
      super(props)
      this.loginData = (!Cookies.get('loginSuccess')) ? [] : JSON.parse(Cookies.get('loginSuccess'));
      console.log(this.loginData);
      this.state = {
         user_name: '',
         full_name: '',
         profileData: '',
         image_file: null,
         image_preview: '',
         image_file1: null,
         image_preview1: '',
         email: '',
         currentPassword: '',
         password: '',
         password2: '',
         twoAuthenticationData: '',
         enableTwoFactor: '',
         talentStatusAPIData: '',
         copied: false,
         referralUserList: [],
         totalBonusReferral: 0,
         country_id: '0',
         countryList: [],
         active_tab:1         

      }
      this.onChange = this.handleChange.bind(this);
      this.submitForm = this.submitForm.bind(this)
      this.onChange = this.handleChangePassword.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this)
      this.onChange = this.handleTwoWay.bind(this);
      this.getReferralUserList = this.getReferralUserList.bind(this);

      this.twoAuthenticationVerifyAPI = this.twoAuthenticationVerifyAPI.bind(this)
   }
   componentDidMount() {
      this.getProfilePicAPI();
      this.twoAuthenticationAPI()
      this.getReferralUserList()
      this.getCountryListAPI()
   }

   copyToClipboard(id) {
      copy(id);

      toast.success("Copied", {

      });


   }

   async getCountryListAPI() {
      await axios({
        method: 'post',
        url: `${config.apiUrl}getcountries`,
        data: { "email": this.loginData.data.user_email, "user_id": this.loginData.data?.id }
      }).then(response => {
        if (response.data.success === true) {
          this.setState({
            countryList: response.data.response
          })
        }
      })
    }

   copyToClipboardLink(id) {
      var url = `https://yusetoken.io/register/${id}`;

      copy(url);

      toast.success("Copied", {

      });


   }



   //============================  Password change  ====================================
   handleChangePassword = e => {
      // alert('3')
      this.setState({
         [e.target.name]: e.target.value
      })
      console.log(e.target.name, e.target.value);
   }

   handleChange = event => {
      event.preventDefault()
      let value = event.target.value;
      this.setState(prevState => ({
         profileData: { ...prevState.profileData, [event.target.name]: value }
      }))
   }

   handleTwoWay = event => {
      event.preventDefault()

      if (event.target.checked === true && event.target.type === 'checkbox') {
         event.target.value = '1'
      }
      else if (event.target.checked === false && event.target.type === 'checkbox') {
         event.target.value = '0'
      }
      let value = event.target.value;
      this.setState(prevState => ({
         twoAuthenticationData: { ...prevState.twoAuthenticationData, [event.target.name]: value }
      }))
   }


   async getReferralUserList() {
      await axios({
         method: 'post',
         url: `${config.apiUrl}getReferralUserList`,
         headers: { "Authorization": this.loginData?.Token },
         data: { "email": this.loginData.data.user_email, "user_id": this.loginData.data?.id }
      }).then(response => {
         if (response.data.success === true) {
            var token = 0;
            response.data.response.map(item => (
               (item.tokenBonus) ?
                  token = token + parseFloat(item.tokenBonus) : ''
            ));
            this.setState({
               referralUserList: response.data.response,
               totalBonusReferral: token
            })
         }
      })
   }
   async getProfilePicAPI() {
      await axios({
         method: 'post',
         url: `${config.apiUrl}getuserprofile`,
         headers: { "Authorization": this.loginData?.Token },
         data: { "email": this.loginData.data.user_email, "user_id": this.loginData.data?.id }
      }).then(response => {
         if (response.data.success === true) {
            this.setState({
               profileData: response.data.response
            })
         }
      })
   }

   //================================================  Update information API integrate  =============
   handleImagePreviewAvatar = (e) => {
      let image_as_base64 = URL.createObjectURL(e.target.files[0])
      let image_as_files = e.target.files[0];
      this.setState({
         image_preview: image_as_base64,
         image_file: image_as_files,
      })
   }
   //================================================  Update information API integrate  =============
   handleImagePreviewBanner = (e) => {
      let image_as_base64 = URL.createObjectURL(e.target.files[0])
      let image_as_files = e.target.files[0];
      this.setState({
         image_preview1: image_as_base64,
         image_file1: image_as_files,
      })
   }

   async submitForm(e) {
      e.preventDefault()
      let formData = new FormData();
      formData.append('email', this.state.profileData.email);
      formData.append('profile_pic', this.state.image_file);
      formData.append('banner', this.state.image_file1);
      formData.append('user_name', this.state.profileData.user_name);
      formData.append('full_name', this.state.profileData.full_name);
      formData.append('country_id', this.state.profileData.country_id);
      formData.append('user_id', this.loginData.data?.id);
      axios({
         method: 'post',
         url: `${config.apiUrl}updateuserprofile`,
         headers: { "Authorization": this.loginData?.Token },
         data: formData
      })
         .then(response => {
            if (response.data.success === true) {
               toast.success(response.data.msg, {

               });
               this.getProfilePicAPI()
               setTimeout(() => {
                  window.location.reload()
               }, 1000);
            }
            else if (response.data.success === false) {
               toast.error(response.data.msg, {

               });
            }
         })
         .catch(err => {
            toast.error(err?.response?.data?.msg, {

            });
         })
   }
   //================================================= Update password ========================
   handleSubmit(e) {
      e.preventDefault();
      axios({
         method: 'post',
         url: `${config.apiUrl}changepassword`,
         headers: { "Authorization": this.loginData?.Token },
         data: {
            email: this.state.profileData.email, currentPassword: this.state.currentPassword,
            password: this.state.password, password2: this.state.password2,
            "user_id": this.loginData.data?.id
         }
      })
         .then(result => {
            if (result.data.success === true) {
               toast.success(result.data.msg, {

               });
               window.location.reload()
            }
            else if (result.data.success === false) {
               toast.error(result.data.msg, {

               });
            }
         }).catch(err => {
            toast.error(err.response.data?.msg, {

            })
         })
   }

   async twoAuthenticationAPI() {
      await axios({
         method: 'post',
         url: `${config.apiUrl}getQR`,
         headers: { "Authorization": this.loginData?.Token },
         data: { "user_id": this.loginData.data.id, 'email': this.loginData.data.user_email }
      }).then(response => {
         if (response.data.success === true) {
            // toast.success('2FA Authentication has been disable successfully!', {

            // });
            this.setState({
               twoAuthenticationData: response.data.response
            })
         }
      })

   }
   //==================================  twoupdateAuthentication ========================

   async twoAuthenticationVerifyAPI(e) {
      e.preventDefault()
      await axios({
         method: 'post',
         url: `${config.apiUrl}twoAuthenticationVerify `,
         headers: { "Authorization": this.loginData?.Token },
         data: { "user_id": this.loginData.data.id, 'SecretKey': this.state.twoAuthenticationData.SecretKey, 'enableTwoFactor': this.state.twoAuthenticationData.enableTwoFactor, 'email': this.loginData.data.user_email }
      }).then(response => {

         if (response.data.success === true) {
            toast.success(response.data.msg, {

            });
            window.location.reload()
         }
      }).catch(err => {
         toast.error('Token mismatch', {

         })
      })
   }

   formatInput = (e) => {
      // Prevent characters that are not numbers ("e", ".", "+" & "-") 
      let checkIfNum;
      if (e.key !== undefined) {
         // Check if it's a "e", ".", "+" or "-"
         checkIfNum = e.key === "e" || e.key === "." || e.key === "+" || e.key === "-";
      }
      else if (e.keyCode !== undefined) {
         // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
         checkIfNum = e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 187 || e.keyCode === 189;
      }
      return checkIfNum && e.preventDefault();
   }

   render() {
      return (
         <>
            <Header1 />
            <Toaster
               position="top-right"
               reverseOrder={false} />
            <section className="page-login personal-setting" style={{ backgroundColor: "#e0e8f3" }}>
               <div className="row">
                  <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12">

                     <div className="header-menu-tabs">
                        <a href="" className="aside_logo"><img className="logo-c active be_logo aside_logo" src="images/YUSE_Logo_purple.png" alt="logo" /></a>
                        <aside className="web_view">
                           <nav>
                              <ul>
                                 <Link to={`${config.baseUrl}dashboard`}><li><i class="fa fa-th" aria-hidden="true"></i>Dashboard</li></Link>
                                 <Link to={`${config.baseUrl}transaction`}><li><i class="fal fa-money-bill-alt"></i>Transactions</li></Link>
                                 <Link to={`${config.baseUrl}authoredit`}><li className="active "><i class="fa fa-user-circle-o" aria-hidden="true"></i>My Profile</li></Link>
                                 <Link to={`${config.baseUrl}supportRequest`}><li><i class="fal fa-user-headset"></i>Support</li></Link>
                                 <Link to={`${config.baseUrl}withdraw`}><li ><i className="fal fa-money"></i>Withdraw</li></Link>
                                 <Link to={`${config.baseUrl}staking`}><li className={this.state.lastSegment == 'staking'? 'active' : '' }><i className="fa fa-object-ungroup"></i>Staking</li></Link>
                                 <Link to={`${config.baseUrl}`}><li><i class="fal fa-home-alt"></i>Home</li></Link>
                              </ul>
                           </nav>
                        </aside>
                     </div>
                  </div>

                  <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12">
                     <div className="container-fluid">
                        <div className="transaction_heading">
                           <h6 className="heading_anchor">
                              <a href={`${config.baseUrl}dashboard`}>Dashboard</a> <span style={{ color: '#707070' }}>/ Profile Details</span>
                           </h6>
                        </div>
                        <div className="content-area bg-white card bg-shadow">
                           <div className="card-innr">
                              <div className="card-head">

                                 {/* <h4 className="card-title" style={{ textAlign: 'right', marginTop: '-24px' }}>Referral Code - <span style={{ color: '#000' }}>{this.state.profileData?.referral_code} </span> &nbsp;
                                          <i onClick={this.copyToClipboard.bind(this, this.state?.profileData?.referral_code)} title="copy" className="fa fa-copy" style={{ color: 'purple', cursor: 'pointer' }}></i></h4>
                                       <br/>   
                                       <h4 className="card-title" style={{ textAlign: 'right', marginTop: '-24px' }}>Referral Link - <span style={{ color: '#000' }}>{this.state.profileData?.referral_code} </span> &nbsp;
                                          <i onClick={this.copyToClipboardLink.bind(this, this.state?.profileData?.referral_code)} title="copy" className="fa fa-copy" style={{ color: 'purple', cursor: 'pointer' }}></i></h4> */}

                              </div>
                              <ul className="nav nav-tabs nav-tabs-line" role="tablist">
                                 <li className="nav-item"><a className="nav-link active" onClick={e=>this.setState({active_tab:1})} data-toggle="tab" href="#personal-data2">Personal Data</a></li>

                                 <li className="nav-item"><a className="nav-link" data-toggle="tab" onClick={e=>this.setState({active_tab:2})} href="#password2">Password</a></li>

                                 <li className="nav-item"><a className="nav-link" data-toggle="tab" onClick={e=>this.setState({active_tab:3})} href="#settings2">Referral</a></li>
                              </ul>


                              <div className="tab-content" id="profile-details">

                                 <div className="tab-pane fade show active" id="personal-data2">
                                    <form>
                                       <div className="row">
                                          <div className="col-md-12">
                                             <div className="input-item input-with-label"><label for="full-name" className="input-item-label">Name</label>
                                                <input className="input-bordered" autocomplete="off" type="text" id="user-name" name="user_name" onChange={this.handleChange} value={this.state.profileData.user_name} /></div>
                                          </div>
                                          {/* <div className="col-md-6">
                                                   <div className="input-item input-with-label"><label for="full-name" className="input-item-label">Last Name</label>
                                                      <input className="input-bordered" type="text" id="full-name" name="full_name" onChange={this.handleChange} value={this.state.profileData.full_name} /></div>
                                                </div> */}
                                          <div className="col-md-12">
                                             <div className="input-item input-with-label"><label for="email-address" className="input-item-label">Email Address</label>
                                                <input readOnly className="input-bordered" type="text" id="email-address" name="email" onChange={this.handleChange} value={this.state.profileData.email} /></div>
                                          </div>

                                          <div className="col-md-12">
                                             <div className="input-item input-with-label"><label for="email-address" className="input-item-label">Country</label>
                                                <select value={this.state.profileData?.country_id} className="input-bordered" onChange={this.handleChange} name='country_id' >
                                                   <option value="0" >Select Country</option>
                                                   {this.state.countryList.map((item) => (
                                                      <option value={item.id}>{item.name}</option>
                                                   ))}
                                                </select>
                                             </div>
                                          </div>

                                          <div className="col-md-6">
                                             {/* <div className="input-item input-with-label"><label for="mobile-number" className="input-item-label">Mobile Number</label><input className="input-bordered" type="number" id="mobile-number" name="mobile" value="8517045651"/></div> */}
                                          </div>

                                          <div className="col-md-6">
                                             {/* <div className="input-item input-with-label"><label for="date-of-birth" className="input-item-label">Date of Birth</label><input className="input-bordered" type="date" id="date-of-birth" name="dob" value="1997-09-19"/></div> */}
                                          </div>

                                          <div className="col-md-6">

                                          </div>
                                          <div className="col-md-12">
                                             <div className="input-item input-with-label"><label for="date-of-birth" className="input-item-label">Profile</label>
                                                <input className="input-bordered" type="file" name="profile" accept="image/*" onChange={this.handleImagePreviewAvatar} />
                                                {/* {this.state.profileData.profile_pic === '' || this.state.profileData.profile_pic === null ?  */}
                                                <img className="login-user mt-4" style={{ width: '110px' }} src={`${config.imageUrl1}${this.state.profileData.profile_pic}`} alt="" />
                                                {/* 	<img className="image-auth login-user"  src={`${config.imageUrl1}${this.state.profileData.profile_pic}`} alt=""/> */}
                                             </div>
                                          </div>
                                       </div>
                                       <div className="gaps-1x"></div>
                                       <div className="d-sm-flex justify-content-between align-items-center">
                                          <button className="btn " onClick={this.submitForm}>Update</button>
                                          <div className="gaps-2x d-sm-none"></div>
                                       </div>
                                    </form>
                                 </div>


                                 <div className="tab-pane fade" id="password2">
                                    <form>
                                       <div className="row">
                                          <div className="col-md-6">
                                             <div className="input-item input-with-label"><label for="old-pass" className="input-item-label">Old Password</label>
                                                <input className="input-bordered" type="password" name="currentPassword" onChange={this.handleChangePassword} value={this.state.currentPassword} id="old-pass" /></div>
                                          </div>
                                       </div>
                                       <div className="row">
                                          <div className="col-md-6">
                                             <div className="input-item input-with-label"><label for="new-pass" className="input-item-label">New Password</label>
                                                <input className="input-bordered" type="password" id="new-pass" name="password" onChange={this.handleChangePassword} value={this.state.password} /></div>
                                          </div>
                                          <div className="col-md-6">
                                             <div className="input-item input-with-label"><label for="confirm-pass" className="input-item-label">Confirm Password</label>
                                                <input className="input-bordered" type="password" id="confirm-pass" name="password2" onChange={this.handleChangePassword} value={this.state.password2} /></div>
                                          </div>
                                       </div>
                                       <div className="note note-plane d-flex note-info pdb-1x">
                                          <em className="fa fa-info-circle "></em>&nbsp;
                                          <p className="text-black"> Password should be minmum 6 letter.</p>
                                       </div>
                                       <div className="gaps-1x"></div>
                                       <div className="d-sm-flex justify-content-between align-items-center">
                                          <button className="btn" disabled={!this.state.password || !this.state.currentPassword || !this.state.password2} onClick={this.handleSubmit}>Update Password</button>
                                          <div className="gaps-2x d-sm-none"></div>
                                       </div>
                                    </form>
                                 </div>

                                 <div className="tab-pane fade" id="settings2">
                                    <div class="row">
                                       <div class="col-sm-6">
                                          <h4>Total Referral Bonus : <span style={{ fontWeight: 300 }}> {parseFloat(this.state.totalBonusReferral).toFixed(2)} YUSE </span> </h4></div>
                                       <div class="col-sm-6">  <h4 style={{ textAlign: 'right' }}>Referral Code - <span style={{ color: '#000', fontWeight: 300 }}>{this.state.profileData?.referral_code} </span> &nbsp;
                                          <i onClick={this.copyToClipboard.bind(this, this.state?.profileData?.referral_code)} title="copy" className="fa fa-copy" style={{ color: 'purple', cursor: 'pointer' }}></i></h4>

                                          <h4 style={{ textAlign: 'right' }}>
                                             Referral Link - <span style={{ color: '#000', fontWeight: 300 }}>
                                                {(!this.state.profileData?.referral_code) ? '' : 'https://yusetoken.io/register/' + this.state.profileData?.referral_code} </span> &nbsp;
                                             <i onClick={this.copyToClipboardLink.bind(this, this.state?.profileData?.referral_code)} title="copy" className="fa fa-copy" style={{ color: 'purple', cursor: 'pointer' }}></i></h4>  </div> </div>

                                    <div className="table-responsive">
                                       <table className="table">
                                          <tr>
                                             <th>S.No.</th>
                                             <th>My Referral</th>
                                             <th>Date</th>
                                             <th>Referral Bonus</th>
                                          </tr>
                                          {(this.state.referralUserList.length == 0) ?
                                             <tr>
                                                <td colSpan="5">No Referral Found!!</td>
                                             </tr>
                                             :
                                             this.state.referralUserList.map((item, i) => (
                                                <tr>
                                                   <td>{i + 1}</td>
                                                   <td>{item.user_name} <small>({item.email})</small></td>
                                                   <td>{(item.datetime.replace('.000Z', '')).replace('T', ' ')}</td>
                                                   <td>{(!item.tokenBonus) ? '0.00' : parseFloat(item.tokenBonus).toFixed(2)} YUSE </td>

                                                </tr>
                                             ))}


                                       </table>
                                    </div>
                                 </div>



                              </div>
                           </div>
                          {this.state.active_tab==1? <div className="col-xs-12 col-md-12 mt-5 ">
                              <div className="content-area bg-white card bg-shadow ">
                                 <div className="card-innr">
                                    <div className="card-head">
                                       <h3 className="card-title">Security and Authentication </h3>
                                    </div>
                                    <h4 className=""><strong>Two-Factor Authentication with Email -</strong></h4>
                                    <p className="text-black">Two-Factor Authentication (2FA) is an extra layer of security to ensure that only you have the ability to Login.</p>
                                    {/* <img> */}


                                    <form>

                                       <div className="row">
                                          <div className="col-sm-6"><div className="form-group">
                                             <input type="text" name="SecretKey" className=" mt-3" style={{ border: "1px solid #ccc" }} onKeyPress={(event) => { if (!/^\d*[.]?\d{0,1}$/.test(event.key)) { event.preventDefault(); } }} value={this.state.twoAuthenticationData.SecretKey} onChange={this.handleTwoWay} onKeyDown={this.formatInput} />
                                          </div></div>
                                          <div className="col-sm-6"></div>
                                          <div className="col-sm-6">
                                             <img src={this.state.twoAuthenticationData.QR_code} />
                                          </div>
                                          <br />
                                          <div className="col-sm-6"></div>
                                          <div className="col-sm-6">
                                             Status : {this.state.twoAuthenticationData?.enableTwoFactor === 1 ? "Enabled" : "Disabled"}
                                             {/* Enable/Disabled: */}
                                             {/* {this.state.twoAuthenticationData?.enableTwoFactor === 1 ?
                                           <input className="input-checkbox100" id="ckb1" value="1" checked type="checkbox"
                                                   name="enableTwoFactor" onChange={this.handleTwoWay} />
                                                :
                                                <input className="input-checkbox100" id="ckb1" value="0" type="checkbox"
                                                   name="enableTwoFactor" onChange={this.handleTwoWay} />

                                             }

                                             <label className="label-checkbox100" for="ckb1" >
                                                Enable 2FA
                                             </label> */}
                                          </div>
                                          <div className="col-sm-6"></div>
                                          <div className="col-sm-6">
                                             <button className="btn " disabled={!this.state.twoAuthenticationData.SecretKey} type="submit" onClick={this.twoAuthenticationVerifyAPI}>Submit</button></div>
                                          <div className="col-sm-6"></div>

                                       </div>

                                       {/* <div className="col-md-12">
            <div className="input-item input-with-label">
			<input type="number" value={this.state.twoAuthenticationData.SecretKey} onChange={this.handleTwoWay} 	name="SecretKey" className="form-control mt-3" onKeyDown={ this.formatInput }/>
			</div>
         </div>

         <div className="col-md-6">
            <div className="input-item input-with-label"><label for="full-name" className="input-item-label">Enter Auth Code</label>
			{this.state.twoAuthenticationData?.enableTwoFactor === 1 ? 
			<input className="input-checkbox100" id="ckb1" value="1" checked type="checkbox" 
			name="enableTwoFactor" onChange={this.handleTwoWay}/>
			:
			<input className="input-checkbox100" id="ckb1" value="0"  type="checkbox"
			 name="enableTwoFactor" onChange={this.handleTwoWay}/>
			
		}
           	</div>

         </div>
         <div className="col-sm-12 justify-content-between align-items-center pdt-1-5x">
            <span className="text-light ucap d-inline-flex align-items-center">
            <span className="mb-0"><small>Current Status:</small></span>
             <span className="badge badge-disabled ml-2">Disabled</span></span>
             <div className="col-sm-6"><img src={this.state.twoAuthenticationData.QR_code}/></div>
            <div className="gaps-2x d-sm-none"></div>
			<br/>
            <button type="submit" className="order-sm-first btn " disabled={!this.state.twoAuthenticationData.SecretKey} type="submit" onClick={this.twoAuthenticationVerifyAPI}>Enable 2FA</button>
         </div> */}

                                    </form>
                                 </div>
                              </div>
                           </div>:''}
                           <div id="two-factor-modal" className="modal fade" role="dialog" style={{ display: "none" }} aria-hidden="true">
                              <div className="modal-dialog  modal-dialog-centered">
                                 {/* <!-- Modal content--> */}
                                 <div className="modal-content no-padding">
                                    <div className="modal-body">
                                       <div className="row">
                                          <div className="col-12 text-right">
                                             <button type="button" className="close" data-dismiss="modal">×</button>
                                          </div>
                                       </div>
                                       <div className="row justify-content-center mt-1 mb-3">
                                          <h4 className="modal-title text-center">Two-Factor Verification</h4>
                                       </div>
                                       <div className="row justify-content-center text-center modal-content-padding">
                                          <p>Check your email for your one-time access code.</p>
                                          <p>Enter your code to <span className="with-confirm-text d-none">confirm your </span><span><strong className="two-factor-verification-reason">Enable Email 2FA.</strong></span></p>
                                       </div>
                                       <div className="row justify-content-center text-center px-4">
                                          <div id="two_factor_error_message" className="alert alert-danger d-none w-100" role="alert"></div>
                                       </div>
                                       <div className="row justify-content-center mt-1 mb-4">
                                          <div className="single-number">
                                             <div className="single-number-container">
                                                <div className="single-number-input">
                                                   <div className="single-number-input-container">
                                                      <div className="single-number-input-item single-number-input-item-0">
                                                         <input type="number" pattern="\d*" min="0" max="9" />
                                                      </div>
                                                      <div className="single-number-input-item single-number-input-item-1">
                                                         <input type="number" pattern="\d*" min="0" max="9" />
                                                      </div>
                                                      <div className="single-number-input-item single-number-input-item-2">
                                                         <input type="number" pattern="\d*" min="0" max="9" />
                                                      </div>
                                                      <div style={{ marginTop: "-25px" }}>
                                                         <span className="input-seperator">.</span>
                                                      </div>
                                                      <div className="single-number-input-item single-number-input-item-3">
                                                         <input type="number" pattern="\d*" min="0" max="9" />
                                                      </div>
                                                      <div className="single-number-input-item single-number-input-item-4">
                                                         <input type="number" pattern="\d*" min="0" max="9" />
                                                      </div>
                                                      <div className="single-number-input-item single-number-input-item-5">
                                                         <input type="number" pattern="\d*" min="0" max="9" />
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="row justify-content-center m-3">
                                          <button id="two-factor-modal-confirm-button" type="button" className="btn btn-primary btn-lg btn-ext-padding" disabled="">Confirm</button>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>

                     </div>
                  </div>
               </div>

            </section>
         </>
      )
   }
}