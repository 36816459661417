import React, { Component } from 'react';
// import Header from '../directives/header'
// import Footer from '../directives/footer'
import config from '../config/config'
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom'
import axios from 'axios';
import Favicon from 'react-favicon'

export default class header1 extends Component {

   constructor(props) {
      super(props)
      this.state = {
         profileData: '',
         searchData: '',
         searchDataList: [],
         headerShowData: '',
         talentStatusAPIData: '',
         nftIndex: '',
         talentIndex: '',
         formStep: 0,
         switchClassOPen: 0


      }
      this.loginData = (!Cookies.get('loginSuccess')) ? [] : JSON.parse(Cookies.get('loginSuccess'))
      this.loginId = (!this.loginData.data) ? 0 : this.loginData.data.id;
      this.onChange = this.onChange.bind(this)
      this.handleChange2 = this.handleChange2.bind(this)
   }
   componentDidMount() {

      // alert("ok = " + this.loginId)
      if(this.loginId==0){
         window.location.href=`${config.baseUrl}login` 
      }
      
      this.getProfilePicAPI()

      var timeCount = 0
      setInterval(() => {
         if (timeCount > 3600) {
            Cookies.remove('loginSuccess')
            window.location.href = config.baseUrl
         }
         timeCount++;
      }, 1000);
   }

   onChange = e => {
      this.setState({
         [e.target.name]: e.target.value
      })

      if (e.target.name === 'searchData') {
         this.allSearchAPI(e.target.value)
      }
   }

   handleChange2(id) {

      if (id === 0) {

         this.setState({
            formStep: 1
         })
      }
      else if (id === 1) {

         this.setState({
            formStep: 0
         })
      }
   }
   async getProfilePicAPI() {
      await axios({
         method: 'post',
         url: `${config.apiUrl}getuserprofile`,
         headers: { "Authorization": this.loginData?.Token },
         data: { "email": this.loginData.data.user_email, "user_id": this.loginData.data?.id },
      }).then(response => {
         if (response.data.success === true) {
            this.setState({
               profileData: response.data.response
            })
         }
      })
   }





   logout() {
      Cookies.remove('loginSuccess')
      setTimeout(() => {
         window.location.href = `${config.baseUrl}`
      });
   }

   redir() {
      window.location.href = `${config.baseUrl}`

   }

   redirectTo(id) {
      console.log(id);
   }

   loading(id) {
      setTimeout(() => {
         window.location.href = `${config.baseUrl}featurescreator/${id}`
         window.location.reload(true)
      }, 500);
   }

   loadingGroup(id) {
      setTimeout(() => {
         window.location.href = `${config.baseUrl}itemdetails/${id}`
         window.location.reload(true)
      }, 500);
   }

   loadingAccount() {
      setTimeout(() => {
         window.location.reload()
      });
   }

   headerShow(id) {

      if (id === '1') {
         this.setState({
            headerShowData: '1'
         })
      }
      else if (id === '0') {
         this.setState({
            headerShowData: ''
         })
      }
   }

   navOpen(id) {

      if (id === 1) {
         this.setState({
            switchClassOPen: 0
         })
      }
      else if (id === 0) {
         this.setState({
            switchClassOPen: 1
         })
      }
   }



   render() {
      return (

         <>
            <Favicon url='https://yusetoken.io/assets/images/Final-Logo-1.png' />

            <header id="innerHeader">
               <div className="container-fluid ">
                  <div className="row">
                    <div classname="col-lg-2 col-md-2 col-2">
                    <Link to={`${config.baseUrl}`} style={{ zIndex: '1' }} onClick={this.redir.bind(this)}>
                        <div className="brand-be">
                           <img className="logo-c active be_logo" src="images/YUSE_Logo_White.png" alt="logo" />
                        </div>
                     </Link>
                    </div>
                    <div classname="col-lg-10 col-md-10 col-10">
                    <div className="header-menu-block">
                        <button className={this.state.switchClassOPen === 0 ? "cmn-toggle-switch cmn-toggle-switch__htx" : "cmn-toggle-switch cmn-toggle-switch__htx active"} onClick={this.navOpen.bind(this, this.state.switchClassOPen)} ><span></span></button>
                        <div class={this.state.switchClassOPen === 0 ? "fat-nav" : "fat-nav active"} style={{ display: this.state.switchClassOPen === 0 ? "none" : 'block' }}>
                        <ul className="mobile_view_link">
                  <li className="current-menu-item menu-item-has-children">
                     <a href={`${config.baseUrl}dashboard`}><i class="fa fa-th" aria-hidden="true"></i>Buy Token</a>
                  </li>
                  <li><Link to={`${config.baseUrl}transaction`}><i class="fal fa-money-bill-alt"></i>Transactions</Link></li>
                  {/* <li><a href="#Community-id">Community</a></li> */}
                  <li><a href={`${config.baseUrl}authoredit`} ><i class="fa fa-user-circle-o" aria-hidden="true"></i>My Profile</a></li>
                  <li><a href={`${config.baseUrl}supportRequest`} ><i class="fal fa-user-headset"></i>Support</a></li>

                  <li><Link to={`${config.baseUrl}staking`} ><i class="fa fa-object-ungroup"></i>Staking</Link></li>
                  <li><Link to={`${config.baseUrl}withdraw`} ><i class="fal fa-money"></i>Withdraw</Link></li>
                 
                  <li><Link to={`${config.baseUrl}home`} ><i class="fal fa-home-alt"></i>Home</Link></li>
      


                 

                  {/* <div className="header-right">
                     <div className="device-btn cursorshow"><a>Buy Now</a></div>
                  </div> */}
               </ul>
                           <div class="fat-nav__wrapper" id="fatmenu">
                         

                              <ul className="header-menu" id="one" >
                                 <div className="col-md-3 search-box-input"  ></div>
                                 {console.log(this.loginData.length)}
                                 {this.loginData.length === 0 ?
                                    <>
                                       {/* <li className="login"><a href={`${config.baseUrl}login`} >Home</a></li> 
                              <li className="login"><a href={`${config.baseUrl}about`} >About</a></li> */}
                                    </>
                                    :
                                    <>
                                       <div className="be-drop-down login-user-down" onClick={this.handleChange2.bind(this, this.state.formStep)}>
                                       {this.state.profileData.profile_pic === '' || this.state.profileData.profile_pic === null ?
                                             <img className="login-user" style={{ height: '40px', width: '40px' }} src="images/noimage.png" alt="" /> : <img className="image-auth login-user" style={{ height: '40px', width: '40px' }} src={`${config.imageUrl1}${this.state.profileData.profile_pic}`} alt="" />}
                                          <span className="be-dropdown-content" style={{ fontWeight: "600" }}> &nbsp; Hi, <span>{this.state.profileData.user_name}</span></span>&nbsp;&nbsp;
                                          
                                          {console.log(this.state.formStep)}
                                          <div className="a-list" data-toggle="dropdown" style={{ display: this.state.formStep === 0 ? 'none' : 'block ' }} >
                                             <Link to={`${config.baseUrl}dashboard`} >Dashboard</Link>
                                             {/* <Link to={`${config.baseUrl}buynow`} >Buy Token</Link> */}
                                             {/* <Link>Buy Token</Link> */}
                                             <Link to={`${config.baseUrl}`} onClick={this.logout.bind(this)}>Logout</Link>
                                          </div>
                                       </div>
                                    </>}
                              </ul>

                           </div>
                        </div>

                        <ul className="header-menu" id="one" >
                           <div className="col-md-3 search-box-input"  ></div>
                           {this.loginData.length === 0 ?
                              <>z

                              </>
                              :
                              <>
                                 <div className="login-header-block user-dropprofile" onClick={this.headerShow.bind(this, this.state.headerShowData === '' ? '1' : '0')}>
                                    <div className="login_block">

                                       <div className="be-drop-down login-user-down" onClick={this.handleChange2.bind(this, this.state.formStep)}>
                                       {this.state.profileData.profile_pic === '' || this.state.profileData.profile_pic === null ?
                                             <img className="login-user" style={{ height: '40px', width: '40px' }} src="images/noimage.png" alt="" /> : <img className="image-auth login-user" style={{ height: '40px', width: '40px' }} src={`${config.imageUrl1}${this.state.profileData.profile_pic}`} alt="" />}
                                          <span className="be-dropdown-content" style={{ fontWeight: "600" }}> &nbsp; Hi, <span>{this.state.profileData.user_name}</span></span>&nbsp;&nbsp;
                                         
                                          <div className="drop-down-list a-list" data-toggle="dropdown" style={{ display: `${(this.state.formStep === 0) ? 'none' : 'block'}` }} >

                                             <Link to={`${config.baseUrl}dashboard`} >Dashboard</Link>
                                             {/* <Link to={`${config.baseUrl}buynow`} >Buy Token</Link> */}
                                             {/* <Link  >Buy Token</Link> */}
                                             <Link to={`${config.baseUrl}`} onClick={this.logout.bind(this)}>Logout</Link>
                                          </div>
                                       </div>
                                    </div>
                                 </div>

                              </>}

                        </ul>
                     </div>
                    </div>
                     

                  </div>
               </div>

            </header>

         </>
      )
   }
}