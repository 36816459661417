import React, { Component } from 'react';
import Header1 from '../directives/header1'
import axios from 'axios'
import config from '../config/config'
import Cookies from 'js-cookie';
import ReactDatatable from '@ashvin27/react-datatable'
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Link } from 'react-router-dom';
import copy from 'copy-to-clipboard';
import toast, { Toaster } from 'react-hot-toast';
import moment from 'moment'

export default class transaction extends Component {
    constructor(props) {
        super(props)
        this.loginData = (!Cookies.get('loginSuccess')) ? [] : JSON.parse(Cookies.get('loginSuccess'));

        const { match: { params } } = this.props;
        this.id = params.id

        this.state = {
            token_amount: '',
            usd_amount: '',
            staking_period_id: '0',
            tokenPriceInUSD: 0,
            userIsClaim: 0,
            tokenPrice: 0,
            spinLoader: '0',
            claimAmount: 0,
            min_stakingclaim_amount: 0,
            sumtokenamount: 0,
            getStakingList: [],
            totalReemed: 0,
            stakingReward:0,
            available:0,
            isClaim: 0,
            getStakingPeriod: [],
            isClaimBtn: 0
        };

        this.columns = [
            {
                key: "token",
                text: "Amount(YUSE)",
                cell: (item) => {
                    return (
                        <span>{parseFloat(item.token).toFixed(2)} YUSE</span>
                    );
                }
            },

            {
                key: "usd_amount",
                text: "Amount($)",
                cell: (item) => {
                    return (
                        <span>${parseFloat(item.usd_amount).toFixed(6)}</span>
                    );
                }
            },

            {
                key: "created_date",
                text: "Date",
                cell: (item) => {
                    return (
                        <span>{item.created_date}</span>
                    );
                }
            },

            // {
            //     key: "action",
            //     text: "Action",
            //     cell: (item) => {
            //         //  console.log(moment().format('YYYY-MM-DD'))
            //         //   console.log(moment(item.created_date).diff(moment().format('YYYY-MM-DD')))
            //         return (

            //             item.is_redeem == 0 && moment(item.created_date).diff(moment().format('YYYY-MM-DD')) == 0 ?
            //                 <button class="copy_id" title="copy" style={{ color: '#fff', backgroundColor: '#561484' }} onClick={e => this.claimHandler(e, item)}>Claim</button>
            //                 :item.is_redeem == 1? <label >Claimed</label>:''


            //         )
            //     }
            // },

        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            pagination: 'advance',
            button: {
                excel: false,
                print: false

            }
        }


    }
    componentDidMount() {
        this.getStakingListIncomeAPI();
        this.getTokenPriceAPI()
        this.getUserProfile()
        this.getStakingListAPI()
    }

    async getUserProfile() {
        await axios({
            method: 'post',
            url: `${config.apiUrl}getuserprofile`,
            data: { "email": this.loginData.data.user_email, "user_id": this.loginData.data?.id },
            headers: { "Authorization": this.loginData?.Token },
        }).then(response => {
            if (response.data.success === true) {
                this.setState({
                    userIsClaim: response.data.response.is_claim,
                })
            }
        })
    }
    async getTokenPriceAPI() {
        await axios({
            method: 'post',
            url: `${config.apiUrl}getfeeDetails`,
            data: { "email": this.loginData.data.user_email, "user_id": this.loginData.data?.id },
            headers: { "Authorization": this.loginData?.Token },
        }).then(response => {
            if (response.data.success === true) {
                this.setState({
                    tokenPrice: parseFloat(response.data.response.token_price),
                    min_stakingclaim_amount: response.data.response.min_stakingclaim_amount,
                    tokenPriceInUSD: parseFloat(1 / response.data.response.token_price)
                })

            }
        })
    }

    async claimHandler(e) {
        e.preventDefault()
        if (this.loginData.data.user_email != 'vijeta.espsofttech@gmail.com' && parseFloat(this.state.claimAmount * this.state.tokenPrice).toFixed(2) < this.state.min_stakingclaim_amount) {
            toast.error(`Minimum redeem amount ${this.state.min_stakingclaim_amount} USD`)
        } else {

            this.setState({
                isClaimBtn: 1
            })

            const token_amount = parseFloat(parseFloat(this.state.sumtokenamount / 2) - parseFloat(this.state.totalReemed)).toFixed(2)
            const usd_token_amount = token_amount * this.state.tokenPrice
            // console.log( { 'email': this.loginData.data.user_email, token_amount: parseFloat(token_amount), usd_amount:used_token_amount, "user_id": this.loginData.data.id, 'staking_id': this.id })
            await axios({
                method: 'post',
                url: `${config.apiUrl}updateStakingdailyIncome`,
                data: { 'email': this.loginData.data.user_email, token_amount: parseFloat(token_amount), usd_amount: usd_token_amount, "user_id": this.loginData.data.id, 'staking_id': this.id },
                headers: { "Authorization": this.loginData?.Token },
            }).then(response => {
                if (response.data.success === true) {
                    toast.success(response.data.msg)
                }
                this.componentDidMount()
            })
        }
        //     console.log({ 'email': this.loginData.data.user_email, usd_amount: amount, "user_id": this.loginData.data.id, 'staking_id': this.id })
    }

    async getStakingListAPI() {
        await axios({
            method: 'post',
            url: `${config.apiUrl}getStakingList`,
            data: { 'email': this.loginData.data.user_email, "user_id": this.loginData.data.id },
            headers: { "Authorization": this.loginData?.Token },
        }).then(response => {
            if (response.data.success === true) {
                const filters = response.data.response.filter(item => item.id == this.id)
                this.setState({
                    isClaim: filters[0].is_claim
                })

            }
        })
    }

    async getStakingListIncomeAPI() {
        await axios({
            method: 'post',
            url: `${config.apiUrl}getStakingIncomeList`,
            data: { 'email': this.loginData.data.user_email, "user_id": this.loginData.data.id, 'staking_id': this.id },
            headers: { "Authorization": this.loginData?.Token },
        }).then(response => {
            if (response.data.success === true) {
                let sum = 0
                let filter = response.data.response.filter(item => item.is_redeem == 0)

                for (let x in filter) {
                    sum += filter[x].token
                }
                var half_amount = parseFloat(sum).toFixed(2) / 2
                let amount = parseFloat(half_amount) - parseFloat(response.data.redeemed == null ? 0 : response.data.redeemed)
                this.setState({
                    getStakingList: response.data.response,
                    sumtokenamount: parseFloat(sum).toFixed(2),
                    totalReemed: response.data.redeemed == null ? 0 : response.data.redeemed,
                    available: response.data.available == null ? 0 : response.data.available,
                    stakingReward: response.data.stakingReward == null ? 0 : response.data.stakingReward,
                    beforeStakingAmount: response.data.redeemed == null ? 0 : response.data.redeemed,
                    claimAmount: amount
                })

            }
        })
    }
    render() {
        //  console.log(parseFloat(this.state.claimAmount * this.state.tokenPrice),this.state.min_stakingclaim_amount)
        //  console.log(parseFloat(this.state.isClaim)
        return (
            <>
                <Header1 />
                <Toaster
                    position="top-right"
                    reverseOrder={false} />
                <section className="page-login newdashboard" style={{ backgroundColor: "#e0e8f3" }}>
                    <div id="content-block">

                        <div className="row">
                            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                                <div className="header-menu-tabs">
                                    <a href="" className="aside_logo"><img className="logo-c active be_logo aside_logo" src="images/YUSE_Logo_purple.png" alt="logo" /></a>
                                    <aside className="web_view">
                                        <nav>
                                            <ul>
                                                <Link to={`${config.baseUrl}dashboard`}><li><i class="fa fa-th" aria-hidden="true"></i>Buy Token</li></Link>
                                                <Link to={`${config.baseUrl}transaction`}><li><i class="fal fa-money-bill-alt"></i>Transactions</li></Link>
                                                <Link to={`${config.baseUrl}authoredit`}><li><i class="fa fa-user-circle-o" aria-hidden="true"></i>My Profile</li></Link>
                                                <Link to={`${config.baseUrl}supportRequest`}><li className=""><i class="fal fa-user-headset"></i>Support</li></Link>
                                                <Link to={`${config.baseUrl}withdraw`}><li className=""><i className="fal fa-money"></i>Withdraw</li></Link>
                                                <Link to={`${config.baseUrl}staking`}><li className="active"><i className="fa fa-object-ungroup"></i>Staking</li></Link>
                                                <Link to={`${config.baseUrl}`}><li><i class="fal fa-home-alt"></i>Home</li></Link>
                                            </ul>
                                        </nav>
                                    </aside>
                                </div>
                            </div>
                            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12">
                                <div className="container-fluid">
                                    <div className="transaction_heading">
                                        <h6 className="heading_anchor">
                                            <a href={`${config.baseUrl}dashboard`}>Dashboard</a> <span style={{ color: '#707070' }}>/ Staking Income List</span>
                                        </h6>
                                    </div>
                                    <div className="token-transaction bg-white bg-shadow card-full-height" id="transaction_table">

                                        <div className="card-innr p-4">
                                            <div className="card-head has-aside pb-6">
                                                <h4 className="card-title" id='recomended1st'>Total staking reward: {parseFloat(this.state.stakingReward).toFixed(2)} YUSE </h4>
                                                <h4 className="card-title" id="recomended">Redeemed : {parseFloat(this.state.totalReemed).toFixed(2)} YUSE </h4>
                                                <h4 className="card-title" id="redemption">Available for redemption : {parseFloat(this.state.available).toFixed(2)}  YUSE </h4>
                                                {/* {parseFloat(parseFloat(this.state.sumtokenamount / 2) - parseFloat(this.state.totalReemed)).toFixed(2)}  */}
                                                {this.state.isClaimBtn == 0 ?
                                                    this.state.isClaim == 1 ? <button className='btn btn-primary' disabled={parseFloat(this.state.claimAmount * this.state.tokenPrice).toFixed(2) < this.state.min_stakingclaim_amount ? true : false} onClick={e => this.claimHandler(e)}>Claim</button> : ''
                                                    :
                                                    <button className='btn btn-primary' disabled="disabled">Claim</button>
                                                }
                                            </div>
                                            <span className='redumtion'>(Minimum redumption amount {this.state.min_stakingclaim_amount} USD)</span>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-12">
                                                    <div className="table-responsive">
                                                        <ReactDatatable
                                                            config={this.config}
                                                            records={this.state.getStakingList}
                                                            columns={this.columns}
                                                        />

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}