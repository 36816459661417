import React, { Component } from 'react';
import axios from 'axios';
import Header1 from '../directives/header1'
import config from '../config/config'
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import toast, { Toaster } from 'react-hot-toast';
import { Dialog, Classes } from "@blueprintjs/core";
import BarLoader from 'react-bar-loader'
import Cookies from 'js-cookie';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import copy from 'copy-to-clipboard';

const headers = {
    'Content-Type': 'application/json'
};
export default class withdraw extends Component {
    constructor(props) {
        super(props)
        this.loginData = (!Cookies.get('loginSuccess')) ? [] : JSON.parse(Cookies.get('loginSuccess'));
        this.state = {
            ConnectWalletAddress: '',
            amount: '',
            usd_amount: 0,
            bnb_amount: '0',
            token_amount: '0',
            release_token: 0,
            spinLoader: '0',
            dashboard_list: '',
            tokenPrice: '',
            coin_id: '',
            ip: '',
            authCode: '',
            SelectCoins: [{ 'id': 1, 'name': 'YUSE' }, { 'id': 2, 'name': 'BNB' }],
            bnbprice: '0',
            otpSendPopup: 0,
            otpCode: '',
            spinLoaderForOTP: 0,
            sendOTPBtn: 0
        };
    }
    componentDidMount() {
        // setTimeout(() => {
        //     if (window.ethereum) {
        //         const { ethereum } = window;
        //         this.setState({
        //             ConnectWalletAddress: ethereum.selectedAddress
        //         })
        //     }
        // }, 2000);

        this.getBNBToUsd();
        this.dashboardList()
        this.getTokenPriceAPI();
        this.getProfilePicAPI()
    }

    async getProfilePicAPI() {
        await axios({
            method: 'post',
            url: `${config.apiUrl}getuserprofile`,
            headers: { "Authorization": this.loginData?.Token },
            data: { "email": this.loginData.data.user_email, "user_id": this.loginData.data?.id },
        }).then(response => {
            if (response.data.success === true) {
                this.setState({
                    release_token: response.data.response.release_token
                })
            }
        })
    }

    async getBNBToUsd() {
        await axios({
            method: 'get',
            url: `https://api.binance.com/api/v3/ticker/price?symbol=BNBUSDT`,
        }).then(response => {
            console.log(response.data.price);
            this.setState({
                'BNBtoUsdPrice': response.data.price,
                bnbprice: 1 / response.data.price
            })
        })
    }

    finalSubmitWithdraw = async event => {
        event.preventDefault();
        this.setState({
            sendOTPBtn: '1'
        });

        const getIp = await axios.get('https://geolocation-db.com/json/')

        this.setState({
            ip: getIp.data.IPv4
        })

        var bnb_value = this.state.coin_id == '2' ? (this.state.usd_amount * this.state.bnbprice).toFixed(6) : 0
        var token_value = this.state.coin_id == '1' ? this.state.token_amount : 0
        var currency = this.state.coin_id == '1' ? 'YUSE' : 'BNB'
        console.log(this.loginData.data.user_email != 'vijeta.espsofttech@gmail.com' && this.state.usd_amount < 20)
        // if (!this.state.authCode) {
        //     this.setState({
        //         sendOTPBtn: '0'
        //     })
        //     toast.error('Authentication code required!!');
        //     return false;
        // }
        if (!this.state.otpCode) {
            this.setState({
                sendOTPBtn: '0'
            })
            toast.error('OTP code required!!');
            return false;
        }
        else if (this.loginData.data.user_email != 'vijeta.espsofttech@gmail.com' && this.state.usd_amount < 20 && currency == 'BNB') {
            toast.error('Minimum 20 USD amount is required for withdrawal!')
            this.setState({
                sendOTPBtn: '0'
            })
        } else if (parseFloat(token_value) < 200 && currency == 'YUSE') {
            toast.error('Minimum 200 YUSE token is required for withdrawal!')
            this.setState({
                sendOTPBtn: '0'
            })
        }
        else if (parseFloat(token_value) > this.state.release_token) {
            toast.error(`Maximum withdraw limit is only ${this.state.release_token} YUSE!`)
            this.setState({
                sendOTPBtn: '0'
            })
        } else if (this.state.dashboard_list?.bnb_balance < bnb_value && currency == 'BNB') {
            this.setState({
                sendOTPBtn: '0'
            })
            toast.error('You do not have sufficient balance!!');
            return false;
        } else if (this.state.release_token < token_value && currency == 'YUSE') {
            this.setState({
                sendOTPBtn: '0'
            })
            toast.error('You do not have sufficient balance!!');
            return false;
        }
        else {
            axios({
                method: 'post',
                url: `${config.apiUrl}/withdraw`,
                headers: { "Authorization": this.loginData?.Token },
                data: { 'usd_amount': this.state.usd_amount, 'bnb_value': bnb_value, currency: currency, tokenAmount: parseFloat(token_value), 'address': this.state.ConnectWalletAddress, user_id: this.loginData.data.id, 'email': this.loginData.data.user_email, 'ip': this.state.ip, 'otpCode': this.state.otpCode }
            }).then(result => {
                if (result.data.success === true) {
                    setTimeout(() => {
                        toast.success(result.data.msg, {
                            style: {
                                border: '1px solid #713200',
                                padding: '20px',
                                color: 'green',
                            },
                            iconTheme: {
                                primary: 'purple',
                                secondary: '#FFFAEE',
                            },
                        });
                    }, 1000);
                    setTimeout(() => {
                        window.location.href = `${config.baseUrl}transaction`
                    }, 5000);

                }
                else if (result.data.success === false) {
                    toast.error(result.data.msg,
                        {
                            style: {
                                border: '1px solid #713200',
                                padding: '20px',
                                color: 'red',
                            },
                            iconTheme: {
                                primary: 'purple',
                                secondary: '#FFFAEE',
                            },
                        });
                    this.setState({
                        sendOTPBtn: '0'
                    })
                }
            }).catch(err => {
                toast.error(err.response.data?.msg,
                    {
                        style: {
                            border: '1px solid #713200',
                            padding: '20px',
                            color: 'red',
                        },
                        iconTheme: {
                            primary: 'purple',
                            secondary: '#FFFAEE',
                        },
                    });
                this.setState({
                    sendOTPBtn: '0'
                })
            })
        }

    }


    handleSubmit = async event => {
        event.preventDefault();
        this.setState({
            spinLoader: '1'
        })

        var bnb_value = this.state.coin_id == '2' ? (this.state.usd_amount * this.state.bnbprice).toFixed(6) : 0
        var token_value = this.state.coin_id == '1' ? this.state.token_amount : 0
        var currency = this.state.coin_id == '1' ? 'YUSE' : 'BNB'
        console.log(this.loginData.data.user_email != 'vijeta.espsofttech@gmail.com' && this.state.usd_amount < 20)
        if (this.loginData.data.user_email != 'vijeta.espsofttech@gmail.com' && this.state.usd_amount < 20 && currency == 'BNB') {
            toast.error('Minimum 20 USD amount is required for withdrawal!')
            this.setState({
                spinLoader: '0'
            })
        } else if (parseFloat(token_value) < 200 && currency == 'YUSE') {
            toast.error('Minimum 200 YUSE token is required for withdrawal!')
            this.setState({
                spinLoader: '0'
            })
        }
        else if (parseFloat(token_value) > this.state.release_token) {
            toast.error(`Maximum withdraw limit is only ${this.state.release_token} YUSE!`)
            this.setState({
                spinLoader: '0'
            })
        } else if (this.state.dashboard_list?.bnb_balance < bnb_value && currency == 'BNB') {
            this.setState({
                spinLoader: '0'
            })
            toast.error('You do not have sufficient balance!!');
            return false;
        } else if (this.state.release_token < token_value && currency == 'YUSE') {
            this.setState({
                spinLoader: '0'
            })
            toast.error('You do not have sufficient balance!!');
            return false;
        }
        else {
            axios({
                method: 'post',
                url: `${config.apiUrl}/sendOTPForWithdraw`,
                headers: { "Authorization": this.loginData?.Token },
                data: { user_id: this.loginData.data.id, 'email': this.loginData.data.user_email, type: 2 }
            }).then(result => {
                if (result.data.success === true) {

                    toast.success(result.data.msg, {
                        style: {
                            border: '1px solid #713200',
                            padding: '20px',
                            color: 'green',
                        },
                        iconTheme: {
                            primary: 'purple',
                            secondary: '#FFFAEE',
                        },
                    });

                    setTimeout(() => {
                        this.setState({
                            otpSendPopup: 1,
                            spinLoader : '0'
                        })
                    }, 1000);
                }
                else if (result.data.success === false) {
                    toast.error(result.data?.msg,
                        {
                            style: {
                                border: '1px solid #713200',
                                padding: '20px',
                                color: 'red',
                            },
                            iconTheme: {
                                primary: 'purple',
                                secondary: '#FFFAEE',
                            },
                        });
                    this.setState({
                        otpSendPopup: 0
                    })
                }
            }).catch(err => {
                toast.error(err.response.data?.msg,
                    {
                        style: {
                            border: '1px solid #713200',
                            padding: '20px',
                            color: 'red',
                        },
                        iconTheme: {
                            primary: 'purple',
                            secondary: '#FFFAEE',
                        },
                    });
                this.setState({
                    otpSendPopup: 0
                })
            })
        }

    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    async connectMetasmask() {
        if (window.ethereum) {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            this.setState({
                ConnectWalletAddress: accounts[0]
            })
        }
        else {
            toast.error(`Please use dApp browser to connect wallet!`, {

            });
        }
    }

    onChange = e => {
        if (e.target.name == 'bnb_amount') {
            var usd_amount = (this.state.BNBtoUsdPrice * e.target.value).toFixed(6)
            this.setState({
                'usd_amount': usd_amount
            })
        }

        if (e.target.name == 'token_amount') {
            var yuse_value = (e.target.value * this.state.tokenPrice)
            console.log('yuse_value', yuse_value)
            this.setState({
                'usd_amount': yuse_value
            })
        }

        if (e.target.name == 'usd_amount' && this.state.coin_id == '2') {
            var bnb_amount = (this.state.bnbprice * e.target.value).toFixed(6)
            this.setState({
                'bnb_amount': bnb_amount
            })
        }

        if (e.target.name == 'usd_amount' && this.state.coin_id == '1') {
            var usd_amount = e.target.value
            var tokenAmount = usd_amount / this.state.tokenPrice
            this.setState({
                token_amount: parseInt(tokenAmount)
            })
        }

        this.setState({
            [e.target.name]: e.target.value
        })
    }

    async getTokenPriceAPI() {
        await axios({
            method: 'post',
            url: `${config.apiUrl}getfeeDetails`,
            data: { "email": this.loginData.data.user_email, "user_id": this.loginData.data?.id },
            headers: { "Authorization": this.loginData?.Token },
        }).then(response => {
            if (response.data.success === true) {
                this.setState({
                    tokenPrice: response.data.response.token_price,
                    tokenfee: response.data.response.token_fee,
                    min_token: response.data.response.min_token,
                    max_token: response.data.response.max_token
                })

            }
        })
    }

    async dashboardList() {
        await axios({
            method: 'post',
            url: `${config.apiUrl}getuserbalance`,
            headers: { "Authorization": this.loginData?.Token },
            data: { "user_id": this.loginData.data.id, 'email': this.loginData.data.user_email }
        })
            .then(result => {
                if (result.data.success === true) {
                    this.setState({
                        dashboard_list: result.data.response
                    })

                }

                else if (result.data.success === false) {

                }
            })

            .catch(err => {
            })
    }

    closeModel(){
        this.setState({
            otpSendPopup : 0
        })
    }

    render() {

        console.log(this.state.release_token)
        return (
            <>
                <Header1 />
                <Toaster
                    position="top-right"
                    reverseOrder={false} />

                <div className={this.state.otpSendPopup === 0 ? "modal fade" : "modal fade show"} id="putOnSale" style={{ display: this.state.otpSendPopup === 0 ? 'none' : 'block' }} >
                    <div className="modal-dialog send">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn-sm close" onClick={this.closeModel.bind(this)}>&times;</button>
                            </div>
                            <div className="modal-body">
                                <div className="card-innr">
                                    <div className="row">
                                        <div className="col-md-12 ">
                                            <div className="input-item input-with-label"><label for="full-name" className="input-item-label">OTP</label>
                                                <input className="form-control otp" onChange={this.onChange.bind(this)} autocomplete="off" name="otpCode" type="text" value={this.state.otpCode} /></div>
                                       

                                        <div className="gaps-1x"></div>

                                        <div className="col-sm-12 text-center">
                                        {this.state.sendOTPBtn == 0 ?
                                            <button onClick={this.finalSubmitWithdraw} className="btn-primary btn-lg mt-3" >Send</button>
                                            :
                                            <button className="btn-primary btn-lg mt-3" disabled>
                                                Loading<i className="fa fa-spinner fa-spin validat"></i>
                                            </button>
                                        }
                                        </div>
                                        <div className="gaps-2x d-sm-none"></div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="page-login personal-setting" style={{ backgroundColor: "#e0e8f3" }}>
                    <div className="row">
                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12">

                            <div className="header-menu-tabs">
                                <a href="" className="aside_logo"><img className="logo-c active be_logo aside_logo" src="images/YUSE_Logo_purple.png" alt="logo" /></a>
                                <aside className="web_view">
                                    <nav>
                                        <ul>
                                            <Link to={`${config.baseUrl}dashboard`}><li><i class="fa fa-th" aria-hidden="true"></i>Dashboard</li></Link>
                                            <Link to={`${config.baseUrl}transaction`}><li><i class="fal fa-money-bill-alt"></i>Transactions</li></Link>
                                            <Link to={`${config.baseUrl}authoredit`}><li><i class="fa fa-user-circle-o" aria-hidden="true"></i>My Profile</li></Link>
                                            <Link to={`${config.baseUrl}supportRequest`}><li className=""><i class="fal fa-user-headset"></i>Support</li></Link>
                                            <Link to={`${config.baseUrl}withdraw`}><li className="active"><i className="fal fa-money"></i>Withdraw</li></Link>
                                            <Link to={`${config.baseUrl}staking`}><li className={this.state.lastSegment == 'staking' ? 'active' : ''}><i className="fa fa-object-ungroup"></i>Staking</li></Link>
                                            <Link to={`${config.baseUrl}`}><li><i class="fal fa-home-alt"></i>Home</li></Link>
                                        </ul>
                                    </nav>
                                </aside>
                            </div>
                        </div>

                        <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12">
                            <div className="container-fluid">
                                <div className="transaction_heading">
                                    <h6 className="heading_anchor">
                                        <a href={`${config.baseUrl}dashboard`}>Dashboard</a> <span style={{ color: '#707070' }}>/ Withdraw</span>
                                    </h6>
                                </div>
                                <div className="content-area bg-white card bg-shadow">
                                    <div className="card-innr">
                                        <div className="tab-content" id="profile-details">
                                            <div className="tab-pane fade show active" id="personal-data2">
                                                <div className='row' style={{ minHeight: '300px' }}>

                                                    {/* <label style={{marginLeft:'200px',marginTop:'100px',color:'red'}}>Note - Withdrawal process is under maintenance and will be resume soon.</label> */}
                                                    <div className="tab-pane fade show active" id="personal-data2">
                                                        <div className="row">


                                                            <div className="col-md-6">
                                                                <div className="input-item input-with-label"><label for="full-name" className="input-item-label" style={{ width: '100%' }}>Select coin </label>
                                                                    <select onChange={this.handleChange} name="coin_id" className="form-control">
                                                                        <option value="">Select coin</option>
                                                                        {this.state.SelectCoins.map(item => {
                                                                            return (
                                                                                <option value={item.id}>{item.name}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <div className="input-item input-with-label"><label for="full-name" className="input-item-label" style={{ width: '100%' }}>Wallet address
                                                                    {this.state.coin_id == 1 ?
                                                                        <span className="pull-right" style={{ fontSize: '15px' }}>
                                                                            Available balance for withdrawal: {parseFloat(!this.state.release_token ? '0' : this.state.release_token)} YUSE
                                                                        </span> : <span className="pull-right" style={{ fontSize: '15px' }}>
                                                                            Available balance for withdrawal: {parseFloat(!this.state.dashboard_list.bnb_balance ? '0' : this.state.dashboard_list.bnb_balance)} BNB (${parseFloat(!this.state.dashboard_list.bnb_balance ? '0' : this.state.dashboard_list.bnb_balance * this.state.BNBtoUsdPrice).toFixed(2)})
                                                                        </span>}
                                                                </label>
                                                                    <input className="input-bordered" autocomplete="off" type="text" id="user-name" value={this.state.ConnectWalletAddress} /></div>
                                                            </div>

                                                            {this.state.coin_id == 1 ?
                                                                <div className="col-md-6">
                                                                    <div className="input-item input-with-label"><label for="full-name" className="input-item-label">Token amount</label>
                                                                        <input className="input-bordered" onKeyPress={(event) => { if (!/^\d*[.]?\d{0,1}$/.test(event.key)) { event.preventDefault(); } }} onChange={this.onChange.bind(this)} autocomplete="off" name="token_amount" type="text" value={this.state.token_amount} /></div>
                                                                </div>
                                                                : <div className="col-md-6">
                                                                    <div className="input-item input-with-label"><label for="full-name" className="input-item-label">BNB amount</label>
                                                                        <input className="input-bordered" onKeyPress={(event) => { if (!/^\d*[.]?\d{0,1}$/.test(event.key)) { event.preventDefault(); } }} onChange={this.onChange.bind(this)} autocomplete="off" name="bnb_amount" type="text" value={this.state.bnb_amount} /></div>
                                                                </div>}

                                                            <div className="col-md-6">
                                                                <div className="input-item input-with-label"><label for="full-name" className="input-item-label">USD amount</label>
                                                                    <input className="input-bordered" onKeyPress={(event) => { if (!/^\d*[.]?\d{0,1}$/.test(event.key)) { event.preventDefault(); } }} onChange={this.onChange.bind(this)} autocomplete="off" name="usd_amount" type="text" value={this.state.usd_amount} /></div>
                                                            </div>

                                                            {/* <div className="col-md-6">
                                                                <div className="input-item input-with-label"><label for="full-name" className="input-item-label">Authentication Code(2FA)</label>
                                                                    <input className="input-bordered" onKeyPress={(event) => { if (!/^\d*[.]?\d{0,1}$/.test(event.key)) { event.preventDefault(); } }} onChange={this.onChange.bind(this)} autocomplete="off" name="authCode" type="text" value={this.state.authCode} /></div>
                                                            </div> */}


                                                        </div>
                                                        <div className="gaps-1x"></div>
                                                        <div className="d-sm-flex justify-content-between align-items-center">

                                                            {this.state.spinLoader === '0' ?
                                                                (this.state.ConnectWalletAddress) ?
                                                                    <button className="btn" disabled={!this.state.usd_amount || !this.state.coin_id} onClick={this.handleSubmit}>Withdraw</button>
                                                                    :
                                                                    <button onClick={this.connectMetasmask.bind(this)} className="btn" >Connect Wallet</button>

                                                                :
                                                                <button className="btn" disabled>
                                                                    Loading<i className="fa fa-spinner fa-spin validat"></i>
                                                                </button>
                                                            }
                                                            <div className="gaps-2x d-sm-none"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br /><br /><br /><br /><br />
                            </div>
                        </div>
                    </div>

                </section>
            </>
        )
    }
}