import React, { Component } from 'react';
import axios from 'axios';
import Header1 from '../directives/header1'
// import Footer from '../directives/footer'
import config from '../config/config'
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
// import { ToastContainer, toast } from 'react-toastify';
import toast, { Toaster } from 'react-hot-toast';
import Cookies from 'js-cookie';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import copy from 'copy-to-clipboard';

const headers = {
   'Content-Type': 'application/json'
};
export default class authoredit extends Component {
   constructor(props) {
      super(props)
      this.loginData = (!Cookies.get('loginSuccess')) ? [] : JSON.parse(Cookies.get('loginSuccess'));
      console.log(this.loginData);
      this.state = {
        name : '',
        email: '',
        subject: '',
        description: '',
    };
   }
   componentDidMount() {

   }

   handleSubmit = event => {
    event.preventDefault();
    this.setState({
        spinLoader: '1'
    })
    const { email, subject, description } = this.state;
    const data = this.state

    axios.post(`${config.apiUrl}sendSupportrequest`, data, {  email, subject, description })
        .then(result => {
            if (result.data.success === true) {
                    setTimeout(() => {
                        toast.success('Request send successful.', {
                            style: {
                                border: '1px solid #713200',
                                padding: '20px',
                                color: 'green',
                            },
                            iconTheme: {
                                primary: 'purple',
                                secondary: '#FFFAEE',
                            },
                        });                         
                    }, 1000);
                    setTimeout(() => {
                        window.location.href = `${config.baseUrl}supportRequest`
                    }, 1500);   

            }
            else if (result.data.success === false) {
                toast.error(result.data.msg,
                    {
                        style: {
                            border: '1px solid #713200',
                            padding: '20px',
                            color: 'red',
                        },
                        iconTheme: {
                            primary: 'purple',
                            secondary: '#FFFAEE',
                        },
                    });                
            }
        }).catch(err => {
            toast.error(err.response.data?.msg,
                {
                    style: {
                        border: '1px solid #713200',
                        padding: '20px',
                        color: 'red',
                    },
                    iconTheme: {
                        primary: 'purple',
                        secondary: '#FFFAEE',
                    },
                });
            this.setState({
                spinLoader: '0'
            })
        })
}

handleChange = e => {
    this.setState({
        [e.target.name]: e.target.value
    })
}

   render() {
      return (
         <>
            <Header1 />
            <Toaster
               position="top-right"
               reverseOrder={false} />
            <section className="page-login personal-setting" style={{ backgroundColor: "#e0e8f3" }}>
               <div className="row">
                  <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                     
                     <div className="header-menu-tabs">
                     <a href="" className="aside_logo"><img className="logo-c active be_logo aside_logo" src="images/YUSE_Logo_purple.png" alt="logo" /></a>
                        <aside className="web_view">
                           <nav>
                              <ul>
                                 <Link to={`${config.baseUrl}dashboard`}><li><i class="fa fa-th" aria-hidden="true"></i>Dashboard</li></Link>
                                 <Link to={`${config.baseUrl}transaction`}><li><i class="fal fa-money-bill-alt"></i>Transactions</li></Link>
                                 <Link to={`${config.baseUrl}authoredit`}><li><i class="fa fa-user-circle-o" aria-hidden="true"></i>My Profile</li></Link>
                                 <Link to={`${config.baseUrl}supportRequest`}><li className="active"><i class="fal fa-user-headset"></i>Support</li></Link>
                                 <Link to={`${config.baseUrl}withdraw`}><li ><i className="fal fa-money"></i>Withdraw</li></Link>
                                 <Link to={`${config.baseUrl}staking`}><li className={this.state.lastSegment == 'staking'? 'active' : '' }><i className="fa fa-object-ungroup"></i>Staking</li></Link>
                                 <Link to={`${config.baseUrl}`}><li><i class="fal fa-home-alt"></i>Home</li></Link>
                              </ul>
                           </nav>
                        </aside>
                     </div>
                  </div>
                  
                  <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12">
                  <div className="container-fluid">
                     <div className="transaction_heading">
                        <h6 className="heading_anchor">
                              <a href={`${config.baseUrl}dashboard`}>Dashboard</a> <span style={{ color: '#707070' }}>/ Support Request</span>
                           </h6>                        
                     </div>
                     <div className="content-area bg-white card bg-shadow">
                        <div className="card-innr">

                           <div className="tab-content" id="profile-details">

                              <div className="tab-pane fade show active" id="personal-data2">
                                 <form>
                                    <div className="row">

                                    {/* <div className="col-md-12">
                                          <div className="input-item input-with-label"><label for="full-name" className="input-item-label">Name</label>
                                             <input className="input-bordered" autocomplete="off" type="text" id="user-name" onChange={this.handleChange} value={this.state.name} name="name" /></div>
                                       </div> */}

                                       <div className="col-md-12">
                                          <div className="input-item input-with-label"><label for="full-name" className="input-item-label">Email</label>
                                             <input className="input-bordered" autocomplete="off" type="email" id="user-name" onChange={this.handleChange} value={this.state.email} name="email" /></div>
                                       </div>

                                       <div className="col-md-12">
                                          <div className="input-item input-with-label"><label for="full-name" className="input-item-label">Subject</label>
                                             <input className="input-bordered" autocomplete="off" type="text" id="user-name" name="subject" onChange={this.handleChange} value={this.state.subject}  /></div>
                                       </div>

                                       <div className="col-md-12">
                                          <div className="input-item input-with-label"><label for="full-name" className="input-item-label">Description</label>
                                             <textarea className="input-bordered" autocomplete="off" name="description" onChange={this.handleChange}>{this.state.description}</textarea></div>
                                       </div>                                                                              
                   
                                   
                                    </div>
                                    <div className="gaps-1x"></div>
                                    <div className="d-sm-flex justify-content-between align-items-center">
                                       <button className="btn " onClick={this.handleSubmit}>Send</button>
                                       <div className="gaps-2x d-sm-none"></div>
                                    </div>
                                 </form>
                              </div>


                    
                           </div>
                        </div>
         
                                       </div>

                  </div>
                  </div>
               </div>

            </section>
         </>
      )
   }
}