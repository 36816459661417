import React, { Component } from 'react';
import Header1 from '../directives/header1'
import axios from 'axios'
import config from '../config/config'
import Cookies from 'js-cookie';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import copy from 'copy-to-clipboard';
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from 'web3';
import Web3Modal from "web3modal";
import Swal from 'sweetalert2'
import Modal from 'react-modal';
import { BrowserView, MobileView } from 'react-device-detect'
import { Dropdown } from "react-bootstrap";
import Onboard from 'bnc-onboard'
const headers = {
    'Content-Type': 'application/json'
};

const INFURA_KEY = '27e484dcd9e3efcfd25a83a78777cdf1'

const providerOptions = {
    connector: async (ProviderPackage, options) => {
        const provider = new ProviderPackage(options);
        await provider.enable()
        return provider;
    }
};

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};


const provider = new WalletConnectProvider({
    rpc: {
        97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
        56: "https://bsc-dataseed.binance.org/",
        3: "https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
        1: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
        4: "https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"
    },
});

const web3Modal = new Web3Modal({
    //  network: "mainnet", // optional
    cacheProvider: true, // optional
    providerOptions // required
});

var web3 = ''

export default class dashboard extends Component {

    constructor(props) {
        super(props)
        this.state = {
            dashboard_list: '',
            user_name: '',
            getUserPurchaseData: [],
            getUserCoinData: [],
            buyBtnText: 'Buy',
            tokenPrice: '',
            token: '',
            ismodal: false,
            cryptoValue: 0,
            EthtoUsdPrice: 0,
            BNBtoUsdPrice: 0,
            YuseTokenEquilant: 0,
            selectCurrency: 'BNB',
            currencyName: 'ETH',
            ConnectWalletAddress: '',
            AdminWallet: '',
            ErrorMsg: '',
            walletType: '1',
            paymentType: 1
        }
        this.loginData = (!Cookies.get('loginSuccess')) ? [] : JSON.parse(Cookies.get('loginSuccess'));
        this.buy_now = this.buy_now.bind(this)
        this.onSelectCoinETH = this.onSelectCoinETH.bind(this)
        this.onSelectCoinBNB = this.onSelectCoinBNB.bind(this)
        // this.onSelectMetamaskWallet = this.onSelectMetamaskWallet.bind(this)
        // this.onSelectTrustWallet = this.onSelectTrustWallet.bind(this)
    }

    handleChange(e) {
        return function () {
            this.setState({
                startDate: e.target.value
            });
        }
    }

    componentDidMount() {
        if (!Cookies.get('loginSuccess')) {
            window.location.href = `${config.baseUrl}`
            return false;
        }

        setTimeout(() => {
            if (window.ethereum) {
                const { ethereum } = window;
                this.setState({
                    ConnectWalletAddress: ethereum.selectedAddress
                })
            }
        }, 1000);

        // window.ethereum.on('accountsChanged', function (accounts) {
        //     window.location.reload()
        // })

        // provider.on("accountsChanged", (accounts) => {
        //     console.log(accounts);

        // });

        // Subscribe to chainId change
        //   provider.on("chainChanged", (chainId) => {
        //       console.log('chainidchain',chainId)
        //      window.location.reload()
        //   })

        this.dashboardList();
        this.getProfilePicAPI();
        this.getTokenPriceAPI();
        this.getuserTransactionListAPI();
        this.getEthToUsd()
        this.getBNBToUsd()
        this.getAdminWalletDetails()
    }

    async getAdminWalletDetails() {
        await axios({
            method: 'post',
            url: `${config.apiUrl}/getWalletDetails`,
            headers: { "Authorization": this.loginData?.Token },
            data: { "email": this.loginData.data.user_email, "user_id": this.loginData.data?.id }
        }).then(response => {
            if (response.data.success === true) {
                this.setState({
                    AdminWallet: response.data?.response
                })

            }
        })
    }

    // onSelectMetamaskWallet = e => {
    //     e.preventDefault();
    //     this.setState({
    //         walletType: '1'
    //     })
    // }

    // onSelectTrustWallet = e => {
    //     e.preventDefault();
    //     this.setState({
    //         walletType: '2'
    //     })
    // }    

    onSelectCoinETH = e => {
        e.preventDefault();
        this.setState({
            selectCurrency: "ETH"
        })
    }

    onSelectCoinBNB = e => {
        e.preventDefault();
        this.setState({
            selectCurrency: "BNB"
        })
    }

    onChange = e => {
        this.setState({ ErrorMsg: '' })
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    copyToClipboardLink(id) {
        var url = `https://yusetoken.io/register/${id}`;
        // var url = `https://espsofttech.tech/yuse_user_testing/register/${id}`;
        copy(url);

        toast.success("Copied", {

        });
    }

    async dashboardList() {
        await axios({
            method: 'post',
            url: `${config.apiUrl}getuserbalance`,
            data: { "user_id": this.loginData.data.id, 'email': this.loginData.data.user_email },
            headers: { "Authorization": this.loginData?.Token }
        })
            .then(result => {
                // console.log(result.data);
                if (result.data.success === true) {
                    this.setState({
                        dashboard_list: result.data.response
                    })

                }

                else if (result.data.success === false) {

                }
            })

            .catch(err => {
            })
    }

    //=======================================  User details  =====================

    async getuserTransactionListAPI() {
        await axios({
            method: 'post',
            url: `${config.apiUrl}getUserTransactionDetail`,
            data: { "user_id": this.loginData.data.id, 'email': this.loginData.data.user_email },
            headers: { "Authorization": this.loginData?.Token }
        })
            .then(result => {
                if (result.data.success === true) {
                    this.setState({
                        getUserPurchaseData: result.data.response,
                    })
                }
                else if (result.data.success === false) {
                }
            }).catch(err => {
            });
    }

    async getTokenPriceAPI() {
        await axios({
            method: 'post',
            url: `${config.apiUrl}getuserbalance`,
            data: { 'email': this.loginData.data.user_email, user_id: this.loginData.data.id },
            headers: { "Authorization": this.loginData?.Token },
        }).then(response => {
            console.log(response)
            // alert("API "+ response.data.success);
            if (response.data.success === true) {
                this.setState({
                    tokenPrice: response.data.response.token_price,
                    tokenfee: response.data.response.token_fee,
                    min_token: response.data.response.min_token,
                    max_token: response.data.response.max_token
                })
            } else {
                Cookies.remove('loginSuccess');
                window.location.href = `${config.baseUrl}login`
            }
        }).catch(ee => {
            // alert("API Catch");

            Cookies.remove('loginSuccess');
            window.location.href = `${config.baseUrl}login`
        })
    }

    async getEthToUsd() {
        await axios({
            method: 'get',
            // url: `https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD&Apikey=a8353644909600f9c297c8fd38447a21d31bdf70b9b4f286d4b79accf97e4dda`,

            url: `https://api.binance.com/api/v3/ticker/price?symbol=ETHUSDT`,

        }).then(response => {
            // this.setState({
            //     'EthtoUsdPrice': response.data.USD
            // })

            this.setState({
                'EthtoUsdPrice': response.data.price,
                ethprice: 1 / response.data.price,
            })

            var etheq = this.state.dashboard_list.NumberOftoken * this.state.tokenPrice
            this.setState({
                totalTokenInUSD: etheq
            })
        })
    }

    async getBNBToUsd() {
        await axios({
            method: 'get',
            // url: `https://min-api.cryptocompare.com/data/price?fsym=BNB&tsyms=USD&Apikey=a8353644909600f9c297c8fd38447a21d31bdf70b9b4f286d4b79accf97e4dda`,

            url: `https://api.binance.com/api/v3/ticker/price?symbol=BNBUSDT`,

        }).then(response => {
            // this.setState({
            //     'BNBtoUsdPrice': response.data.USD
            // })   

            this.setState({
                'BNBtoUsdPrice': response.data.price,
                bnbprice: 1 / response.data.price
            })
        })
    }


    async getProfilePicAPI() {
        await axios({
            method: 'post',
            url: `${config.apiUrl}getuserprofile`,
            headers: { "Authorization": this.loginData?.Token },
            data: { "email": this.loginData.data.user_email, "user_id": this.loginData.data?.id }
        }).then(response => {
            if (response.data.success === true) {
                this.setState({
                    profileData: response.data.response
                })
            }
        })
    }


    async connectselfWallet(e) {
        // try {
        const walletnetworkId = this.state.selectCurrency === 'BNB' ? 56 : 1
        const rpcUrl = this.state.selectCurrency === 'BNB' ? "https://bsc-dataseed.binance.org/" : "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"


        var onboard = Onboard({
            dappId: "77b074a9-006c-4811-86b8-ad4090f3fcab",
            networkId: this.state.selectCurrency === 'BNB' ? 0x38 : 0x1,
            subscriptions: {
                wallet: wallet => {
                    if (wallet.name == undefined) {
                        return;
                    }
                    web3 = new Web3(wallet.provider)
                    console.log(wallet)

                    this.setState({ paymentType: wallet.name == 'MetaMask' ? 1 : wallet.name == 'Trust' ? 2 : 3 })
                    // alert(`${wallet.name} connected!`)
                }
            },


            walletSelect: {

                wallets: [
                    {
                        walletName: 'metamask'
                    },
                    {
                        walletName: 'trust'
                    },
                    {
                        walletName: "walletConnect",
                        // infuraKey: INFURA_KEY,
                        rpc: {
                            [walletnetworkId]: `${rpcUrl}`
                        }, // [Optional]
                        //   bridge: '<BRIDGE_URL>'

                    },

                ]

            }

        });
        // alert('4444')
        const check = await onboard.walletSelect();
        //  console.log('check',check)


        // console.log(web3.provider);
        if (!window.ethereum.providers) {
            var metamaskProvider = await window.ethereum;
        } else {
            var metamaskProvider = await window.ethereum.providers.find((provider) => provider.isMetaMask);
        }





        // alert(metamaskProvider)
        const accounts = await metamaskProvider.request({ method: 'eth_requestAccounts' });
        this.setState({
            ConnectWalletAddress: accounts[0]
        })
        localStorage.setItem('ConnectWalletAddress', accounts[0])

        // } catch (err) {

        // }


    }


    async connectMetasmask() {
        // var ETH_mainnet = 0x1;
        // var ETH_testnet = 0x3;

        // var bnb_mainnet = 0x38;
        // var bnb_testnet = 0x61;
        if (this.state.paymentType != 3) {
            if (window.ethereum) {
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                this.setState({
                    ConnectWalletAddress: accounts[0]
                })
            } else {
                try {
                    if (this.state.paymentType == 1) {
                        var onboard = Onboard({
                            dappId: "77b074a9-006c-4811-86b8-ad4090f3fcab",
                            networkId: this.state.selectCurrency === 'BNB' ? 0x38 : 0x1,
                            subscriptions: {
                                wallet: wallet => {
                                    web3 = new Web3(wallet.provider)
                                }
                            },


                            walletSelect: {
                                wallets: [
                                    {
                                        walletName: 'metamask'
                                    }


                                ]
                            }

                        });
                    } else if (this.state.paymentType == 2) {
                        var onboard = Onboard({
                            dappId: "77b074a9-006c-4811-86b8-ad4090f3fcab",
                            networkId: this.state.selectCurrency === 'BNB' ? 0x38 : 0x1,
                            subscriptions: {
                                wallet: wallet => {
                                    web3 = new Web3(wallet.provider)
                                }
                            },


                            walletSelect: {
                                wallets: [

                                    {
                                        walletName: 'trust'
                                    }

                                ]
                            }

                        });
                    }



                    await onboard.walletSelect();
                    if (!window.ethereum.providers) {
                        var metamaskProvider = await window.ethereum;
                    } else {
                        var metamaskProvider = await window.ethereum.providers.find((provider) => provider.isMetaMask);
                    }
                    const accounts = await metamaskProvider.request({ method: 'eth_requestAccounts' });
                    this.setState({
                        ConnectWalletAddress: accounts[0]
                    })
                    localStorage.setItem('ConnectWalletAddress', accounts[0])
                } catch (error) {
                    // toast.error(error)
                    console.log(error)
                }

            }
        } else if (this.state.paymentType == 3 && provider) {
            try {
                localStorage.clear('walletconnect')
                const web3 = new Web3(provider);

                await provider.enable();
                const accounts = await web3.eth.getAccounts();
                //  console.log(provider)
                this.setState({
                    ConnectWalletAddress: accounts[0]
                })

                localStorage.clear('walletconnect')
            } catch (error) {
                localStorage.clear('walletconnect')
                console.log('walletconnecterror', error)
                window.location.reload()
                // toast.error(`Something went wrong!`, {

                // });
            }

        }

    }


    async buy_now() {
        var yuseToken = this.state.token / this.state.tokenPrice
        if (parseFloat(yuseToken) < parseFloat(this.state.min_token)) {
            this.setState({
                ErrorMsg: `Enter Minimum ${this.state.min_token} Token`
            })
            return false;
        }
        // else
        if (parseFloat(yuseToken) > parseFloat(this.state.max_token)) {
            this.setState({
                ErrorMsg: `Enter Maximum ${this.state.max_token} Token`
            })
            return false;
        } else {
            const itemPrice = (parseFloat(this.state.token) + parseFloat(this.state.tokenfee * this.state.token) / 100).toFixed(2)
            const itemfee = (parseFloat(this.state.tokenfee * this.state.token) / 100).toFixed(2)
            const itemEqviAmount = this.state?.token
            this.setState({
                buyBtnText: 'Processing...'
            })

            console.log('itemPriceitemPrice', yuseToken, itemPrice);

            //  console.log(this.state.ConnectWalletAddress)
            if (this.state.ConnectWalletAddress != '' || this.state.ConnectWalletAddress != null) {
                const accounts = this.state.ConnectWalletAddress
                var web3 = '';
                if (this.state.paymentType == 3) {
                    web3 = new Web3(provider);
                    await provider.enable();
                } else {
                    web3 = new Web3(window.ethereum);
                }


                var currentNetwork = web3.currentProvider.chainId;
                //   console.log('currentNetwork', currentNetwork)
                // var ETH_mainnet = 0x1;||1
                // var ETH_testnet = 0x3;|| 3

                // var bnb_mainnet = 0x38; || 56
                // var bnb_testnet = 0x61; || 97

                try {
                    if (this.state.selectCurrency === 'BNB') {

                        if (currentNetwork != '0x38' || currentNetwork != 56) {
                            toast.error(`Please select BNB smartchain!!`, {

                            });
                            this.setState({
                                buyBtnText: 'buy'
                            })
                            return false;
                        }
                        var chainId = '0x38';
                    }

                    if (this.state.selectCurrency === 'ETH') {
                        if (currentNetwork != '0x1' || currentNetwork != 1) {
                            toast.error(`Please select ETH smartchain!!`, {

                            });
                            this.setState({
                                buyBtnText: 'buy'
                            })
                            return false;
                        }
                        var chainId = '0x1';
                    }


                    // let livePriceAPI = await axios({ url: 'https://espsofttech.tech:6002/api/usdprice' });
                    // var usdInEth = livePriceAPI.data.response.ETH

                    var usdInEth = this.state.ethprice;
                    var usdvalueETH = (parseFloat(usdInEth) * parseFloat(itemPrice)).toFixed(6);

                    // let livePriceAPI1 = await axios({ url: 'https://espsofttech.tech:6002/api/bnbprice' });
                    // var usdInBNB = livePriceAPI1.data.response.BNB
                    var usdInBNB = this.state.bnbprice

                    var usdvalueBNB = (parseFloat(usdInBNB) * parseFloat(itemPrice)).toFixed(6);
                    // try {

                    if (this.state.selectCurrency === 'BNB') {
                        var trx_amount = parseInt(usdvalueBNB * (10 ** 18));
                        var usdvalue = usdvalueBNB;
                    } else {
                        var trx_amount = parseInt(usdvalueETH * (10 ** 18));
                        var usdvalue = usdvalueETH;
                    }
                    //   var to_address = '0xB1b3B9104C0ee3Fc67626b50374509ad11dEd0FD';//admin address
                    var to_address = this.state.AdminWallet?.public_key
                    var from_address = accounts;

                    var getBalace = await web3.eth.getBalance(from_address) / (10 ** 18);

                    //   console.log('getBalace', getBalace)
                    var currentBal = parseFloat(getBalace).toFixed(6)

                    if (currentBal < usdvalueBNB && this.state.selectCurrency === 'BNB') {
                        toast.error(`Insufficient fund for transfer`, {

                        });
                        this.setState({
                            buyBtnText: 'buy'
                        })
                        return false;
                    }

                    if (currentBal < usdvalueETH && this.state.selectCurrency === 'ETH') {
                        toast.error(`Insufficient fund for transfer`, {

                        });
                        this.setState({
                            buyBtnText: 'buy'
                        })
                        return false;
                    }

                    let gasPrice = await web3.eth.getGasPrice();
                    // console.log({
                    //     gasPrice: web3.utils.toHex(gasPrice),
                    //     to: to_address,
                    //     from: from_address,
                    //     value: web3.utils.toHex(trx_amount),
                    //     chainId: chainId,
                    // })

                    let gasLimit = await web3.eth.estimateGas({
                        gasPrice: web3.utils.toHex(gasPrice),
                        to: to_address,
                        from: from_address,
                        value: web3.utils.toHex(trx_amount),
                        chainId: chainId,
                    });

                    gasPrice = parseInt(gasPrice) + (5 * (10 ** 9));

                    const transactionParameters = {
                        gasPrice: web3.utils.toHex(gasPrice),
                        gas: web3.utils.toHex(gasLimit),
                        to: to_address,
                        from: from_address,
                        value: web3.utils.toHex(trx_amount),
                        chainId: chainId,
                    };

                    const txHash = await web3.currentProvider.request({
                        method: 'eth_sendTransaction',
                        params: [transactionParameters],
                    }).catch(err => {
                        if (err = 'Error: User rejected the transaction') {
                            toast.error(`Transaction rejected!`, {

                            });
                            this.setState({
                                buyBtnText: 'buy'
                            })
                            return false;

                        } else {
                            toast.error(`Transaction Denied!`, {

                            });
                            this.setState({
                                buyBtnText: 'buy'
                            })
                            return false;
                        }

                    });



                    // const txHash = await window.ethereum.request({
                    //     method: 'eth_sendTransaction',
                    //     params: [transactionParameters],
                    // });
                    //    console.log('txHash', txHash)

                    if (txHash) {

                        var arr = {
                            'user_id': this.loginData.data.id,
                            'from_address': from_address,
                            'to_address': to_address,
                            "amount": itemPrice,
                            "usd_amount": itemEqviAmount,
                            "fee": itemfee,
                            "amount_eth": usdvalue,
                            "token": this.state.token / this.state.tokenPrice,
                            "currency": this.state.selectCurrency,
                            "trx_currency": this.state.selectCurrency,
                            "hash": txHash,
                            "email": this.loginData.data.user_email,
                            //   "txid" : txid,
                            "transaction_type_id": 1,
                        }
                        //   console.log(arr);
                        this.paymentConfirmFromMetamask(arr);
                    }
                    //   this.buyItemAPI(item);

                } catch (error) {

                    toast.error(`Something went wrong! Please try again.`, {

                    });
                    this.setState({
                        buyBtnText: 'buy'
                    })
                    return false;
                }

            }
            else {
                toast.error(`Please connect your MetaMask wallet!`, {

                });
                this.setState({
                    buyBtnText: 'buy'
                })
                return false;
            }
        }
    }

    async paymentConfirmFromMetamask(arr) {

        await axios({
            method: 'post',
            url: `${config.apiUrl}paymentConfirmFromMetamask`,
            headers: { "Authorization": this.loginData?.Token },
            data: arr
        }).then(async response => {
            if (response.data.success === true) {
                this.setState({
                    buyBtnText: 'buy'
                })

                await Swal.fire({
                    title: 'Payment successful!',
                    text: 'Congratulations!! You have successfully done this transaction',
                    icon: 'success',
                    width: 500,
                    confirmButtonColor: '#3085d6',
                    allowOutsideClick: false,
                    confirmButtonText: 'View Transactions',
                });
                window.location.href = `${config.baseUrl}transaction`
            }
            if (response.data.success === false) {
                toast.error(response.data.msg, {

                });
            }
        }).catch(err => {
            toast.error(err.response.data.msg, {

            });
        })
    }

    changePaymentType(type) {
        this.setState({
            paymentType: type
        })
    }

    copyToClipboardAddress(e) {
        e.preventDefault()
        var url = this.state.ConnectWalletAddress;
        copy(url);

        toast.success("Copied", {

        });
    }


    DisconnectWallet(value) {

        console.log('value', value)
        if (value == 2) {
            this.setState({
                paymentType: 1,
                ConnectWalletAddress: ''
            })
            // socket.close();
            toast.success('Disconnected ')
            //   const  keys = Object.keys(localStorage)
            //   console.log(keys)
            //     for (var i = 0; i<localStorage.length; i++) {
            //       const key = localStorage.getItem(localStorage.key(i));
            //       console.log(key)
            //     }
            localStorage.setItem("e875b9e6d3c99201ded882cc1a83563885677393", '')
            localStorage.setItem('ConnectWalletAddress', '')

            // setTimeout(() => {
            //     window.location.href=`${config.baseUrl}dashboard`
            // }, 1000);
        } else {
            this.setState({ ismodal: true })
        }

    }


    closeModal(e) {
        this.setState({ ismodal: false })
    }


    connectModalWallet(number) {

        this.setState({ paymentType: number, ismodal: false })
        setTimeout(() => {
            this.connectMetasmask()
        }, 100);


    }

    render() {
        // alert(this.state.walletType)
        return (

            <>
                <Header1 />
                <Toaster />
                <section className="page-login newdashboard" style={{ backgroundColor: "#fff" }}>
                    <div id="content-block">
                        <div className="container-fluids">
                            <div className="row">
                                <div className="col-xl-2 col-lg-2 col-md-4 col-sm-12">
                                    <div className="header-menu-tabs">
                                        <a href="" className="aside_logo"><img className="logo-c active be_logo aside_logo" src="images/YUSE_Logo_purple.png" alt="logo" /></a>
                                        <aside className="web_view">
                                            <nav>
                                                <ul>
                                                    <Link to={`${config.baseUrl}dashboard`}><li className="active"><i class="fa fa-th" aria-hidden="true"></i>Dashboard</li></Link>
                                                    <Link to={`${config.baseUrl}transaction`}><li><i class="fal fa-money-bill-alt"></i>Transactions</li></Link>
                                                    <Link to={`${config.baseUrl}authoredit`}><li ><i class="fa fa-user-circle-o" aria-hidden="true"></i>My Profile</li></Link>
                                                    <Link to={`${config.baseUrl}supportRequest`}><li><i class="fal fa-user-headset"></i>Support</li></Link>
                                                    <Link to={`${config.baseUrl}withdraw`}><li className=""><i className="fal fa-money"></i>Withdraw</li></Link>
                                                    <Link to={`${config.baseUrl}staking`}><li className={this.state.lastSegment == 'staking' ? 'active' : ''}><i className="fa fa-object-ungroup"></i>Staking</li></Link>
                                                    <Link to={`${config.baseUrl}`}><li><i class="fal fa-home-alt"></i>Home</li></Link>
                                                </ul>
                                            </nav>
                                        </aside>
                                    </div>
                                </div>

                                <div className="col-xl-10 col-lg-10 col-md-8 col-sm-12">
                                    <div className="container-fluids">
                                        <div className="container-fluid be-detail-container pt-3">
                                            <div className="dashboard_heading">
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-6 col-12'>
                                                        <h6 className="heading_anchor">
                                                            <a href={`${config.baseUrl}dashboard`}>Dashboard</a> <span style={{ color: '#707070' }}>/ Buy Tokens</span>
                                                        </h6>
                                                    </div>
                                                    <div className='col-lg-6 col-md-6 col-12'>

                                                        {(this.state.ConnectWalletAddress == '' || this.state.ConnectWalletAddress == 'null' || this.state.ConnectWalletAddress == null) ?
                                                            <button onClick={e => this.DisconnectWallet(1)} className="connect_wallet_btn btn btn-primary" id='connect_disconnet_button' >Connect Wallet</button>

                                                            :
                                                            <Dropdown style={{ float: 'right' }} onSelect={this.change}>
                                                                <Dropdown.Toggle className='btn btn-primary' id='connect_disconnet_button' variant="success">
                                                                    {this.state.ConnectWalletAddress.toString().substring(0, 5) + '...' + this.state.ConnectWalletAddress.toString().substr(this.state.ConnectWalletAddress.length - 5)}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <li className='dropdown_wallet'> {(this.state.ConnectWalletAddress == '' || this.state.ConnectWalletAddress == 'null' || this.state.ConnectWalletAddress == null) ?
                                                                        <a className='btn btn-primary' id="connect_btn_top" style={{ float: 'left' }} onClick={e => this.DisconnectWallet(1)}>Connect</a> : <a className='btn btn-primary' id="connect_btn_top" style={{ float: 'left' }} onClick={e => this.DisconnectWallet(2)}>Disconnect</a>}
                                                                    </li>
                                                                    <li >
                                                                        <a className='btn btn-primary' id="copy_wallet" onClick={e => this.copyToClipboardAddress(e)}>Copy Address<i className="fa fa-copy"></i></a>
                                                                    </li>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-xs-12 col-lg-4 ">
                                                    <div className="dashboard_buytoken_card">
                                                        <div className="token-statistics bg-shadow card-token height-auto" >
                                                            <div className="card-innr">
                                                                <h4 className="card-title">Token Balance
                                                                    <Link to={`${config.baseUrl}withdraw`}><button style={{
                                                                        background: '#4c1368',
                                                                        border: '#4c1368'
                                                                    }} className="btn-primary btn-lg pull-right">Withdraw</button></Link>
                                                                </h4>
                                                                <div className="token-balance token-balance-with-icon">

                                                                    <div className="token-balance-icon">
                                                                        <img src="images/Yuse-Coin.png" alt="logo" />
                                                                    </div>
                                                                    <div className="token-balance-text">

                                                                        <span className="lead">{parseFloat(!this.state.dashboard_list.NumberOftoken ? '0' : this.state.dashboard_list.NumberOftoken)}
                                                                            <span> YUSE</span>
                                                                        </span><br />
                                                                        <small>
                                                                            <span className="USD">{parseFloat(!this.state.dashboard_list.NumberOftoken ? '0' : this.state.dashboard_list.NumberOftoken * this.state.tokenPrice).toFixed(2)}
                                                                                <small> USD</small>
                                                                            </span>
                                                                        </small>
                                                                    </div>
                                                                </div>

                                                                <h4 className="card-title">BNB Balance
                                                                    <Link to={`${config.baseUrl}withdraw`}><button style={{
                                                                        background: '#4c1368',
                                                                        border: '#4c1368'
                                                                    }} className="btn-primary btn-lg pull-right">Withdraw</button></Link>
                                                                </h4>
                                                                <div className="token-balance token-balance-with-icon">

                                                                    <div className="token-balance-icon">
                                                                        <img src="images/Yuse-Coin.png" alt="logo" />
                                                                    </div>
                                                                    <div className="token-balance-text">

                                                                        <span className="leads">{parseFloat(!this.state.dashboard_list.bnb_balance ? '0' : this.state.dashboard_list.bnb_balance)}
                                                                            <small> BNB </small>
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <h4 className="card-title">Referral Earnings</h4>
                                                                <div className="token-balance token-balance-with-icon" id="referral_earn">

                                                                    <div className="token-balance-icon">
                                                                        <img src="images/Yuse-Coin.png" alt="logo" />
                                                                    </div>
                                                                    <div className="token-balance-text">

                                                                        <span className="lead">{parseFloat(!this.state.dashboard_list.referral_token ? '0' : this.state.dashboard_list.referral_token)}
                                                                            <span> YUSE</span>
                                                                        </span><br />
                                                                        <small>
                                                                            <span className="USD">{parseFloat(!this.state.dashboard_list.referral_token ? '0' : this.state.dashboard_list.referral_token * this.state.tokenPrice).toFixed(6)}
                                                                                <small> USD</small>
                                                                            </span>
                                                                        </small>
                                                                    </div>
                                                                </div>

                                                                <h4 className="referral_link card-title">
                                                                    Referral Link  </h4>
                                                                <div className="refarral_links">
                                                                    <span className="referral_link_span">
                                                                        {(!this.state.profileData?.referral_code) ? '' : 'https://yuse.../register/' + this.state.profileData?.referral_code} </span> &nbsp;

                                                                    <span className="copy_id" onClick={this.copyToClipboardLink.bind(this, this.state?.profileData?.referral_code)} title="copy">Copy</span>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className=" col-lg-4 col-xs-12">
                                                    <div className="dashboard_buytoken_card">
                                                        <div className="token-information bg-white bg-shadow card-full-height">
                                                            <h4 class="card-title">Buy Tokens</h4>
                                                            <div className="row no-gutters height-100">
                                                                <div className="col-md-12 col-12 text-center">

                                                                    <div className="row no-gutters height-100">
                                                                        <div className="col-md-12 col-12 text-center">
                                                                            <div className="input-group mb-3" id="but-token-input">
                                                                                <input type="text" onKeyPress={(event) => { if (!/^\d*[.]?\d{0,1}$/.test(event.key)) { event.preventDefault(); } }} name="token" onChange={this.onChange} value={this.state.token} class="form-control" placeholder="Enter Amount" autoComplete="off" aria-describedby="basic-addon2" />
                                                                                <span className="input-group-text" id="basic-addon2">USD</span>
                                                                                <p style={{ color: 'red' }}>{this.state.ErrorMsg}</p>
                                                                            </div>
                                                                            <p>Minimum {this.state.min_token} YUSE</p>
                                                                            <h5 className="usd_mini">You will get: <span className="yuse_amount">{!this.state.tokenPrice ? '0' : parseFloat(this.state.token / this.state.tokenPrice).toFixed(2)} YUSE</span></h5>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="token-information bg-white bg-shadow card-full-height">
                                                            <h2 class="Currency-title">Payment Currency</h2>
                                                            <div className="row no-gutters height-100">
                                                                <div className="col-md-12 col-12 text-center">
                                                                    <div className="row no-gutters height-100">
                                                                        <div className="col-md-6 col-12 text-center">
                                                                            <label className="containers purple-color" onClick={this.onSelectCoinBNB}>
                                                                                {((parseFloat(this.state.token === '' ? 0 : this.state.token) + parseFloat(this.state.tokenfee * this.state.token) / 100) * parseFloat(this.state.bnbprice)).toFixed(6)} BNB
                                                                                <input checked={(this.state.selectCurrency == 'BNB') ? "checked" : ""} type="radio" value="BNB" name="cryptoCurrencyType" />
                                                                                <span className="checkmark"></span>
                                                                            </label>

                                                                        </div>
                                                                        <div className="col-md-6 col-12">
                                                                            <label class="containers grey-color" onClick={this.onSelectCoinETH}>
                                                                                {((parseFloat(this.state.token === '' ? 0 : this.state.token) + parseFloat(this.state.tokenfee * this.state.token) / 100) * parseFloat(this.state.ethprice)).toFixed(6)} ETH
                                                                                <input checked={(this.state.selectCurrency == 'ETH') ? "checked" : ""} type="radio" value="ETH" name="cryptoCurrencyType" />
                                                                                <span class="checkmark"></span>
                                                                            </label>
                                                                        </div>
                                                                        <p className="fees_prices">Fees : ${!this.state.token ? '0.00' : (parseFloat(this.state.tokenfee * this.state.token) / 100).toFixed(2)}&nbsp;({parseFloat(this.state.tokenfee).toFixed(2)}%)</p>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="token-information bg-white bg-shadow card-full-height" id="payment_currency">
                                                            <h2 class="Currency-title">Payment Wallet</h2>
                                                            <div className="row no-gutters height-100">
                                                                <div className="col-md-12 col-12 text-center">

                                                                    <div className="row no-gutters height-100">
                                                                        <div className="col-md-6 col-12 text-center">
                                                                            <label class="containers grey-color">MetaMask
                                                                                <input type="radio" name="wallet_type" onChange={this.changePaymentType.bind(this, 1)} value="1" checked={this.state.paymentType == 1 ? 'checked' : ''} />
                                                                                <span class="checkmark"></span>
                                                                            </label>

                                                                        </div>
                                                                        <div className="col-md-6 col-12">
                                                                            <label className="containers purple-color">Trust Wallet
                                                                                <input type="radio" name="wallet_type" checked={this.state.paymentType == 2 ? 'checked' : ''} value="2" onChange={this.changePaymentType.bind(this, 2)} />
                                                                                <span className="checkmark"></span>
                                                                            </label>
                                                                        </div>

                                                                        <div className="col-md-6 col-12 text-center" >
                                                                            <label className="containers purple-color" id="wallet_connect">Wallet Connect
                                                                                <input type="radio" name="wallet_type" checked={this.state.paymentType == 3 ? 'checked' : ''} value="3" onChange={this.changePaymentType.bind(this, 3)} />
                                                                                <span className="checkmark"></span>
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-sm-12 text-center">
                                                                        {/* <div id="buy_connect">
                                                                            {(this.state.ConnectWalletAddress) ?
                                                                            <button onClick={this.buy_now.bind(this)}  className="connect_wallet_btn btn btn-primary"> {this.state.buyBtnText} </button>
                                                                            :
                                                                            <button onClick={this.connectMetasmask.bind(this)} className="connect_wallet_btn btn btn-primary">Connect Wallet</button>
                                                                        }
                                                                        </div> */}

                                                                        <div id="">
                                                                            <button data-toggle="modal" data-target="#inProgressPopup" className="btn buy-token-btn btn-primary">Buy Token </button>

                                                                        </div>

                                                                        </div>


                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="modal fade" id="inProgressPopup" role="dialog">
                                                    <div className="modal-dialog modal-dialog-centered">
                                                        <div className="modal-content">
                                                            <div className="pt-3 pr-4">
                                                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <h5>You can buy token  from pancakeswap !! </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className=" col-lg-4 col-xs-12">
                                                    <div className="dashboard_buytoken_card">
                                                        <div className="token-information bg-white bg-shadow card-full-height" id="buy-tokenss" >
                                                            <h4 class="card-title">How to buy Tokens?</h4>
                                                            <div className="row no-gutters height-100">
                                                                <div className="col-lg-12 col-12">
                                                                    <div className="Buy_Token">
                                                                        <ol>
                                                                            <li>Transfer sufficient BNB or ETH in Trust wallet or Metamask Wallet .</li>
                                                                            <li>If using Trust Wallet, click on the DAPP icon, enter www.yusetoken.io into the URL box and log into your account.</li>
                                                                            <li>Select Binance for BNB or Ethereum for ETH.</li>
                                                                            <li>Enter the desired quantity of YUSE Tokens you wish to purchase and click on 'Continue'.</li>
                                                                            <li>Confirm the transaction.</li>
                                                                        </ol>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <a href="images/YUSE_White_Paper.pdf" attributes-list download="optional-value" className="btn btn-primary">Download Whitepaper <em className="fa fa-download ml-3"></em></a>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row mt-5">
                                                <div className="col-xs-12 col-lg-12 ">
                                                    <div className="token-transaction bg-white bg-shadow card-full-height">
                                                        <div className="card-innr p-4">
                                                            <div className="card-head has-aside pb-4">
                                                                <h4 className="card-title">Transactions</h4>
                                                                <div className="card-opt">
                                                                    <a href={`${config.baseUrl}transaction`} className="link ucap">View ALL <em className="fa fa-angle-right ml-2" style={{ marginTop: "-5px!important" }}></em></a>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-12 col-md-12 col-12">
                                                                    <div className="table-responsives" style={{ overflowX: '0!important' }}>
                                                                        <table className="table tnx-table table-bordered table-striped table-responsive-md table-responsive-sm table-responsive-xl" >
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>S No</th>
                                                                                    <th className="">Date</th>
                                                                                    <th>Tokens Credited</th>
                                                                                    <th>Amount</th>
                                                                                    <th>Status</th>
                                                                                    <th>Transaction Type</th>

                                                                                    <th className="tnx-type">Reference<div className="tnx-type-text"></div></th>
                                                                                </tr>

                                                                            </thead>

                                                                            <tbody>
                                                                                {this.state.getUserPurchaseData.map((item, i) => (
                                                                                    i < 3 ?
                                                                                        <tr>
                                                                                            <td>
                                                                                                {(item.status === 0) ?
                                                                                                    <img src="assets/images/watch.png" width="34px" /> :
                                                                                                    item.status === 1 ? i + 1 : i + 1}
                                                                                            </td>
                                                                                            <td className="">
                                                                                                <span className="">{(item.datetime.replace('.000Z', '')).replace('T', ' ')}</span>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="" >
                                                                                                    <span className="" style={{ paddingLeft: "45px" }}>
                                                                                                        {item.token}</span>
                                                                                                </div>
                                                                                            </td>

                                                                                            <td>
                                                                                                <span>
                                                                                                    {item.token == 0 ?
                                                                                                        <>
                                                                                                            {parseFloat(item.amount_eth).toFixed(6) + ' ' + item.currency + " ("}
                                                                                                            <span className="lead">${!parseFloat(item.usd_amount).toFixed(2) || item.usd_amount === '' ? '0.00' : parseFloat(item.usd_amount).toFixed(2)})</span>
                                                                                                        </>
                                                                                                        :
                                                                                                        <>
                                                                                                            <span className="lead">${!parseFloat(item.usd_amount).toFixed(2) || item.usd_amount === '' ? '0.00' : parseFloat(item.usd_amount).toFixed(2)}</span>
                                                                                                        </>
                                                                                                    }

                                                                                                </span>
                                                                                            </td>
                                                                                            <td>
                                                                                                <span>
                                                                                                    completed
                                                                                                </span>
                                                                                            </td>
                                                                                            <td>
                                                                                                <span>
                                                                                                    {item.transaction_type}
                                                                                                </span>
                                                                                            </td>

                                                                                            <td className="tnx-type">
                                                                                                <span className=" " >

                                                                                                    {item.hash === '' || item.hash === null || item.hash === undefined
                                                                                                        ?
                                                                                                        <span>
                                                                                                            {(item?.receipt_url) ? item?.receipt_url.toString().substring(0, 6) + '..' + item?.receipt_url.toString().substr(item?.receipt_url.length - 6) : '-'}

                                                                                                        </span>
                                                                                                        :

                                                                                                        item.currency === 'ETH' ?
                                                                                                            <a href={`https://etherscan.io/tx/${item.hash}`} target="_blank">
                                                                                                                <span title={item.hash}>{item?.hash.toString().substring(0, 4) + '...' + item?.hash.toString().substr(item?.hash.length - 4)}
                                                                                                                    &nbsp; <i className="fa fa-external-link"></i>
                                                                                                                </span>
                                                                                                            </a>
                                                                                                            :
                                                                                                            <a href={`https://bscscan.com/tx/${item.hash}`} target="_blank">
                                                                                                                <span title={item.hash}>{item?.hash.toString().substring(0, 4) + '...' + item?.hash.toString().substr(item?.hash.length - 4)}
                                                                                                                    &nbsp; <i className="fa fa-external-link"></i>
                                                                                                                </span>
                                                                                                            </a>

                                                                                                    }
                                                                                                </span>

                                                                                            </td>
                                                                                        </tr>
                                                                                        : ""
                                                                                ))}

                                                                            </tbody>
                                                                            {/* <!-- tbody --> */}
                                                                        </table>
                                                                        {/* <!-- .table --> */}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>


                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <Modal
                    isOpen={this.state.ismodal}

                    onRequestClose={e => this.closeModal(e)}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div className="connect_wallet_btn">

                        <button className='btn wallet_cancel_btn' id="cross_btn_wallet" onClick={e => this.closeModal(e)}>X</button>
                        {/* <h3 classname='connect_heading'>Connect Wallet</h3> */}
                        <button className="btn color-3 Connect_wallet_btn" onClick={e => this.connectModalWallet(1)}><img src="https://cdn.iconscout.com/icon/free/png-256/metamask-2728406-2261817.png" className="wallet_icons" />Metamask</button>

                        <button className="btn color-3 Connect_wallet_btn " onClick={e => this.connectModalWallet(3)} ><img src="https://www.nuget.org/profiles/WalletConnect/avatar?imageSize=512" className="wallet_icons" />Wallet Connect</button>
                        <BrowserView className='w-100'>
                            <button className="btn color-3 Connect_wallet_btn " onClick={e => window.open('https://trustwallet.com/dapp')} ><img src="https://play-lh.googleusercontent.com/-3uTwEsZDk2NEgRblDEfIIY7T-xAZfJPN5JzVKz7s94Ds8KrKCrSVHvkEuneJlUBekc=s180-rw" className="wallet_icons" />Trust</button>
                        </BrowserView>
                        <MobileView>
                            <button className="btn color-3 Connect_wallet_btn " onClick={e => this.connectModalWallet(2)} ><img src="https://play-lh.googleusercontent.com/-3uTwEsZDk2NEgRblDEfIIY7T-xAZfJPN5JzVKz7s94Ds8KrKCrSVHvkEuneJlUBekc=s180-rw" className="wallet_icons" />Trust</button>

                        </MobileView>

                        {/* <button className="btn color-3 Connect_wallet_btn " onClick={e => binanceChainConnect()} ><img src="https://avatars.githubusercontent.com/u/45615063?s=200&v=4" className="wallet_icons" />Binance Chain</button> */}
                        {/* <button className="btn color-3 Connect_wallet_btn "><img src="img/trous.png" className="wallet_icons"/>Connect with Torus Wallet</button> */}
                    </div>
                </Modal>

            </>

        )

    }
}