import React, { Component } from 'react';
import axios from 'axios';
import Header from '../directives/header'
//  import Footer from '../directives/footer'
import config from '../config/config'
import { Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import 'react-toastify/dist/ReactToastify.css';
// import { ToastContainer, toast } from 'react-toastify';
import toast, { Toaster } from 'react-hot-toast';
import MetaTags from 'react-meta-tags';
const TEST_SITE_KEY = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"; // for local server
//  const TEST_SITE_KEY = "6Ld-Z4geAAAAAMzmOYfd2gJSAIQoO-G1VJzRAJxu"   // for https://espsofttech.co.in/ server 
// const TEST_SITE_KEY = "6LeSEzMcAAAAAENbE0S5XJQcSH7YT6ZIIdSEZl4h"   // for live server
const headers = {
  'Content-Type': 'application/json'
};

export default class register extends Component {

  constructor(props) {
    super(props)
    const { match: { params } } = this.props;
    this.token = params.token
    console.log(window.location.href.split('/'));
    const lastItem = window.location.href.split('/')
    this.state = {
      user_name: '',
      email: '',
      confirm_password: '',
      password: '',
      referral_code: lastItem[4],
      captcha_code: '',
      passwordIcon: '1',
      is_subscribed: '1',
      spinLoader: '0',
      country_id: '0',
      countryList: []
    };
  }

  handleChange1 = value => {
    console.log("Captcha value:", value);
    this.setState({
      captcha_code: '1'
    })
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  passwordEye(id) {

    if (id == 1) {
      id = 0
    }
    else if (id == 0) {
      id = 1
    }
    this.setState({
      passwordIcon: id
    })
  }

  componentDidMount() {
    if (this.token) {
      this.verifyAccountAPI()
    }
    this.getCountryListAPI()
  }

  async verifyAccountAPI() {
    axios.post(`${config.apiUrl}/verifyAccount/` + this.token, { headers })
      .then(result => {
        console.log(result.data);
        if (result.data.success === true) {
          toast.success(result.data.msg, {
            style: {
              border: '1px solid #713200',
              padding: '20px',
              color: 'green',
            },
            iconTheme: {
              primary: 'purple',
              secondary: '#FFFAEE',
            },
          })
          setTimeout(() => {

            window.location.href = `${config.baseUrl}login`

          }, 3500);
        }
        if (result.data.success === false) {
          toast.error(result.data.msg, {
            style: {
              border: '1px solid #713200',
              padding: '20px',
              color: 'red',
            },
            iconTheme: {
              primary: 'purple',
              secondary: '#FFFAEE',
            },
          });
        }
      })
      .catch(err => {
        // console.log(err);
        toast.error(err.response.data?.msg, {
          style: {
            border: '1px solid #713200',
            padding: '20px',
            color: 'red',
          },
          iconTheme: {
            primary: 'purple',
            secondary: '#FFFAEE',
          },
        })
      });
    setTimeout(() => {
      window.location.href = `${config.baseUrl}login`
    }, 3500);
  }

  async getCountryListAPI() {
    await axios({
      method: 'post',
      url: `${config.apiUrl}getcountries`
    }).then(response => {
      if (response.data.success === true) {
        this.setState({
          countryList: response.data.response
        })
      }
    })
  }

  handleSubmit = event => {
    event.preventDefault();

    this.setState({
      spinLoader: '1'
    })
    const { user_name, email, confirm_password, password, referral_code, country_id } = this.state;
    if (this.state.captcha_code === '') {
      toast.error('Please Select reCAPTCHA');
      this.setState({
        spinLoader: '0'
      })
    }
    else {
      axios.post(`${config.apiUrl}/register`, { user_name, email, confirm_password, password, "referrer": referral_code, country_id })
        .then(result => {
          if (result.data.success === true) {

            // toast.success(result.data.msg, {
            //   style: {
            //     border: '1px solid #713200',
            //     padding: '10px',
            //     color: 'green',
            //   },
            //   iconTheme: {
            //     primary: 'purple',
            //     secondary: '#FFFAEE',
            //   },
            // });

            setTimeout(() => {
              localStorage.setItem("newEmail", email);
              // window.location.reload()
              window.location.href = `${config.baseUrl}regthankyou`
            }, 1000);
          }
        }).catch(err => {
          // console.log(err);
          this.setState({
            spinLoader: '0'
          })
          toast.error(err.response.data?.msg, this.setState({
            spinLoader: '0'
          }), setTimeout(() => {

          }, 500));

        })
    }
  }

  clickChange(id) {
    if (id === 0) {
      this.setState({
        is_subscribed: '1'
      })
    }
    else if (id === 1) {
      this.setState({
        is_subscribed: '0'
      })
    }
  }

  render() {
    const { user_name, email, confirm_password, password, referral_code, country_id } = this.state;
    return (
      <>
        <div style={{ backgroundImage: "url('images/Background_img.png')" }}>
          <Header />
          <div id="content-block" style={{ backgroundImage: '', backgroundPosition: 'bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
            <div className="container-fluid ">
              <div className="container-fluid custom-container pad-spc mt-5 mb-0" >
                <div className="container">
                  <div className="row">
                    <div className="limiter">
                      <div className="container-login100">
                        <div className="">
                          <form className="login100-form validate-form">

                            <Toaster
                              position="top-center"
                              reverseOrder={false}
                            />
                            <MetaTags>
                              <meta name="keywords" content="yuse,yuse ico,yuse token,yuse crypto,yuse token price" />
                              <title>Register Now! | Yuse Token</title>
                              <meta name="description" content="Sign up yourself at Yuse by just filling out your details and create a new password. Visit Yuse Token and Register now!" />
                              <h1>Register for YUSE </h1>
                              <h2>Fill this form to create an account</h2>
                              <link rel='canonica' href='https://yusetoken.io/register' />

                            </MetaTags>
                            <div className="text-center mb-2">
                            </div>
                            <span className="login100-form-title p-b-20">
                              Register for YUSE <br />
                              <small style={{ fontSize: "17px" }}>Fill this form to create an account.</small>
                            </span>



                            <div className="wrap-input100 validate-input" data-validate="Username is required">
                              <input className={this.state.user_name ? "input100 has-val" : "input100"} type="text" name="user_name" onChange={this.handleChange} value={user_name} />
                              <span className="focus-input100"></span>
                              <span className="label-input100">Name</span>
                            </div>
                            {/* <div className="wrap-input100 validate-input" data-validate="Your Name is required">
            <input className={this.state.full_name ? "input100 has-val" : "input100"} type="text" name="full_name"  onChange={this.handleChange}  value={full_name}/>
            <span className="focus-input100"></span>
            <span className="label-input100">Last Name</span>
          </div> */}
                            <div className="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                              <input className={this.state.email ? "input100 has-val" : "input100"} type="email" name="email" onChange={this.handleChange} value={email} />
                              <span className="focus-input100"></span>
                              <span className="label-input100">Email</span>
                            </div>
                            <div className="wrap-input100 validate-input mb-3" data-validate="Password is required">
                              <input className={this.state.password ? "input100 has-val" : "input100"} type={this.state.passwordIcon == 1 ? 'password' : 'text'} id="password-field" name="password" onChange={this.handleChange} value={password} />
                              <span className="focus-input100"></span>
                              <span className="label-input100">Password</span>
                              {this.state.passwordIcon == 1 ?
                                <span toggle="#password-field" onClick={this.passwordEye.bind(this, '1')} className="fa fa-fw fa-eye field-icon toggle-password"></span>
                                : <span toggle="#password-field" onClick={this.passwordEye.bind(this, '0')} className="fa fa-fw fa-eye-slash field-icon toggle-password"></span>}
                            </div>
                            <div className="wrap-input100 validate-input mb-3" data-validate="Password is required">
                              <input className={this.state.password ? "input100 has-val" : "input100"} type={this.state.passwordIcon == 1 ? 'password' : 'text'} id="password-field" name="confirm_password" onChange={this.handleChange} value={confirm_password} />
                              <span className="focus-input100"></span>
                              <span className="label-input100">Confirm Password</span>
                              {this.state.passwordIcon == 1 ?
                                <span toggle="#password-field" onClick={this.passwordEye.bind(this, '1')} className="fa fa-fw fa-eye field-icon toggle-password"></span>
                                : <span toggle="#password-field" onClick={this.passwordEye.bind(this, '0')} className="fa fa-fw fa-eye-slash field-icon toggle-password"></span>}
                            </div>
                            <label className="mt-0">Password must be at least 8 characters and contain 1 special character or number.</label>

                            <div className="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                              <select className={this.state.country_id ? "input100 has-val" : "input100"} onChange={this.handleChange} name='country_id' >
                                <option value="0" style={{ background: '#431359' }} >Select Country</option>
                                {this.state.countryList.map((item) => (
                                  <option style={{ background: '#431359' }} value={item.id}>{item.name}</option>
                                ))}
                              </select>
                              <span className="focus-input100"></span>
                              <span className="label-input100">Country</span>
                            </div>

                            <div className="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                              <input className={this.state.referral_code ? "input100 has-val" : "input100"} type="email" name="referral_code" onChange={this.handleChange} value={referral_code} />
                              <span className="focus-input100"></span>
                              <span className="label-input100">Referral Code (Optional)</span>
                            </div>


                            <div>
                              <ReCAPTCHA
                                style={{ display: "inline-block" }}
                                theme="dark"
                                ref={this._reCaptchaRef}
                                sitekey={TEST_SITE_KEY}
                                onChange={this.handleChange1}
                                asyncScriptOnLoad={this.asyncScriptOnLoad}
                              />
                              {/* <a href="#" className="txt1"> </a> */}
                            </div>

                            <p className="text-white mb-3">By signing up, you agree to the <Link to={`${config.baseUrl}termsandcondition`} target="_blank">
                              Terms and Conditions</Link> and
                              <Link to={`${config.baseUrl}privacyandpolicy`} target="_blank">Privacy Policy</Link>.</p>
                            <div className="container-login100-form-btn">
                              {this.state.spinLoader === '0' ?
                                <button className="login100-form-btn" value="Sign up" onClick={this.handleSubmit}>Register</button> :
                                <button className="login100-form-btn" disabled>
                                  Loading<i className="fa fa-spinner fa-spin validat"></i>
                                </button>
                              }

                            </div>

                            <div className="flex-sb-m w-full  p-t-10 p-b-32">
                              <a href={`${config.baseUrl}generatepassword`} >

                              </a>
                              <div>
                                <div href="#" className="txt1 mt-2">
                                  Already have an account? <Link to={`${config.baseUrl}login`}>

                                    Login
                                  </Link>
                                </div>
                              </div>
                            </div>


                          </form>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
          {/* <Footer/> */}
        </div>
      </>
    )
  }
}