import React, { Component } from 'react';
import axios from 'axios';
import Header2 from '../directives/header2'
import Header from '../directives/header'
import Footer1 from '../directives/footer1'
import config from '../config/config'
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'js-cookie';
// import Countdown from 'react-countdown';
import Countdown from '../Countdown.js';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
 } from 'react-accessible-accordion';
 import 'react-accessible-accordion/dist/fancy-example.css';
 import MetaTags from 'react-meta-tags';
export default class faq extends Component {

    render() {
       
        return (
           <>
            <Header />
            <MetaTags>
                  <meta name="keywords" content="yuse,yuse ico,yuse token,yuse crypto,yuse token price" />
                  <title>FAQs - Yuse Token</title>
                  <meta name="description" content="Discover the frequently asked question on related to Yuse token. Explore the answer of your queries at Yuse Token. Visit now!" />
                  <h1>FREQUENTLY ASKED QUESTIONS</h1>
                  {/* <h2>About us</h2> */}
                  {/* <h2>Mission & Vission</h2> */}
                  <link rel='canonica' href='https://yusetoken.io/faq' />

               </MetaTags>
            <br/>
            <br/>
            <br/>
              <div className="faq pt-110 pb-110 mobile-pt-60 mobile-pb-20 overflow-hidden">
               <div className="container">
                  <div className="row">
                     <div className="col-12 big-title text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">

                        <h6 className="sub-title2 text-center">Frequently Asked Questions</h6>
                        <h1 className="text-white">FREQUENTLY ASKED QUESTIONS</h1>
                        {/* <p className="text-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the</p> */}
                     </div>
                  </div>
                  <br/>
                  <br/>
                  <br/>
                  <div className="row">
                     <div className="col-md-4 mb-3">
                        <ul className="nav nav-pills flex-column" id="myTab" role="tablist">
                           {/* <li className="">
                              <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">General</a>
                           </li> */}
                           {/* <li className="">
                              <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Pre-ICO & ICC</a>
                           </li>
                           <li className="">
                              <a className="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">ICO Tokens</a>
                           </li>
                           <li className="">
                              <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Client</a>
                           </li>
                           <li className="">
                              <a className="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Team</a>
                           </li> */}
                        </ul>
                     </div>

                     <div className="col-md-12">
                        <div className="tab-content" id="myTabContent">
                           <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                              <Accordion className='accordion shadow' allowZeroExpanded={true}>
                                 <AccordionItem className="card">
                                    <AccordionItemHeading >
                                       <AccordionItemButton className="card-header bg-pink shadow-sm border-0">
                                       1. What is YUSE?
                                       </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body p-5 collapse in show">
                                       <p className="font-weight-light m-0">
                                          YUSE is a multi-purpose community token backed by an immersive ecosystem to make lives easier. It is a single token for multiple products and apps; it becomes profitable to earn rewards by creating and consuming content, learning, playing games, and chatting with your loved ones.
                                       </p>
                                    </AccordionItemPanel>
                                 </AccordionItem>

                                 <AccordionItem className="card">
                                    <AccordionItemHeading >
                                       <AccordionItemButton className="card-header bg-pink shadow-sm border-0">
                                      2. How is YUSE different from other tokens?
                                       </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body p-5 collapse in show">
                                       <p className="font-weight-light m-0">
                                       $YUSE, unlike most cryptocurrencies today, is a community token that allows users to use multiple digital products and apps. Anyone, regardless of technical ability, can effectively use the YUSE ecosystem.
                                       </p>
                                    </AccordionItemPanel>
                                 </AccordionItem>


                                 <AccordionItem className="card">
                                    <AccordionItemHeading >
                                       <AccordionItemButton className="card-header bg-pink shadow-sm border-0">
                                      3. Can I earn through referrals? And how do I get my referral link?
                                       </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body p-5 collapse in show">
                                       <p className="font-weight-light m-0">
                                       You can earn a 10% referral bonus on every new Direct (referred) member who buys YUSE tokens during pre-sale.
                                      <br/>
                                      For referral link: As soon as you sign-up and login into the dashboard, you will find your referral link which can be copied and shared across any social media channels or email to your friends and family. 
                                      You can also find your referral in the Profile section in your YUSE Back office.
                                       </p>
                                    </AccordionItemPanel>
                                 </AccordionItem>



                                 <AccordionItem className="card">
                                    <AccordionItemHeading >
                                       <AccordionItemButton className="card-header bg-pink shadow-sm border-0">
                                     4. What is the highest amount I can invest during Pre-Sale?
                                       </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body p-5 collapse in show">
                                       <p className="font-weight-light m-0">
                                       The highest amount that one can invest during Pre-sale  is $50,000
                                      </p>
                                    </AccordionItemPanel>
                                 </AccordionItem>



                                 <AccordionItem className="card">
                                    <AccordionItemHeading >
                                       <AccordionItemButton className="card-header bg-pink shadow-sm border-0">
                                      5. What is the lowest amount I can invest during Pre-Sale?
                                       </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body p-5 collapse in show">
                                       <p className="font-weight-light m-0">
                                       The lowest amount that one can invest during Pre-sale is $10.
                                       Our Mission is to reduce the entry-level barrier for our community members. 
                                       </p>
                                    </AccordionItemPanel>
                                 </AccordionItem>
                              </Accordion>
                        </div>

                           {/* <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                              <Accordion className='accordion shadow' allowZeroExpanded={true}>
                                 <AccordionItem className="card">
                                    <AccordionItemHeading >
                                       <AccordionItemButton className="card-header bg-pink shadow-sm border-0">
                                         6. What services does Yuse Offer?
                                       </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body p-5 collapse in show">
                                       <p className="font-weight-light m-0">
                                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                                       </p>
                                    </AccordionItemPanel>
                                 </AccordionItem>

                                 <AccordionItem className="card">
                                    <AccordionItemHeading >
                                       <AccordionItemButton className="card-header bg-pink shadow-sm border-0">
                                          Why should i choose a Design studio like Yuse over full-service agency?
                                       </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body p-5 collapse in show">
                                       <p className="font-weight-light m-0">
                                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                                       </p>
                                    </AccordionItemPanel>
                                 </AccordionItem>


                                 <AccordionItem className="card">
                                    <AccordionItemHeading >
                                       <AccordionItemButton className="card-header bg-pink shadow-sm border-0">
                                          How does Yuse create website content without knowing our Business plan?
                                       </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body p-5 collapse in show">
                                       <p className="font-weight-light m-0">
                                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                                       </p>
                                    </AccordionItemPanel>
                                 </AccordionItem>



                                 <AccordionItem className="card">
                                    <AccordionItemHeading >
                                       <AccordionItemButton className="card-header bg-pink shadow-sm border-0">
                                          What will be delivered? And When?
                                       </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body p-5 collapse in show">
                                       <p className="font-weight-light m-0">
                                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                                       </p>
                                    </AccordionItemPanel>
                                 </AccordionItem>



                                 <AccordionItem className="card">
                                    <AccordionItemHeading >
                                       <AccordionItemButton className="card-header bg-pink shadow-sm border-0">
                                          What often will results be reported?
                                       </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body p-5 collapse in show">
                                       <p className="font-weight-light m-0">
                                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                                       </p>
                                    </AccordionItemPanel>
                                 </AccordionItem>
                             </Accordion>
                          </div> */}

                           {/* <div className="tab-pane fade " id="contact" role="tabpanel" aria-labelledby="contact-tab">
                              <Accordion className='accordion shadow' allowZeroExpanded={true}>
                                 <AccordionItem className="card">
                                    <AccordionItemHeading >
                                       <AccordionItemButton className="card-header bg-pink shadow-sm border-0">
                                         7. What services does Yuse Offer?
                                       </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body p-5 collapse in show">
                                       <p className="font-weight-light m-0">
                                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                                       </p>
                                    </AccordionItemPanel>
                                 </AccordionItem>

                                 <AccordionItem className="card">
                                    <AccordionItemHeading >
                                       <AccordionItemButton className="card-header bg-pink shadow-sm border-0">
                                          Why should i choose a Design studio like Yuse over full-service agency?
                                       </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body p-5 collapse in show">
                                       <p className="font-weight-light m-0">
                                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                                       </p>
                                    </AccordionItemPanel>
                                 </AccordionItem>


                                 <AccordionItem className="card">
                                    <AccordionItemHeading >
                                       <AccordionItemButton className="card-header bg-pink shadow-sm border-0">
                                          How does Yuse create website content without knowing our Business plan?
                                       </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body p-5 collapse in show">
                                       <p className="font-weight-light m-0">
                                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                                       </p>
                                    </AccordionItemPanel>
                                 </AccordionItem>



                                 <AccordionItem className="card">
                                    <AccordionItemHeading >
                                       <AccordionItemButton className="card-header bg-pink shadow-sm border-0">
                                          What will be delivered? And When?
                                       </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body p-5 collapse in show">
                                       <p className="font-weight-light m-0">
                                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                                       </p>
                                    </AccordionItemPanel>
                                 </AccordionItem>



                                 <AccordionItem className="card">
                                    <AccordionItemHeading >
                                       <AccordionItemButton className="card-header bg-pink shadow-sm border-0">
                                          What often will results be reported?
                                       </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body p-5 collapse in show">
                                       <p className="font-weight-light m-0">
                                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                                       </p>
                                    </AccordionItemPanel>
                                 </AccordionItem>
                             </Accordion>
                           </div> */}

                        </div>
                     </div>
                  </div>
               </div>
            </div> 
      <Footer1/>

           </>

        )
    }


}