import React, { Component } from 'react';
// import Header from '../directives/header'
// import Footer from '../directives/footer'
import config from '../config/config'
import Cookies from 'js-cookie'
import {Link} from 'react-router-dom'
import axios from 'axios';

export default class header2 extends Component {

    constructor(props) {
        super(props)
        this.state={
           profileData:'',
           searchData:'',
           searchDataList:[],
           headerShowData:'',
           talentStatusAPIData:'',
           nftIndex:'',
           talentIndex:''

  
        }
      //   this.loginData = (!Cookies.get('loginSuccess'))?[]:JSON.parse(Cookies.get('loginSuccess'))
      this.loginData = (!Cookies.get('loginSuccess')) ? [] : JSON.parse(Cookies.get('loginSuccess'));
      this.loginId = (!this.loginData.data) ? 0 : this.loginData.data.id;
        this.onChange = this.onChange.bind(this)
    }
    componentDidMount() {
       
      // this.getProfilePicAPI()
      // this.talentStatusAPI()
      // alert("Done = " + this.loginId)
      if(this.loginId==0){
         window.location.href=`${config.baseUrl}login` 
      }
          }

    onChange = e =>{
      this.setState({
          [e.target.name] : e.target.value
       })

       if(e.target.name === 'searchData'){
          this.allSearchAPI(e.target.value)
       }
   }

  


    logout(){
      Cookies.remove('loginSuccess')
      setTimeout(() => {
         window.location.href = `${config.baseUrl}`
      }, );
   }

   redir(){
      window.location.href = `${config.baseUrl}`

   }

   redirectTo(id)
   {
      console.log(id);
   }

   loading(id) {
      setTimeout(() => {
         
          window.location.reload(true)
      }, 500);
  }

  loadingGroup(id) {
      setTimeout(() => {
          window.location.href = `${config.baseUrl}itemdetails/${id}`
          window.location.reload(true)
      }, 500);
  }

  loadingAccount(){
     setTimeout(() => {
        window.location.reload()
     }, );
  }

  headerShow(id){
     console.log(id);
      if(id === '1'){
         this.setState({
            headerShowData:'1'
         })
      }
      else if(id === '0'){
         this.setState({
            headerShowData:''
         })
      }
  }


  

    render() {
        return (    

            <>
           <header className="header-container position-absolute">
        	<div className="container">
        		<div className="row header-area justify-content-between pt-50 pb-20">
    				<div className="logo">
                        <a href="index.html"><img src="assets/images/Final-Logo-1.png" alt="" className="brand-logo" /></a>
                    </div>
    				<div className="menu-area d-flex flex-wrap">
    					<nav className="main-menu">
    						<ul className="nav">
    							 <li className="current-menu-item menu-item-has-children"><a href={`${config.baseUrl}login`}>Login</a>
                                  
                                </li>
                               {/* <li><a href="#about-id">About</a></li>
                                <li><a href="#Community-id">Community</a></li>
                                <li><a href="#road-map">Road Map</a></li>
                                <li><a href="#team-id">Team</a></li>
                                <li><a href="#road-map">Login</a></li>
                                <li><a href="#road-map">Sign Up</a></li>
                                */}
                               
    						</ul>
    					</nav>
                        <div className="header-right">
                            <div className="device-btn"><a href="contact.html">Buy Now</a></div>
                        </div>
    				</div>
        		</div>
    		</div>
        </header>

            </>
        )
    }
}     