import React, { Component } from 'react';
import axios from 'axios';
import Header from '../directives/header'
import Footer1 from '../directives/footer1'
import config from '../config/config'
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';

import toast, { Toaster } from 'react-hot-toast';

const headers = {
    'Content-Type': 'application/json'
};

export default class support_request extends Component {

    constructor(props) {
        super(props)
        this.state = {
            email: '',
            password: "",
            passwordIcon: '1',
            spinLoader: '0'
        };
    }

    componentDidMount() {
    }

    handleSubmit = event => {
        event.preventDefault();
        this.setState({
            spinLoader: '1'
        })
        const { name, email, subject, description } = this.state;
        const data = this.state

        axios.post(`${config.apiUrl}sendSupportrequest`, data, { name, email, subject, description })
            .then(result => {
                if (result.data.success === true) {
                        setTimeout(() => {
                            toast.success('Request send successful.', {
                                style: {
                                    border: '1px solid #713200',
                                    padding: '20px',
                                    color: 'green',
                                },
                                iconTheme: {
                                    primary: 'purple',
                                    secondary: '#FFFAEE',
                                },
                            });                         
                        }, 1000);
                        setTimeout(() => {
                            window.location.href = `${config.baseUrl}support_request`
                        }, 1500);   

                }
                else if (result.data.success === false) {
                    this.setState({
                        email: '',
                        password: '',
                        spinLoader: '0'
                    })
                }
            }).catch(err => {
                toast.error(err.response.data?.msg,
                    {
                        style: {
                            border: '1px solid #713200',
                            padding: '20px',
                            color: 'red',
                        },
                        iconTheme: {
                            primary: 'purple',
                            secondary: '#FFFAEE',
                        },
                    });
                this.setState({
                    spinLoader: '0'
                })
            })
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }


    passwordEye(id) {

        if (id == 1) {
            id = 0
        }
        else if (id == 0) {
            id = 1
        }
        this.setState({
            passwordIcon: id
        })
    }

    render() {
        const { email, password } = this.state;
        return (

            <>
                <div style={{ backgroundImage: "url('images/Background_img.png')" }}>
                    <Header />
                    <Toaster
                        position="top-right"
                        reverseOrder={false} />
                    <div id="root" style={{ backgroundImage: 'url(assets/images/Intersection1.png)', backgroundPositionX: 'left' }}  ></div>
                    <div id="content-block" style={{ backgroundImage: '' }} >
                        <br /><br />

                        <div className="container-fluid">
                            <div className="container-fluid pad-spc mt-5 mb-0" >
                                <div className="container">
                                    <div className="row">
                                        <div className="limiter">
                                            <div className="container-login100" style={{ minHeight: "54vh" }}>
                                                <div className="">
                                                    <form className="login100-form validate-form" autoComplete="on" id="login_form">
                                                        {/*   <div className="text-center mb-2">
          <img src="images/logo-new.png" align="center" width="196px">
        </div>  */}
                                                        <span className="login100-form-title p-b-20" style={{ color: 'purple' }}> Support Request <br />
                                                        </span>

                                                     

                                                        <div className="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                                                            <input className={this.state.email ? "input100 no-autofill-bkg has-val" : "input100 no-autofill-bkg"} type="email" autoComplete="off" name="email" onChange={this.handleChange} value={email} />
                                                            <span className="focus-input100"></span>
                                                            <span className="label-input100">Email</span>
                                                        </div>

                                                        <div className="wrap-input100 validate-input" >
                                                            <input className={this.state.subject ? "input100 no-autofill-bkg has-val" : "input100 no-autofill-bkg"} type="text" autoComplete="off" name="subject" onChange={this.handleChange}  />
                                                            <span className="focus-input100"></span>
                                                            <span className="label-input100">Subject</span>
                                                        </div>  

                                                        <div className="wrap-input100 validate-input" >
                                                            <input className={this.state.description ? "input100 no-autofill-bkg has-val" : "input100 no-autofill-bkg"} type="text" autoComplete="off" name="description" onChange={this.handleChange}  />
                                                            <span className="focus-input100"></span>
                                                            <span className="label-input100">Description</span>
                                                        </div>                                                                                             

                                                        <div className="container-login100-form-btn">
                                                            {this.state.spinLoader === '0' ?
                                                                <button className="login100-form-btn" type="submit" onClick={this.handleSubmit} disabled={!this.state.email || !this.state.subject || !this.state.name === ''} >
                                                                    Send
                                                                </button> :
                                                                <button className="login100-form-btn" disabled>
                                                                    Loading<i className="fa fa-spinner fa-spin validat"></i>
                                                                </button>
                                                            }


                                                        </div>

                                                    </form>
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                </div>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}