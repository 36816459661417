import React, { Component } from 'react';
import axios from 'axios';
import Header from '../directives/header'
import Footer1 from '../directives/footer1'
import config from '../config/config'
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'js-cookie';
// import Countdown from 'react-countdown';
import Countdown from '../Countdown.js';

import {
   Accordion,
   AccordionItem,
   AccordionItemHeading,
   AccordionItemButton,
   AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

const headers = {
   'Content-Type': 'application/json'
};
export default class regthankyou extends Component {

   constructor(props) {
      super(props)
      this.state={
         email : '',
      }

   }

   async resendEmail(){
      var  email = localStorage.getItem("newEmail");
      axios.post(`${config.apiUrl}resendverifyaccount`, { email })
      .then(result => {
          if (result.data.success === true) {
              toast.success(result.data.msg, {
                  position: toast.POSITION.TOP_CENTER
              });
          }
          else if (result.data.success === false) {
              toast.error(result.data.msg, {
                  position: toast.POSITION.TOP_CENTER
              });
          }
      })

      .catch(err => {
          if (err.request) { } if (err.response) {
              toast.error(err.response.data.msg, {
                  position: toast.POSITION.TOP_CENTER
              });
          }
      });
      
   }

   render() {
      const currentDate = new Date();
      const currentDate1 = (new Date()).toLocaleDateString('en-US', { day: "numeric", month: "long", year: 'numeric' })
      const year = (currentDate.getMonth() === 11 && currentDate.getDate() > 23) ? currentDate.getFullYear() + 1 : currentDate.getFullYear();
      return (

         <>
           <div style={{ backgroundImage: "url('images/Background_img.png')", position: 'absolute', width: '100%' }}>
           <header className="header-container  position-relative">
            <div className="container">
               <div className="row header-area justify-content-between pt-50 pb-20">
                  <div className="logo">
                     <a href={`${config.baseUrl}`}><img src="assets/images/logo1.png" alt="" className="brand-logo" /></a>
                  </div>

       <div className="menu-area d-flex flex-wrap">
      <nav className="main-menu">
       {/* <ul className="nav">
       <li className="current-menu-item menu-item-has-children"><a href={`${config.baseUrl}`}>Home</a>
             </li>
                       </ul> */}
                     </nav>
                   
                  </div>


               </div>
            </div>
         </header>
            <ToastContainer />
            
            <section className="thankyoupage text-white" > 
              <div className="container">
                  <div className="row">
                      <div className="col-sm-3">

                      </div>
                      <div className="col-sm-6">
                      <div className="text-center">
          <p>To activate your account, click the email we've just sent you to their email address used for signup
 
          </p>
             
                          <br/>
                          <div className="col-sm-12">
                             <div className="row mb-4">
                                <div className="col-sm-6 text-right">
                                <div className="mb-3 didnt">Didn't get the email?</div>

                                </div>
                                <div className="col-sm-6 text-left">
                                <button className="login100-form-btn-thanku"  > &nbsp;<a onClick={this.resendEmail.bind(this)} target="_blank" >Resend Activation Email</a> </button>
                               </div>
                            </div>
                             <div className="row mb-4">
                                <div className="col-sm-12 text-center">
                                <div>Wrong email address? 
                                <a style={{color:"#e385da"}} href={`${config.baseUrl}register`}>  Change email</a></div>

                                </div>
                                <div className="col-sm-6 text-left">
                                {/* <button className="login100-form-btn-thanku"  > &nbsp;<a onClick={this.resendEmail.bind(this)} target="_blank" >Resend Activation Email</a> </button> */}

                                </div>

                             </div>

                          </div>
                         
                 
                          
                          <br/>
                         
                         <br/>
                          <div>Follow us on social media channels to stay updated</div>
                          
                          <br/>
                          <div className="social-menu">
                          <ul class="">
                              <li>
                                  <a href="https://twitter.com/YuseToken" target="_blank" class="btn-social btn-outline" title="Twitter"><i class="fa fa-twitter"></i></a>
                               </li>
                               <li>
                                   <a href="javascript:void(0);" target="_blank" class="btn-social btn-outline" title="linkedin"><i class="fa fa-linkedin"></i>
                                   </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/yusetoken/" target="_blank" class="btn-social btn-outline" title="instagram"><i class="fa fa-instagram"></i></a>
                                </li>
                                <li><a href="https://www.facebook.com/yusetoken/" target="_blank" class="btn-social btn-outline" title="facebook"><i class="fa fa-facebook"></i></a>
                                </li>
                                <li>
                                    <a href="https://t.me/joinchat/f4jxc9SAgbcxMTdl" target="_blank" class="btn-social btn-outline" title="telegram"><i class="fa fa-telegram"></i>
                                    </a>
                                </li>
                           </ul>
                          </div>
                  </div>

                      </div>
                      <div className="col-sm-3">

                      </div>

                  </div>
                  

              </div>
                
            </section>
            

           
            </div>
         </>

      )
   }
}