import React, { Component } from 'react';
import axios from 'axios';
import Header from '../directives/header'
import Footer1 from '../directives/footer1'
import config from '../config/config'
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'js-cookie';
// import Countdown from 'react-countdown';
import Countdown from '../Countdown.js';
import MetaTags from 'react-meta-tags';
import {
   Accordion,
   AccordionItem,
   AccordionItemHeading,
   AccordionItemButton,
   AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';


export default class about extends Component {

   constructor(props) {
      super(props)


   }

   render() {
      const currentDate = new Date();
      const currentDate1 = (new Date()).toLocaleDateString('en-US', { day: "numeric", month: "long", year: 'numeric' })
      const year = (currentDate.getMonth() === 11 && currentDate.getDate() > 23) ? currentDate.getFullYear() + 1 : currentDate.getFullYear();
      return (

         <>
            <div className="circle-bg">
               <Header activeTab="about" />
               <ToastContainer />
               <MetaTags>
                  <meta name="keywords" content="yuse,yuse ico,yuse token,yuse crypto,yuse token price" />
                  <title>About US - Yuse Token</title>
                  <meta name="description" content="YUSE is a multi-purpose community token which is single token for multiple products and apps backed by an immersive ecosystem to make lives easier. To know more about Yuse, visit now!" />
                  <h1>About US - Yuse</h1>
                  <h2>About us</h2>
                  <h2>Mission & Vission</h2>
                  <link rel='canonica' href='https://yusetoken.io/about' />

               </MetaTags>
               {/* <section className="pages_banner_area" style={{paddingTop:'0%'}}> 
                <img src="images/parallax-layer/banner-layer-3.png" alt="" className="layer layer_1" data-depth="0.10" style={{transform: "translate3d(12.4512px, -47.6487px, 0px)", transformtyle: "preserve-3d", backfaceVisibility: "hidden" ,position: "relative", display: "block", left:" 0px", top:" 0px" }} />   
                <div className="container">
                    <h2>About us</h2>
                    
                </div>
            </section> */}


               {/* <section>

               <div className="about-container ">
                  <div className="container">
                     <div className="row  justify-content-between">
                        
                        <div className="col-12 col-md-12 col-lg-12 ">

                        <p className="pb-4 text-white" >YUSE is looking to be the leading innovator in the block chain industry, powered by some of the most experienced players in the business. Our ecosystem consists of Multiple Digital Products which is back by a single multi-utility token YUSE. For us, quality in delivery is what matters and we make it an imperative point to deliver the best while being in step with the trends in the industry.</p>
                       <p className="text-white">YUSE is definitely the right choice for the community who appreciate the most efficient system to expand the scope of their interest.  Committed to brilliance, maintaining integrity and high performance, you can’t go wrong in the business with YUSE.</p>
                      </div>
                     </div>
                  </div>
               </div>
            </section> */}
               <div className="header-banner bg-theme-grad-s2 has-ovm">
                  <div className="nk-banner">
                     <div className="banner banner-page">
                        <div className="banner-wrap">
                           <div className="container">
                              <div className="row justify-content-center align-items-center">
                                 <div className="col-xl-6 col-lg-9">
                                    <div className="banner-caption cpn tc-light text-center">
                                       <div className="cpn-head"><h2 className="title ttu">About us</h2></div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  {/* .nk-banner */}
                  <div className="nk-ovm shape-z6-sm" />
               </div>
               <section className="about_banner">



                  <div className="col-12 ">
                     <div className=" mt-3 tab-card aboutus">
                        <div className="row">
                           <div className="col-sm-3">

                           </div>
                           <div className="col-sm-6">
                              <div className="card-header tab-card-header">
                                 <ul className="nav nav-tabs card-header-tabs" id="myTab" role="tablist">
                                    <li className="nav-item active">
                                       <a className="nav-link active" id="one-tab" data-toggle="tab" href="#one" role="tab" aria-controls="One" aria-selected="true">About Us</a>
                                    </li>
                                    <li className="nav-item">
                                       <a className="nav-link" id="two-tab" data-toggle="tab" href="#two" role="tab" aria-controls="Two" aria-selected="false">Mission & Vision</a>
                                    </li>
                                    {/* <li className="nav-item">
                                       <a className="nav-link" id="three-tab" data-toggle="tab" href="#three" role="tab" aria-controls="Three" aria-selected="false">Community</a>
                                    </li> */}
                                 </ul>
                              </div>

                           </div>

                           <div className="col-sm-3">

                           </div>


                        </div>


                        <div className="tab-content" id="myTabContent">
                           <div className="tab-pane fade show active p-3" id="one" role="tabpanel" aria-labelledby="one-tab">
                              <div className="container">
                                 <div className="row">
                                    <div className="col-sm-12">
                                       <h5 className="card-title text-center text-white">About US</h5>
                                       {/* <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                           <a href="#" className="btn btn-primary">Go somewhere</a>   */}
                                       <div className="row gutter-vr-15px justify-content-center">
                                          <div className="col-lg-6 col-mb-10">
                                             <div className="nk-block-text">
                                                <p className="text-white">YUSE is a multi-purpose community token ($YUSE) backed by an immersive ecosystem to make lives easier.  </p>
                                                <p className="text-white">It is a single token for multiple products and apps; it becomes profitable to earn rewards by creating and consuming content, playing games, learning and chatting with your loved ones.</p>

                                             </div></div>
                                          <div className="col-lg-6 col-mb-10"><div className="nk-block-text">
                                             <p className="text-white">YUSE aims to unify entertainment, gaming, communication, DeFi, cloud storage, travel, and a crypto-based university under a single ecosystem.  </p>

                                          </div>
                                          </div>
                                       </div>
                                       <br />
                                       <div className="row justify-content-center">
                                          <div className="col-lg-10 col-mb-10">
                                             <div className="payment-holder">
                                                <div className="nk-block-img">
                                                   <img src="images/map2.png" style={{ maxWidth: "100%" }} alt="map" />
                                                </div>
                                             </div>
                                          </div>
                                       </div>

                                    </div>

                                 </div>
                              </div>

                           </div>
                           <div className="tab-pane fade p-3" id="two" role="tabpanel" aria-labelledby="two-tab">
                              <div className="container">

                                 <h5 className="card-title text-center text-white">Mission and Vision</h5>
                                 <section className="section">
                                    <div className="container">
                                       <div className="nk-block nk-block-text-wrap">
                                          <div className="row align-items-center gutter-vr-30px justify-content-lg-between justify-content-center">
                                             <div className="col-lg-5 col-mb-10">
                                                <div className="nk-block-img gfx-re-mdl py-5 py-lg-0">
                                                   <img src="images/mission.png" style={{ maxWidth: "100%" }} alt="app" />
                                                </div>
                                             </div>
                                             <div className="col-lg-6 col-mb-10">
                                                <div className="nk-block-text">
                                                   <div className="pb-5">
                                                      <h3 className="title title-regular">Mission</h3>
                                                      <p className="lead lead-regular text-white">To provide an improved user experience, Accelerate digitization and adoption of blockchain technology for everyday life.</p>

                                                   </div></div></div></div>

                                       </div>
                                       {/* <!-- .block @e --> */}

                                    </div>
                                 </section>

                                 <section className="section pt-0">
                                    <div className="container">
                                       <div className="nk-block nk-block-text-wrap">
                                          <div className="row align-items-center gutter-vr-30px justify-content-lg-between justify-content-center">
                                             <div className="col-lg-5 col-mb-10 order-lg-last">
                                                <div className="nk-block-img gfx-re-lg">
                                                   <img src="images/vision.png" style={{ maxWidth: "100%" }} alt="app" />
                                                </div></div>
                                             <div className="col-lg-6 col-mb-10">
                                                <div className="nk-block-text">
                                                   <div className="pb-5"><h3 className="title title-regular">Vision</h3>
                                                      <p className="lead lead-regular text-white">To improve people's daily lives with its products under a single ecosystem backed by blockchain technology.</p>

                                                   </div></div></div></div>
                                       </div>

                                    </div>
                                 </section>

                              </div>
                           </div>

                           {/* <div className="tab-pane fade p-3" id="three" role="tabpanel" aria-labelledby="three-tab">
                              <div className="container">
                                 <div className="row">
                                    <div className="col-sm-12">
                                       <h5 className="card-title text-center">Community</h5>
                                       <p className="card-text text-white">Our ecosystem consists of Multiple Digital Products which is back by a single multi-utility token YUSE for our community. YUSE offers innovative product for the community and would play a pivotal role in empowering YOU. Lets together explore the unlimited possibility and create a better tomorrow for all of us. </p>

                                    </div>
                                    <div className="col-sm-6">
                                    <br />
                                       <div className="community-img">
                                          <img src="images/community.png" width="100%" />

                                       </div>

                                    </div>
                                    <div className="col-sm-6">
                                       <div className="">
                                          
                                          
                                          <br />
                                          <ul className=" community-list text-white ">
                                          <li> Communication</li>
                                          <li> Cloud Storage </li>
                                          <li> Social Entertainment </li>
                                          <li> Gaming </li>
                                          <li> Dapps</li>
                                          <li> Wallet </li>
                                          <li> Decentralized Payments </li>
                                          <li> Travel app </li>
                                          <li> NFT </li>
                                          <li> Defi (Staking)</li>
                                             
                                          </ul>
                                    
                                       </div>

                                    </div>

                                 </div>

                                 
                              </div>
                           </div> */}

                        </div>
                     </div>
                  </div>
               </section>






               <Footer1 />
            </div>
         </>

      )
   }
}