import React, { Component } from 'react';
// import './assets/vendor/fontawesome/fontawesome-all.min.css';
// import './assets/vendor/bootstrap/css/bootstrap.min.css';
// import './assets/css/jquery.fatNav.css';
// import './assets/vendor/animate/css/animate.css';
// import './assets/vendor/chart/css/chart.css';
// import './assets/css/main.css';
// import './assets/css/responsive.css';

// import Header from '../directives/header'
// import Footer from '../directives/footer'
import config from '../config/config'
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom'
import axios from 'axios';
import Favicon from 'react-favicon'
import toast, { Toaster } from 'react-hot-toast';
import $ from "jquery";
export default class header extends Component {

   constructor(props) {
      super(props)
      this.state = {
         profileData: '',
         searchData: '',
         searchDataList: [],
         headerShowData: '',
         talentStatusAPIData: '',
         nftIndex: '',
         talentIndex: '',
         navClassOPen: 0,
         classAppend: 0
      }
      console.log(window.location.href);
      //   console.log('last',window.location.href.substring(this.href.lastIndexOf('/') + 1));

      this.loginData = (!Cookies.get('loginSuccess')) ? [] : JSON.parse(Cookies.get('loginSuccess'))
      this.loginId = (!this.loginData.data) ? 0 : this.loginData.data.id;
      this.onChange = this.onChange.bind(this)
   }
   componentDidMount() {
      
      // this.getProfilePicAPI()
      // this.talentStatusAPI()
      $('body').css('background-image', 'url(assets/images/Background_img.png)');
      this.setState({
         activeTab:this.props.activeTab
      })
      
      var parts = window.location.href.split('/');
      var lastSegment = parts.pop() || parts.pop();  // handle potential trailing slash

      console.log('lastSegment', lastSegment);
      if (lastSegment === 'buynow') {
         this.setState({
            classAppend: 1
         })
      }
      else {

         this.setState({
            classAppend: 0
         })
      }
      let currentUrl = window.location.href;
      
      // const url = new URL(currentUrl);
      // const pathname = url.pathname; // contains "/register"
      // alert(pathname)

      window.scrollTo({ top: 0, behavior: 'smooth' });
   }

   onChange = e => {
      this.setState({
         [e.target.name]: e.target.value
      })

      if (e.target.name === 'searchData') {
         this.allSearchAPI(e.target.value)
      }
   }




   logout() {
      Cookies.remove('loginSuccess')
      setTimeout(() => {
         window.location.href = `${config.baseUrl}`
      });
   }

   redir() {
      window.location.href = `${config.baseUrl}`

   }

   redirectTo(id) {

   }

   loading(id) {
      setTimeout(() => {

         window.location.reload(true)
      }, 500);
   }

   loadingGroup(id) {
      setTimeout(() => {
         window.location.href = `${config.baseUrl}itemdetails/${id}`
         window.location.reload(true)
      }, 500);
   }

   loadingAccount() {
      setTimeout(() => {
         window.location.reload()
      });
   }

   headerShow(id) {

      if (id === '1') {
         this.setState({
            headerShowData: '1'
         })
      }
      else if (id === '0') {
         this.setState({
            headerShowData: ''
         })
      }
   }

   navOpen(id) {

      if (id === 1) {
         this.setState({
            navClassOPen: 0
         })
      }
      else if (id === 0) {
         this.setState({
            navClassOPen: 1
         })
      }
   }


   copyToClipboardLink(id) {

      toast("Pre-Sale will be start on 17th September at 12:00PM",
         {
            duration: 6000,

         });
   }

   downloadpdf(){
      window.open(`https://yusetoken.io/assets/images/YUSE_White_Paper.pdf`)
   }

   render() {
      return (

         <>
            <Favicon url='https://yusetoken.io/assets/images/YUSE_Logo_White.png' />

            {console.log('classAppendsss', this.state.classAppend)}
            <header className={this.state.classAppend === 0 ? "header-container  position-absolute" : "header-container buynow-header position-absolute"}>
               <div className="container">
                  <div className="row header-area justify-content-between pt-50 pb-20">
                     <div className="logo">
                        <a href={`${config.baseUrl}`}><img src="images/YUSE_Logo_White.png" alt="" className="brand-logo" /></a>
                     </div>
                     {Cookies.get('loginSuccess') ?
                        <div className="menu-area d-flex flex-wrap">
                           <nav className="main-menu">
                              <ul className="nav">
                                 <li className={`${(this.state.activeTab=='home')?'current-menu-item':''} menu-item-has-children`}><a href={`${config.baseUrl}`}>Home</a>
                                 </li>
                                 <li><Link to={`${config.baseUrl}about`}>About</Link></li>
                                 {/* <li><a href="#Community-id">Community</a></li> */}
                                 <li><a href="#team-id">Team</a></li>
                                 <li><Link to={`${config.baseUrl}dashboard`} >Dashboard</Link></li>
                                 <li><a href={`${config.baseUrl}`} onClick={this.logout.bind(this)} >Logout</a></li>
                              </ul>
                           </nav>
                           <div className="header-right">
                        <div className="device-btn"><a href={`${config.baseUrl}dashboard`}>Buy Now</a></div>
                     </div> 
                           {/* <div className="header-right">
                              <div className="device-btn cursorshow"   ><a >Buy Now</a></div>
                           </div> */}
                        </div>

                        :
                        <div className="menu-area d-flex flex-wrap">
                           <nav className="main-menu">
                              <ul className="nav">
                                 <li className={`${(this.state.activeTab=='home')?'current-menu-item':''} menu-item-has-children`}><a href={`${config.baseUrl}`}>Home</a>
                                 </li>
                                 <li className={`${(this.state.activeTab=='about')?'current-menu-item':''} menu-item-has-children`}><Link to={`${config.baseUrl}about`}>About</Link></li>
                                 {/* <li><a href="#Community-id">Community</a></li> */}
                                 <li><a href="#roadmap_06">Roadmap</a></li>
                                 <li><a href="#team-id">Team</a></li>
                                 <li><a onClick={e=>this.downloadpdf(e)} style={{width:'120px'}}>White Paper</a></li>

                                 <li className={`${(this.state.activeTab=='login')?'current-menu-item':''} menu-item-has-children`}>
                                    <a className="device-btn" href={`${config.baseUrl}login`}>Login</a>
                                 </li>

                                 {/* <li className="header-right"><a className="device-btn" href={`${config.baseUrl}register`}>Register</a>
                            </li> */}
                              </ul>
                           </nav>
                           {/* <div className="header-right">
                        <div className="device-btn"><a href={`${config.baseUrl}buynow`}>Buy Now</a></div>
                     </div> */}
                           <div className="header-right">
                              <div className="device-btn cursorshow"   ><a href={`${config.baseUrl}register`}>Register</a></div>
                           </div>
                        </div>
                     }

                  </div>
               </div>
            </header>

            <div className={this.state.navClassOPen === 0 ? "fat-nav" : "fat-nav active"} style={{ display: this.state.navClassOPen === 0 ? 'none' : 'block' }}></div>
            {/* <!-- start header-container --> */}

            {Cookies.get('loginSuccess') ?
               <div className={this.state.navClassOPen === 0 ? "fat-nav" : "fat-nav active"} style={{ display: this.state.navClassOPen === 0 ? 'none' : 'block' }}><div className="fat-nav__wrapper" id="fatmenu">
                  <ul>
                  <li className="current-menu-item menu-item-has-children">
                     <Link to={`${config.baseUrl}`}>Home</Link>
                  </li>
                  <li><Link to={`${config.baseUrl}about`}>About</Link></li>
                  {/* <li><a href="#Community-id">Community</a></li> */}
                  <li><a href="#team-id">Team</a></li>
                  <li><Link to={`${config.baseUrl}dashboard`} >Dashboard</Link></li>
                  <li><a href={`${config.baseUrl}`} onClick={this.logout.bind(this)} >Logout</a></li>


                  <div className="header-right">
                     <div className="device-btn"><a href={`${config.baseUrl}dashboard`}>Buy Now</a></div>
                     </div>

                  {/* <div className="header-right">
                     <div className="device-btn cursorshow"><a>Buy Now</a></div>
                  </div> */}
               </ul>
               </div>
               </div>
               :
               <div className={this.state.navClassOPen === 0 ? "fat-nav" : "fat-nav active"} style={{ display: this.state.navClassOPen === 0 ? 'none' : 'block' }}><div className="fat-nav__wrapper" id="fatmenu"><ul>
                  <li className="current-menu-item menu-item-has-children">
                     <Link to={`${config.baseUrl}`}>Home</Link>
                  </li>
                  <li><Link to={`${config.baseUrl}about`}>About</Link></li>
                  {/* <li><a href="#Community-id">Community</a></li> */}
                  <li><a href="#roadmap_06">Roadmap</a></li>
                  <li><a href="#team-id">Team</a></li>
                  <li><a onClick={e=>this.downloadpdf(e)} >White Paper</a></li>
                  <li className="header-right">
                     <a className="device-btn" href={`${config.baseUrl}login`}>Login</a>
                  </li>

                  {/* <li className="header-right"><a className="device-btn" href={`${config.baseUrl}register`}>Register</a>
                            </li> */}

                  {/* <div className="header-right">
                     <div className="device-btn"><a href={`${config.baseUrl}buynow`}>Buy Now</a></div>
                     </div> */}

                  <div className="header-right">
                     <div className="device-btn cursorshow"  ><a href={`${config.baseUrl}register`}>Register</a></div>
                  </div>
               </ul>
               </div>
               </div>}

            <small className={this.state.navClassOPen === 0 ? "hamburger" : "hamburger active"} onClick={this.navOpen.bind(this, this.state.navClassOPen)}>
               <div className="hamburger__icon"></div>
            </small>
         </>
      )
   }
}