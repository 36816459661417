import React, { Component } from 'react';
import axios from 'axios';
import Header2 from '../directives/header2'
import Header from '../directives/header'
import Footer1 from '../directives/footer1'
import config from '../config/config'
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'js-cookie';
// import Countdown from 'react-countdown';
import Countdown from '../Countdown.js';
import DonutChart from 'react-donut-chart';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactTooltip from 'react-tooltip';
import $ from 'jquery';
import {
   // Accordion,
   // AccordionItem,
   AccordionItemHeading,
   AccordionItemButton,
   AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

import { Accordion, AccordionItem } from 'react-light-accordion';
import 'react-light-accordion/demo/css/index.css';
import { Helmet } from "react-helmet";
import MetaTags from 'react-meta-tags';
import Highcharts from 'highcharts';
import variablePie from "highcharts/modules/variable-pie.js";
import { Button, ButtonToolbar, Modal } from 'react-bootstrap';
import Marquee from "react-fast-marquee";


// import exporting from "highcharts/modules/exporting.js";
// import exportdata from "highcharts/modules/export-data.js";
// import accessibility from "highcharts/modules/accessibility.js";




variablePie(Highcharts);
// exporting(Highcharts);
// exportdata(Highcharts);
// accessibility(Highcharts);

function chunkSize(dataNum, dataTotal) {
   return (dataNum / dataTotal) * 360;
}
function addChunk(chunkSize, donutElement, offset, chunkID, color) {
   $(donutElement).append("<div class='chunk " + chunkID + "'><span><\/span><\/div>");
   var offset = offset - 1;
   var sizeRotation = -179 + chunkSize;
   $("." + chunkID).css({
      transform: "rotate(" + offset + "deg) translate3d(0,0,0)",
   });
   $("." + chunkID + " span").css({
      transform: "rotate(" + sizeRotation + "deg) translate3d(0,0,0)",
      "background-color": color,
   });
}
function iterateChunks(chunkSize, donutElement, offset, dataCount, chunkCount, color) {
   var chunkID = "s" + dataCount + "-" + chunkCount;
   var maxSize = 179;
   if (chunkSize <= maxSize) {
      addChunk(chunkSize, donutElement, offset, chunkID, color);
   } else {
      addChunk(maxSize, donutElement, offset, chunkID, color);
      iterateChunks(chunkSize - maxSize, donutElement, offset + maxSize, dataCount, chunkCount + 1, color);
   }
}
function createDonut(dataElement, donutElement) {
   var listData = [];
   $(dataElement + " span").each(function () {
      listData.push(Number($(this).html()));
   });
   var listTotal = 0;
   for (var i = 0; i < listData.length; i++) {
      listTotal += listData[i];
   }
   var offset = 0;
   var color = ["#9200ff", "#12d2ff", "#fdd43c", "#f04feb", "#fc9338", "#fd5c3c", "#4748ff", "#8596ff"];
   for (var i = 0; i < listData.length; i++) {
      var size = chunkSize(listData[i], listTotal);
      iterateChunks(size, donutElement, offset, i, 0, color[i]);
      $(dataElement + " li:nth-child(" + (i + 1) + ")").css("border-color", color[i]);
      offset += size;
   }
}



export default class home2 extends Component {

   constructor(props) {
      super(props)
      this.state = {
         email: '',
         username: '',
         subject: '',
         comments: '',
         tokenDis: '',
         dashboard_list: '',
         tokenPrice: '',
         modalOpen: false,
         copied: false,
         tokenPricePancake: ''
         //   email1 : ''

      }
      this.state = { time: {}, seconds: 259200 };
      this.timer = 0;
      this.startTimer = this.startTimer.bind(this);
      this.countDown = this.countDown.bind(this);
      this.loginData = (!Cookies.get('loginSuccess')) ? [] : JSON.parse(Cookies.get('loginSuccess'));
      this.handleChange = this.handleChange.bind(this);
      this.next = this.next.bind(this);
      this.previous = this.previous.bind(this);
   }
   next() {
      this.slider.slickNext();
   }
   previous() {
      this.slider.slickPrev();
   }

   RoadeMapFunction(cls) {
      var popup = document.getElementById(cls);
      popup.classList.add("show1");
      document.getElementById("overlay").style.display = "block";
   }
   RoadeMapOff() {
      var popup = document.getElementById("Q1Popup");
      popup.classList.remove("show1");
      document.getElementById("overlay").style.display = "none";
      var popup = document.getElementById("Q2Popup");
      popup.classList.remove("show1");
      document.getElementById("overlay").style.display = "none";
      var popup = document.getElementById("Q3Popup");
      popup.classList.remove("show1");
      document.getElementById("overlay").style.display = "none";
      var popup = document.getElementById("Q4Popup");
      popup.classList.remove("show1");
      document.getElementById("overlay").style.display = "none";
      var popup = document.getElementById("Q5Popup");
      popup.classList.remove("show1");
      document.getElementById("overlay").style.display = "none";
      var popup = document.getElementById("Q6Popup");
      popup.classList.remove("show1");
      document.getElementById("overlay").style.display = "none";
   }

   handleSubmit = event => {
      event.preventDefault();

      const { email } = this.state;
      const data = this.state

      axios.post(`${config.apiUrl}addsubscriber`, data, { email })
         .then(result => {
            // alert(' ALL field');
            if (result.data.success === true) {
               toast.success('You Subscribe successfully!!', {
                  position: toast.POSITION.TOP_CENTER
               });
               window.location.href = `${config.baseUrl}`
            }
            else if (result.data.success === false) {
               this.setState({
                  email: '',
                  password: '',
                  spinLoader: '0'
               })
            }
         }).catch(err => {
            toast.error(err.response?.data?.msg, {
               position: toast.POSITION.TOP_CENTER
            })
            this.setState({
               spinLoader: '0'
            })
         })
   }
   handleSubmit1 = event => {
      event.preventDefault();
      const { email, username, subject, comments } = this.state;
      const data = this.state

      axios.post(`${config.apiUrl}insertcontact`, data, { username, email, subject, comments })
         .then(result => {
            // alert(' ALL field');
            if (result.data.success === true) {

               setTimeout(() => {
                  toast.success('Your request submitted successfully!! ', {
                     position: toast.POSITION.TOP_CENTER
                  });
               }, 2500);
               window.location.href = `${config.baseUrl}`
            }
            else if (result.data.success === false) {
               this.setState({
                  email: '',
                  password: '',
                  spinLoader: '0'
               })

            }
         }).catch(err => {
            toast.error(err.response?.data?.msg, {
               position: toast.POSITION.TOP_CENTER
            })
            this.setState({
               spinLoader: '0'
            })
         })
   }
   handleChange = e => {
      this.setState({
         [e.target.name]: e.target.value
      })
   }
   handleChange1 = e => {
      this.setState({
         [e.target.name]: e.target.value
      })
   }


   secondsToTime(secs) {
      let days = Math.floor(secs / (60 * 60 * 24));
      let divisor_for_hours = secs % (60 * 60 * 24);
      let hours = Math.floor(divisor_for_hours / (60 * 60));
      let divisor_for_minutes = secs % (60 * 60);
      let minutes = Math.floor(divisor_for_minutes / 60);
      let divisor_for_seconds = divisor_for_minutes % 60;
      let seconds = Math.ceil(divisor_for_seconds);
      let obj = {
         "d": days,
         "h": hours,
         "m": minutes,
         "s": seconds
      };
      return obj;
   }
   componentDidMount() {
      let timeLeftVar = this.secondsToTime(this.state.seconds);
      this.setState({ time: timeLeftVar });
      this.startTimer()
      this.getTokenPriceAPI();
      this.getTokenDistributionAPI();
      this.getTokenPricePancakeAPI()

      setTimeout(() => {
         createDonut(".donutTarget-second.legend", ".donutTarget-second.donut-second");
      }, 100);
      Highcharts.chart("container", {
         chart: {
            type: "variablepie",
         },
         title: {
            text: "",
         },
         plotOptions: {
            variablepie: {
               allowPointSelect: false,
               cursor: "pointer",
               dataLabels: {
                  enabled: false,
               },
            },
         },
         tooltip: {
            headerFormat: "",
            pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' + "Percentage (%): <b>{point.y}</b><br/>" + "Amount : <b>{point.z}</b><br/>",
         },
         series: [
            {
               minPointSize: 10,
               innerSize: "20%",
               zMin: 0,
               name: "countries",
               data: [
                  {
                     name: "Charity",
                     y: 2,
                     z: 22222222.22,
                  },
                  {
                     name: "Pre Sales",
                     y: 5,
                     z: 55555555.55,
                  },
                  {
                     name: "Collaborations",
                     y: 5,
                     z: 55555555.55,
                  },
                  {
                     name: "Liquidity Pool",
                     y: 10,
                     z: 111111111.1,
                  },
                  {
                     name: "Marketing & Development",
                     y: 14,
                     z: 155555555.54,
                  },
                  {
                     name: "Team & Foundation",
                     y: 19,
                     z: 211111111.09,
                  },
                  {
                     name: "Public Sales",
                     y: 20,
                     z: 222222222.2,
                  },
                  {
                     name: "Rewards",
                     y: 25,
                     z: 277777777.75,
                  },
               ],
               colors: ["#8596ff", "#9200ff", "#fd5c3c", "#fc9338", "#fdd43c", "#f04feb", "#12d2ff", "#4748ff"],
            },
         ],
      });
   }

   startTimer() {
      if (this.timer == 0 && this.state.seconds > 0) {
         this.timer = setInterval(this.countDown, 1000);
      }
   }

   countDown() {
      // Remove one second, set state so a re-render happens.
      let seconds = this.state.seconds - 1;
      this.setState({
         time: this.secondsToTime(seconds),
         seconds: seconds,
      });

      // Check if we're at zero.
      if (seconds == 0) {
         clearInterval(this.timer);
      }
   }


   async getTokenPriceAPI() {
      await axios({
         method: 'get',
         url: `${config.apiUrl}getfee`,

      }).then(response => {
         if (response.data.success === true) {
            this.setState({
               tokenPrice: response.data.response.token_price,
               tokenfee: response.data.response.token_fee
            })

         }
      })
   }

   async buyBtnContentShow() {
      $(".buyNowBtnTest").show();
   }

   async getTokenDistributionAPI() {
      await axios({
         method: 'get',
         url: `${config.apiUrl}getTokenDistributeData`,

      }).then(response => {
         if (response.data.success === true) {
            this.setState({
               tokenDis: response.data.response1
            })
         }
      })
   }

   async getTokenPricePancakeAPI() {
      await axios({
         method: 'post',
         url: `${config.apiUrl}getLivePrice`,
      }).then(response => {
         this.setState({
            tokenPricePancake: response.data.balance,
         })

      })
   }



   render() {
      const settings = {
         dots: true,
         infinite: true,
         speed: 500,
         slidesToShow: 1,
         slidesToScroll: 1
      };

      const currentDate = new Date();
      const currentDate1 = (new Date()).toLocaleDateString('en-US', { day: "numeric", month: "long", year: 'numeric' })
      const year = (currentDate.getMonth() === 11 && currentDate.getDate() > 23) ? currentDate.getFullYear() + 1 : currentDate.getFullYear();
      return (

         <>
            {/* <div style={{ backgroundImage: "url('images/Background_img.png')" }}> */}
            <div>


               <Header activeTab="home" />
               <ToastContainer />
               <ReactTooltip />
               {/* <Helmet>
                <meta charSet="utf-8" />
                <title>Yuse Token - Multi Utility Crypto Currency Token</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet> */}
               <MetaTags>
                  <meta name="keywords" content="yuse,yuse ico,yuse token,yuse crypto,yuse token price" />
                  <title>Yuse Token - Multi Utility Crypto Currency Token</title>
                  <meta name="description" content="Buy Yuse Crypto token which is one multipurpose token with different use cases. Visit and register yourself to buy Yuse token in Pre-sale now!" />
                  <h1>Introducing YUSE</h1>
                  <h2>The ultimate multi-utility token!</h2>
                  <h2>WE'RE LIVE!</h2>
                  <h2>YUSE, The ultimate multi-utility token!</h2>
                  <link rel='canonica' href='http://www.yusetoken.io/' />

               </MetaTags>
               <div id="overlay" onClick={this.RoadeMapOff.bind(this)}></div>
               {/* <section style={{ backgroundImage: "url('assets/images/Intersection1.png')", backgroundPositionX: "center" }}> */}

               <section style={{ backgroundImage: "", backgroundPositionX: "center" }}>
                  <div className="circle-flash-light">
                     <div className='banner-slider'>


                        <Slider
                        autoplay={true}
                        loop={false}
                        selected={0}
                        showArrows={true}
                        showNav={true}>
                        <div><img src="images/1 Yuse wallet.png" width="100%" /> </div>
                        <div><img src="images/2 Yuse NFT.png" width="100%" /> </div>
                        <div><img src="images/3 Yuse Funtasy.png" width="100%" /></div>
                        {/* <div><img src="images/4 Meta yuse.png" width="100%" /> </div>
                        <div><img src="images/5 Yuse Circle.png" width="100%" /> </div>
                        <div><img src="images/6 Yuse gaming.png" width="100%" /></div>
                        <div><img src="images/7 Yuse Blockchain.png" width="100%" /> </div>
                        <div><img src="images/8 Yuse Cloudstorage.png" width="100%" /></div>
                        <div><img src="images/9 Yuse Travel.png" width="100%" /></div>
                        <div><img src="images/10 Yuse Education.png" width="100%" /> </div> */}
                      
                     </Slider>
                        {/* <div className="banner-container ">
                           <div className="container">
                              <div className="row banner-content-area align-items-center justify-content-between">
                                 <div className='col-md-12 col-lg-12 col-12'>
                                    <Slider
                                autoplay={true}
                                 loop={ false }
                                    selected={ 0 }
                                    showArrows={ true }
                                    showNav={ true }>
                                 <div><img src="images/Slider1.png" width="100%"/> </div>
                                 <div><img src="images/Slider2.png" width="100%"/> </div>
                                 <div><img src="images/Slider3.png" width="100%"/></div>
                                 </Slider>


                                 </div>
                                 <div className="col-12 col-md-7 col-lg-7 banner-content welcome">

                                    <div className="banner-cont-info text-white wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                                       <h3>What is better than having ONE multipurpose token with different use cases?</h3>
                                       <br />
                                       <h2 className="text-white">Introducing YUSE,</h2>
                                       <p>The ultimate multi-utility token!</p>
                                       <div>WE'RE LIVE on <br />
                                          <img src="images/pancake.png" width="50%" />
                                       </div>

                                       <form className="">
                                          <div className="form-group row">
                                             <div className="col-sm-12 text-center" >
                                                <div className="col-form-label mt-3 contract" style={{ background: 'transparent', color: '#fff' }}> <label for="inputPassword" className=" col-form-label">Contract&nbsp;:&nbsp;</label>0x8526ff6bbd8a976127443b1ce451ca1044aa3ce2&nbsp;&nbsp;<CopyToClipboard text="0x8526ff6bbd8a976127443b1ce451ca1044aa3ce2"
                                                   onCopy={() => this.setState({ copied: true })}>
                                                   <span style={{ cursor: 'pointer' }} title="Copy to clipboard"><i className="fa fa-clone"></i></span>
                                                </CopyToClipboard>
                                                   <br />
                                                   {
                                                      this.state.copied ? <i className="fa fa-clipboard" style={{ float: 'right', top: '-26px', left: '-36px' }} aria-hidden="true"></i>
                                                         : null
                                                   }
                                                </div>
                                             </div>
                                          </div>
                                       </form>

                                       <ul className="d-flex flex-wrap social-link social-link-banner pl-0">
                                          <li><a href="https://twitter.com/YuseToken" target="_blank" className="btn-social btn-outline" title="Twitter"><i className="fa fa-twitter"></i></a></li>
                                          <li><a href="https://www.instagram.com/yusetoken/" target="_blank" className="btn-social btn-outline" title="instagram"><i className="fa fa-instagram"></i></a></li>
                                          <li><a href="https://www.facebook.com/yusetoken/" target="_blank" className="btn-social btn-outline" title="facebook"><i className="fa fa-facebook"></i></a></li>
                                          <li><a href="https://t.me/joinchat/2h2s6lfji-83Zjdl" target="_blank" className="btn-social btn-outline" title="telegram"><i className="fa fa-telegram"></i></a></li>
                                       </ul>
                                    </div>
                                 </div>
                                 <div className="col-12 col-md-5 col-lg-5 banner-img">

                                    <div className=" text-center boxheight">
                                       <h2 className="text-white">Yuse Token Price </h2>
                                       <br />

                                    </div>

                                    <div className="col-sm-12 mt-3">
                                       <div className="row text-white">
                                          <div className="col-md-12 col-12 text-center left-ban">
                                            
                                             <h3 className='one'>1 YUSE=${parseFloat(this.state.tokenPricePancake).toFixed(3)}</h3>
                                          </div>
                                       </div>
                                    </div>

                                    <div className="col-sm-12 ">
                                       <hr style={{ borderTop: "1px solid #eee" }} />
                                       <div className=" text-center boxheight">
                                          <h2 className="text-white">Staking Rewards </h2>
                                          <br />

                                       </div>
                                       <div className="row text-white">
                                          <div className="col-sm-12 text-center col-12 margin-auto">

                                             {Cookies.get('loginSuccess') ?
                                                <a className="unc-btn btn-pink" href={`${config.baseUrl}dashboard`} >BUY NOW</a>
                                                :
                                                <a className="unc-btn btn-pink" href={`${config.baseUrl}login`} >BUY NOW</a>
                                             }

                                             <a className="unc-btn btn-pink" target="_blank" href="https://pancakeswap.finance/">BUY NOW</a>

                                             <h6 >100% for 10 months</h6>
                                             <h6 >50% for 6 months</h6>
                                          </div>
                                          <div className="col-sm-5 col-12 margin-auto">
                                          </div>
                                          <br />
                                          <div className="">
                                             <div className="col-md-12">

                                                <small>*Minimum $100 worth of YUSE tokens are required for staking</small>
                                             </div>
                                             <div className="col-md-12" style={{ textAlign: 'center' }} >

                                                <a className="unc-btn btn-pink" target="_blank" href="https://pancakeswap.finance/">BUY NOW</a>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>

                              </div>
                           </div>


                           <a className="btn-scroll skip_swing" href="#section0"><span></span></a>
                        </div> */}

                     </div>
                  </div>


                  <div className="ecosystem" >
                     <div className='row'>
                        <div className="col-sm-12 pb-5">
                           <div className="heading pt-0 text-center">
                              <h2 className="section-big-title-token text-center driven">AS SEEN ON</h2>
                              <h2 className="section-title text-center">AS SEEN ON</h2>
                           </div>
                        </div>
                     </div>

                     <div className='bg_color boxpartner'>
                        <div className='container'>
                           <div className="row  partner-area mobile-pb-20 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".5s">

                              <div className="col-6 col-md-3 col-lg-3  partner-logo-seen">
                                 <a href="https://www.coingecko.com/en/coins/yuse " target="_blank">
                                    <img src="images/coingecko.webp" alt="" />
                                 </a>
                              </div>

                              <div className="col-6 col-md-3 col-lg-3  partner-logo-seen">
                                 <a href="https://coinmarketcap.com/currencies/yuse/">
                                    <img src="images/coinmarketcap_1.svg" alt="" />
                                 </a>
                              </div>

                              <div className="col-6 col-md-3 col-lg-3  partner-logo-seen">
                                 <a href="https://exchange.biswap.org/#/swap?outputCurrency=0x85ef92ded9581a70b3741db005137ba98ba4d5ee" target="_blank">
                                    <img src="images/img1.png" alt="" className='nasdaq-feature-biswap' />
                                 </a>
                              </div>

                              <div className="col-6 col-md-3 col-lg-3  partner-logo-seen">
                                 <a href="https://trade.bscswap.com/#/swap?outputCurrency=0x85ef92ded9581a70b3741db005137ba98ba4d5ee" target="_blank">
                                    <img src="images/img2.png" alt="" />
                                 </a>
                              </div>

                              <div className="col-6 col-md-3 col-lg-3 partner-logo-seen">
                                 <a href="https://crypto.com/price/yuse" target="_blank">
                                    <img src="images/Crypto.com_Logo.png" alt="" />
                                 </a>
                              </div>

                              {/* <div className="col-6 col-md-2 col-lg-2  partner-logo-seen">
                                 <a href=" https://cheeseswap.app/#/swap?outputCurrency=0x85ef92ded9581a70b3741db005137ba98ba4d5ee" target="_blank">
                                    <img src="images/img3.png" alt="" />
                                 </a>
                              </div> */}

                              <div className="col-6 col-md-3 col-lg-3  partner-logo-seen">
                                 <a href=" https://bsc.mdex.me/#/swap?lang=en?outputCurrency=0x85ef92ded9581a70b3741db005137ba98ba4d5ee" target="_blank">
                                    <img src="images/img4.png" alt="" style={{ paddingTop: "25px" }} />
                                 </a>
                              </div>

                              {/* <div className="col-6 col-md-2 col-lg-2  partner-logo-seen">
                                 <a href="https://autofarm.network/swap/?inToken=0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee&outToken=0x85ef92ded9581a70b3741db005137ba98ba4d5ee" target="_blank">
                                    <img src="images/img5.png" alt="" class="img-f" />
                                 </a>
                              </div> */}
                              <div className="col-6 col-md-3 col-lg-3  partner-logo-seen">
                                 <a href="https://apeswap.finance/swap?outputCurrency=0x85ef92ded9581a70b3741db005137ba98ba4d5ee" target="_blank">
                                    <img src="images/img6.png" alt="" />
                                 </a>
                              </div>
                              <div className="col-6 col-md-3 col-lg-3  partner-logo-seen">
                                 <a href="https://tradingstrategy.ai/trading-view/binance/pancakeswap-v2/yuse-busd" target="_blank">
                                    <img src="images/img7.png" alt="" />
                                 </a>
                              </div>
                           </div>
                        </div>
                     </div>
                     {/* <Marquee pauseOnHover={true} speed="40" className='mb-5'>
                        <a href="https://tradingstrategy.ai/trading-view/binance/pancakeswap-v2/yuse-busd" target="_blank">
                           <img src="images/img1.png" className="marquee-img" /></a>

                        <a href="https://bsc.mdex.me/#/swap?lang=en?outputCurrency=0x8526ff6bbd8a976127443b1ce451ca1044aa3ce2" target="_blank">
                           <img src="images/img2.png" className="marquee-img two" /></a>

                        <a href="https://www.coingecko.com/en/coins/yuse" target="_blank">
                           <img src="images/coingecko.webp" className="marquee-img three" /></a>

                        <a href="https://coinmarketcap.com/currencies/yuse/" target="_blank">
                           <img src="images/coinmarketcap_1.svg" className="marquee-img" /></a>

                        <a href="https://crypto.com/price/yuse" target="_blank">
                           <img src="images/Crypto.com_Logo.png" className="marquee-img" /></a>

                        <a href="https://trade.bscswap.com/#/swap?outputCurrency=0x8526ff6bbd8a976127443b1ce451ca1044aa3ce2" target="_blank">
                           <img src="images/img3.png" className="marquee-img" /></a>
                        <a href="https://exchange.biswap.org/#/swap?outputCurrency=0x8526ff6bbd8a976127443b1ce451ca1044aa3ce2" target="_blank">
                           <img src="images/img4.png" className="marquee-img" /></a>
                        <a href="https://cheeseswap.app/#/swap?outputCurrency=0x8526ff6bbd8a976127443b1ce451ca1044aa3ce2" target="_blank">
                           <img src="images/img5.png" className="marquee-img one" /></a>
                        <a href="https://apeswap.finance/swap?outputCurrency=0x8526ff6bbd8a976127443b1ce451ca1044aa3ce2" target="_blank">
                           <img src="images/img6.png" className="marquee-img" /></a>
                        <a href="https://autofarm.network/swap/?inToken=0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee&outToken=0x8526ff6bbd8a976127443b1ce451ca1044aa3ce2" target="_blank">
                           <img src="images/img7.png" className="marquee-img" /></a>
               



                     </Marquee> */}
                     <div className="container">
                        <div className="row">
                           <div className="col-sm-12">
                              <div className="heading pt-0 text-center">
                                 <h2 className="section-big-title-token text-center driven">PEOPLE DRIVEN</h2>
                                 <h2 className="section-title text-center">PEOPLE DRIVEN</h2>
                              </div>
                           </div>
                           <div className='col-sm-12'>
                              <div className="row">
                                 <div className="col-sm-6">
                                    <div className="mt-5 pt-5 text-white ">
                                       <iframe width="100%" height="315"
                                          src="https://www.youtube.com/embed/WXTiTHrZhfk">
                                       </iframe>
                                       {/* <iframe width="100%" height="315"
                                          src="https://www.youtube.com/embed/oXnGNzqBU-8">
                                       </iframe> */}
                                    </div>
                                 </div>
                                 <div className="col-sm-6 mob-content">
                                    <div className="mt-5 pt-5 text-white ">
                                       <p>
                                          YUSE Token can be utilized for multiple purposes. For Instance, <b>Communication</b> is one such product where users will be able to chat with their loved ones, date their potential matches, and will be able to
                                          share and earn rewards.
                                       </p>
                                       <p>
                                          The <b>Gaming</b> world will allow users the 'experience of true gaming' with NFT support features. On the other hand, <b>Social Entertainment</b> focuses on content creators and short entertaining videos, users
                                          can support their favorite creators by tipping with tokens, as they get entertained.
                                       </p>
                                       <p>
                                          <b>Cloud storage</b> provides dedicated blockchain servers where your data would be stored and secured without any worry. While the Travel space gives users the free will to share and earn tokens as they travel
                                          to their favorite destinations.
                                       </p>
                                       <p>And above everything, the <b>YUSE university</b> will provide blockchain and cryptocurrency learnings.</p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>

               <section className="unc-inventment-container overflow-hidden" id="about-id">
                  <div className="container">
                     <div className="row pt-20 pb-0 mobile-pb-0">
                        <div className="col-12 col-md-12 col-lg-6 mobile-pb-0">
                           <div className="unc-inventment-content pt-110 mobile-pt-40 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".7s">
                              {/* <h6 className="sub-title3 text-left">Mobile APP</h6> */}
                              <h2 className="section-big-title text-left">YUSE WALLET</h2>
                              <h2 className="section-title-mobile text-left">YUSE WALLET</h2>
                              {/* <h1>MOBILE APP</h1> */}
                              {/* <p>Yuse Mobile App will include various applications like</p>
                              <ul className=" pl-5">
                                 <li>Block chain based chat application</li>
                                 <li>AI based Virtual Gaming </li>
                                 <li> Secured Cloud storage</li>
                                 <li>Travel Booking App</li>
                                 <li>Social Entertainment </li>
                                 <li>YUSE University for Blockchain & Crypto Education</li>
                              </ul> */}
                              <p>YUSE wallet is a crypto wallet that can be used to send & 
                                 receive crypto coin/ token.<br></br>
                                 YUSE wallet will enable permission-less, trust-less transactions 
                                 free of third-party interference. YUSE wants to provide users 
                                 with this one-of-a-kind experience by providing access to 
                                 premium DeFi services such as staking, farming, and much 
                                 more to further push for total financial decentralization.<p></p>
                                 Yuse wallet was launched on 4th June 2022 on Google Play 
                                 Store and iOS App Store.</p>
                              <a className="unc-btn btn-outline-apple text-white mt-5" style={{ background: "#4c1267" }} href="https://apps.apple.com/in/app/yuse-wallet/id1630759229">
                                 <div className=''>
                                    <div class="d-inline">
                                       <img src="assets/images/apple.png" style={{ width: '15px', marginTop: '-6px', filter: "invert(1)" }} />
                                    </div>
                                    &nbsp;
                                    <div class="d-inline">
                                       <small>Download on the</small>
                                       <br /> App Store
                                    </div>
                                 </div>
                              </a>
                              <a className="unc-btn btn-google-play text-white mt-5" style={{ background: "#4c1267" }} href="https://play.google.com/store/apps/details?id=com.yusewallet">
                                 <div className=''>
                                    <div class="d-inline">
                                       <img src="assets/images/android.png" style={{ width: '15px', marginTop: '-6px' }} />
                                    </div> &nbsp;
                                    <div class="d-inline">
                                       &nbsp;
                                       <small>GET IT ON</small>
                                       <br />
                                       Google Play
                                    </div>
                                 </div>
                              </a>
                           </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6">
                           <div className="mobile-image">
                              <img src="assets/images/001.png" width="100%" />
                                                         </div>
                        </div>
                     </div>
                  </div>
               </section>

               <div className="unc-happenning-container overflow-hidden">
                  <div className="container-fluid">
                     <div className="row pt-110 pb-50 mobile-pt-60 mobile-pb-0">
                        <div className="col-12 big-title mb-4 text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s">

                           <h2 className="section-big-title-token text-center">TOKEN ECONOMICS</h2>
                           <h2 className="section-title text-center">TOKEN ECONOMICS</h2>
                           <p className="text-white text-white">Breakdown of our Token Recipients.</p>
                        </div>
                     </div>
                     <div className="container text-center mb-5">
                        <div className="row">
                           <div className="col-sm-4 col-6 mobile-mb-1">
                              <div className="token-list">
                                 <h3>1,111,111,111</h3>
                                 <h4>TOTAL TOKEN SUPPLY</h4>
                              </div>
                           </div>
                           <div className="col-sm-4 col-6 mobile-mb-1">
                              <div className="token-list">
                                 <h3>$5,030,000</h3>
                                 <h4>HARDCAP (ICO)</h4>
                              </div>
                           </div>
                           <div className="col-sm-4 col-6 mobile-mb-1">
                              <div className="token-list">
                                 <h3>$499,999</h3>
                                 <h4>SOFTCAP (ICO)</h4>
                              </div>
                           </div>
                           <div className="col-sm-4 col-6 mobile-mb-1">
                              <div className="token-list">
                                 <h3>222,222,222</h3>
                                 <h4>TOKEN FOR PUBLIC</h4>
                              </div>
                           </div>
                           <div className="col-sm-4 col-6 mobile-mb-1">
                              <div className="token-list">
                                 <h3>${this.state.tokenPrice} USD</h3>
                                 <h4>YUSE TOKEN PRICE</h4>
                              </div>
                           </div>
                           <div className="col-sm-4 col-6 mobile-mb-1">
                              <div className="token-list">
                                 <h3>ETH, BNB</h3>
                                 <h4>ACCEPTED CURRENCY</h4>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="container">
                        <div className="row">
                           <div className="col-sm-12 text-center">
                              <h3>Token Distribution</h3>


                              {/* ============================================= */}

                              <div className="col-md-7">
                                 <figure className="highcharts-figure">
                                    <div id="container"></div>
                                 </figure>
                              </div>
                              <div className="col-md-5">
                                 <div className="bigWrapper">
                                    <div className="legendWrapper">
                                       <ul className="donutTarget-second legend">
                                          <li>
                                             <em>5% &nbsp;Pre Sales</em>
                                             <span className="hidden"></span>
                                          </li>
                                          <li>
                                             <em>20% &nbsp;Public Sales</em>
                                             <span className="hidden">20</span>
                                          </li>
                                          <li>
                                             <em>14% &nbsp;Marketing & Development</em>
                                             <span className="hidden">14</span>
                                          </li>
                                          <li>
                                             <em>19% &nbsp;Team & Foundation</em>
                                             <span className="hidden">19</span>
                                          </li>
                                          <li>
                                             <em>10% &nbsp;Liquidity Pool</em>
                                             <span className="hidden">25</span>
                                          </li>
                                          <li>
                                             <em>5% &nbsp;Collaborations</em>
                                             <span className="hidden">10</span>
                                          </li>
                                          <li>
                                             <em>25% &nbsp;Rewards</em>
                                             <span className="hidden">5</span>
                                          </li>
                                          <li>
                                             <em>2% &nbsp;Charity</em>
                                             <span className="hidden">2</span>
                                          </li>
                                       </ul>
                                    </div>
                                 </div>
                              </div>


                              {/* ==================================================== */}

                           </div>

                        </div>
                     </div>

                  </div>
               </div>
               {/* <div className="unc-happenning-container overflow-hidden">
                  <div className="container-fluid">
                     <div className="row pt-110 pb-50 mobile-pt-60 mobile-pb-0">
                        <div className="col-12 big-title mb-4 text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s">
                           <h2 className="section-big-title-token text-center">GALLERY</h2>
                           <h2 className="section-title text-center">GALLERY</h2>
                           </div>
                     </div>
                     <div className="container text-center mb-5">
                        <div className="row">
                           <div className="col-sm-4 col-12 mobile-mb-1 mb-5">
                           <div class="portfolio-wrapper">
                              <div class="portfolio-image">
                                 <img src="images/1 Yuse wallet.png" alt="..."/>
                                    </div>
                                    </div>
                           </div>
                           <div className="col-sm-4 col-12 mobile-mb-1 mb-5">
                           <div class="portfolio-wrapper">
                              <div class="portfolio-image">
                                 <img src="images/2 Yuse NFT.png" alt="..."/>
                                    </div>
                                    </div>
                           </div>
                           <div className="col-sm-4 col-12 mobile-mb-1 mb-5">
                           <div class="portfolio-wrapper">
                              <div class="portfolio-image">
                                 <img src="images/3 Yuse Funtasy.png" alt="..."/>
                                    </div>
                                    </div>
                           </div>
                           <div className="col-sm-4 col-12 mobile-mb-1 mb-5">
                           <div class="portfolio-wrapper">
                              <div class="portfolio-image">
                                 <img src="images/5 Yuse Circle.png" alt="..."/>
                                    </div>
                                    </div>
                           </div>
                           <div className="col-sm-4 col-12 mobile-mb-1 mb-5">
                           <div class="portfolio-wrapper">
                              <div class="portfolio-image">
                                 <img src="images/4 Meta yuse.png" alt="..."/>
                                    </div>
                                    </div>
                           </div>
                           <div className="col-sm-4 col-12 mobile-mb-1 mb-5">
                           <div class="portfolio-wrapper">
                              <div class="portfolio-image">
                                 <img src="images/6 Yuse gaming.png" alt="..."/>
                                    </div>
                                    </div>
                           </div>
                        </div>
                     </div>
                     <div className='row'>
                        <div className='col-lg-12 text-center'>
                           <a href={`${config.baseUrl}gallery`} className='gallery'>View More</a>
                        </div>
                          </div>

                  </div>
               </div> */}

               <section id="roadmap_06" style={{ zIndex: '-1px !important' }}>

                  {/* Container (Graph Section) */}
                  <div id="roadmap" className="roadmap-section">
                     <div className="roadmap-inner-section">
                        <div className="container">
                           <div className="row">
                              <div className="col-sm-12">
                                 <h2 className="section-big-title-roadmap text-center">ROADMAP</h2>
                                 <h2 className="section-title-roadmap text-center">ROADMAP</h2>
                                 <p className="text-center">The YUSE roadmap below provides an overview of the entire timeline of
                                    the project’s preparation, development, and implementation of upcoming goals.</p>
                                 <ul className="roadmap-row">
                                    <li className="one_list">
                                       <h3>Q4-2021</h3>
                                       <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;CIRCLE Logo finalization</p>
                                       <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;CIRCLE team on boarding</p>
                                       <a className="view-more-btn popup" onClick={this.RoadeMapFunction.bind(this, 'Q5Popup')}>
                                          View More
                                          <span className="popuptext" id="Q5Popup">
                                             <h3>Q4-2021</h3>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;CIRCLE Logo finalization</p>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;CIRCLE team on boarding</p>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;Awareness campaign</p>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;Digital content creation </p>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;Smart contract goes on Main net</p>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;YUNI Team formation</p>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;YUNI Courses creation begins</p>

                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;Public Sale begins </p>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;Technical team expansion</p>

                                          </span>
                                       </a>
                                       <span className="circle"><i cass="fa fa-circle" /></span>
                                    </li>
                                    <li className="two_list">
                                       <h3>Q2-2022</h3>
                                       <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;Launching YUSE University curriculum</p>
                                       <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;FUNtasy Team onboarding</p>
                                       <a className="view-more-btn popup" onClick={this.RoadeMapFunction.bind(this, 'Q6Popup')}>
                                          View More
                                          <span className="popuptext" id="Q6Popup">
                                             <h3>Q2-2022</h3>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;Launching YUSE University curriculum</p>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;FUNtasy Team onboarding</p>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;FUNtasy Branding promotion initiated</p>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;YUPLAY Team on boarding</p>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;YUPLAY Logo finalization </p>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;Launching CIRCLE app</p>

                                          </span>
                                       </a>
                                       <span className="circle"><i cass="fa fa-circle" /></span>
                                    </li>
                                    <li className="three_list">
                                       <h3>Q4-2022</h3>
                                       <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;Project reviews</p>
                                       <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;New Product launches</p>
                                       <a className="view-more-btn popup" onClick={this.RoadeMapFunction.bind(this, 'Q4Popup')}>
                                          View More
                                          <span className="popuptext" id="Q4Popup">
                                             <h3>Q4-2022</h3>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;Project reviews</p>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;New Product launches</p>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;XperienZ Logo finalization</p>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;XperienZ Technical team expansion</p>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;Launching XperienZ app</p>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;Awareness Campaign</p>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;More CEX Listings</p>
                                          </span>
                                       </a>
                                       <span className="circle"><i cass="fa fa-circle" /></span>
                                    </li>
                                 </ul>
                                 <ul className="roadmap-second-row">
                                    <li className="four_list">
                                       <h3>Q3-2021</h3>
                                       <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;YUSE Ecosystem development initiated </p>
                                       <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;Approval of various upcoming products completed</p>
                                       <a className="view-more-btn popup view-more-Q1" onClick={this.RoadeMapFunction.bind(this, 'Q1Popup')}>
                                          View More
                                          <span className="popuptext" id="Q1Popup">
                                             <h3>Q3-2021</h3>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;YUSE Ecosystem development initiated </p>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;Approval of various upcoming products completed</p>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;Smart Contract creation on test net  </p>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;Seeders Sale finalization & completion </p>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;Team expansion Chief Advisor on boarding completed  </p>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;Blue print of the Ecosystem Approved</p>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;Phase 1 Products Approved </p>
                                             <p>YUSE ecosystem Phase-I:- </p>
                                             <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Communication & Dating app - CIRCLE </p>
                                             <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- YUSE University – YUNI </p>
                                             <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Social Entertainment app - FUNtasy </p>
                                             <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Gaming app - YUPLAY </p>
                                             <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Travel app – XperienZ </p>
                                             <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Cloud storage - AIRlift </p>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;Website launched</p>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;Digital & Offline Marketing initiated</p>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;Private Sale opening </p>
                                             {/* <div className="close-btn">×</div> */}
                                          </span>
                                       </a>
                                       <span className="circle"><i cass="fa fa-circle" /></span>
                                    </li>
                                    <li className="five_list">
                                       <h3>Q1-2022</h3>

                                       <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;CIRCLE team development inception</p>
                                       <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;CIRCLE Digital branding campaigns initiated</p>
                                       <a className="view-more-btn popup" onClick={this.RoadeMapFunction.bind(this, 'Q2Popup')}>
                                          View More
                                          <span className="popuptext" id="Q2Popup">
                                             <h3>Q1-2022</h3>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;CIRCLE team development inception</p>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;CIRCLE Digital branding campaigns initiated</p>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;CIRCLE testing phase initiated</p>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;Launching Decentralized wallet for YUSE</p>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;High level community engagement through YUNI</p>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;Process of launching on DEX initiated</p>

                                          </span>
                                       </a>
                                       <span className="circle"><i cass="fa fa-circle" /></span>
                                    </li>
                                    <li className="six_list">

                                       <h3>Q3-2022</h3>
                                       <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;YUSE University launch</p>
                                       <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;Awareness campaign</p>
                                       <a className="view-more-btn popup" onClick={this.RoadeMapFunction.bind(this, 'Q3Popup')}>
                                          View More
                                          <span className="popuptext" id="Q3Popup">
                                             <h3>Q3-2022</h3>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;YUSE University launch</p>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;Awareness campaign</p>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;Some more DEX pairs listing</p>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;Launching of CIRCLE Plus</p>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;CEX Listings initiated</p>
                                             <p><i className="fa fa-angle-double-right" />&nbsp;&nbsp;Explore possible affiliations with various global universities</p>
                                          </span>
                                       </a>
                                       <span className="circle"><i cass="fa fa-circle" /></span>
                                    </li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  {/* Container (Graph Section) */}
               </section>

               {/* <div className="faq pt-110 pb-110 mobile-pt-60 mobile-pb-20 overflow-hidden" id="faq">
                  <div className="container">
                     <div className="row">
                        <div className="col-sm-12 ">
                           <h2 className="section-big-title-token text-center">FREQUENTLY ASKED QUESTIONS</h2>
                           <h2 className="section-title text-center">FREQUENTLY ASKED QUESTIONS</h2>
                           <p className="text-center">Breakdown of our Token Recipients.</p>
                           <br />
                        </div>
                     </div>
                     <div className="row">
                        <div className="col-md-3 mb-3">
                           <ul className="nav nav-pills flex-column" id="myTab" role="tablist">
                              <li className="">
                                 <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">General</a>
                              </li>
                              <li className="">
                                 <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Pre-ICO & ICC</a>
                              </li>
                              <li className="">
                                 <a className="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">ICO Tokens</a>
                              </li>
                              <li className="">
                                 <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Client</a>
                              </li>
                              <li className="">
                                 <a className="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Legal</a>
                              </li>
                           </ul>
                        </div>

                        <div className="col-md-9">
                           <div className="tab-content" id="myTabContent">
                              <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                 <Accordion atomic={true}>
                                    <AccordionItem className='accordion shadow card card-header bg-pink shadow-sm border-0' title="What services does Yuse Offer?">
                                       <p className="font-weight-light m-0">
                                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                                       </p>
                                    </AccordionItem>

                                     <AccordionItem className='accordion shadow card card-header bg-pink shadow-sm border-0' title="Why should i choose a Design studio like Yuse over full-service agency?">
                                       <p className="font-weight-light m-0">
                                       Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.

                                       </p>
                                    </AccordionItem>
                                    <AccordionItem className='accordion shadow card card-header bg-pink shadow-sm border-0' title="How does Yuse create website content without knowing our Business plan?">
                                       <p className="font-weight-light m-0">
                                       Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.

                                       </p>
                                    </AccordionItem>
                                   
                                    <AccordionItem className='accordion shadow card card-header bg-pink shadow-sm border-0' title=" What will be delivered? And When?">
                                       <p className="font-weight-light m-0">
                                       Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.

                                       </p>
                                    </AccordionItem>
                                    <AccordionItem className='accordion shadow card card-header bg-pink shadow-sm border-0' title=" What often will results be reported?">
                                       <p className="font-weight-light m-0">
                                       Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.

                                       </p>
                                    </AccordionItem>
                                    <AccordionItem className='accordion shadow card card-header bg-pink shadow-sm border-0' title=" How quickly will i start seeing result after working with Yuse?">
                                       <p className="font-weight-light m-0">
                                       Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.

                                       </p>
                                    </AccordionItem>
                                    
                                 </Accordion>


                              </div>
                              <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                              <Accordion atomic={true}>
                                    <AccordionItem className='accordion shadow card card-header bg-pink shadow-sm border-0' title="What services does Yuse Offer?">
                                       <p className="font-weight-light m-0">
                                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                                       </p>
                                    </AccordionItem>

                                     <AccordionItem className='accordion shadow card card-header bg-pink shadow-sm border-0' title="Why should i choose a Design studio like Yuse over full-service agency?">
                                       <p className="font-weight-light m-0">
                                       Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.

                                       </p>
                                    </AccordionItem>
                                    <AccordionItem className='accordion shadow card card-header bg-pink shadow-sm border-0' title="How does Yuse create website content without knowing our Business plan?">
                                       <p className="font-weight-light m-0">
                                       Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.

                                       </p>
                                    </AccordionItem>
                                    
                                    <AccordionItem className='accordion shadow card card-header bg-pink shadow-sm border-0' title=" What will be delivered? And When?">
                                       <p className="font-weight-light m-0">
                                       Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.

                                       </p>
                                    </AccordionItem>
                                    <AccordionItem className='accordion shadow card card-header bg-pink shadow-sm border-0' title=" What often will results be reported?">
                                       <p className="font-weight-light m-0">
                                       Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.

                                       </p>
                                    </AccordionItem>
                                    <AccordionItem className='accordion shadow card card-header bg-pink shadow-sm border-0' title=" How quickly will i start seeing result after working with Yuse?">
                                       <p className="font-weight-light m-0">
                                       Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.

                                       </p>
                                    </AccordionItem>
                                    
                                 </Accordion>


                              </div>
                              <div className="tab-pane fade " id="contact" role="tabpanel" aria-labelledby="contact-tab">
                              <Accordion atomic={true}>
                                    <AccordionItem className='accordion shadow card card-header bg-pink shadow-sm border-0' title="What services does Yuse Offer?">
                                       <p className="font-weight-light m-0">
                                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                                       </p>
                                    </AccordionItem>

                                     <AccordionItem className='accordion shadow card card-header bg-pink shadow-sm border-0' title="Why should i choose a Design studio like Yuse over full-service agency?">
                                       <p className="font-weight-light m-0">
                                       Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.

                                       </p>
                                    </AccordionItem>
                                    <AccordionItem className='accordion shadow card card-header bg-pink shadow-sm border-0' title="How does Yuse create website content without knowing our Business plan?">
                                       <p className="font-weight-light m-0">
                                       Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.

                                       </p>
                                    </AccordionItem>
                                    
                                    <AccordionItem className='accordion shadow card card-header bg-pink shadow-sm border-0' title=" What will be delivered? And When?">
                                       <p className="font-weight-light m-0">
                                       Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.

                                       </p>
                                    </AccordionItem>
                                    <AccordionItem className='accordion shadow card card-header bg-pink shadow-sm border-0' title=" What often will results be reported?">
                                       <p className="font-weight-light m-0">
                                       Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.

                                       </p>
                                    </AccordionItem>
                                    <AccordionItem className='accordion shadow card card-header bg-pink shadow-sm border-0' title=" How quickly will i start seeing result after working with Yuse?">
                                       <p className="font-weight-light m-0">
                                       Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.

                                       </p>
                                    </AccordionItem>
                                    
                                 </Accordion>


                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div> */}

               <div className="partner">
                  <div className="container">

                     <div className="row ">
                        <div className="col-sm-12">
                           <h2 className="section-big-title-roadmap text-center">FEATURED IN</h2>
                           <h2 className="section-title-roadmap text-center">FEATURED IN</h2>
                           {/* <p className="text-center">Lorem Ipsum is simply dummy text of the printing and been the</p> */}
                        </div>
                     </div>
                     <div className="row justify-content-center partner-area mobile-pb-20 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".5s">

                        <div className="col-6 col-md-2 col-lg-2 mt-30 mb-50 partner-logo">
                           <a href="https://finance.yahoo.com/news/yuse-prepares-launch-digital-wallet-222800323.html?guccounter=1 " target="_blank">
                              <img src="images/yahoo_finance.png" alt="" />
                           </a>
                        </div>

                        <div className="col-6 col-md-3 col-lg-3 mt-30 mb-50 partner-logo">
                           <a href="https://www.khaleejtimes.com/kt-network/yuse-token-launches-its-private-token-sale">
                              <img src="assets/images/feature2.png" alt="" />
                           </a>
                        </div>

                        <div className="col-6 col-md-2 col-lg-2  partner-logo">
                           <a href="https://www.nasdaq.com/press-release/yuse-prepares-to-launch-its-digital-wallet-and-nft-marketplace-2022-05-07" target="_blank">
                              <img src="images/nasdaq.png" alt="" className='nasdaq-feature' />
                           </a>
                        </div>

                        <div className="col-6 col-md-2 col-lg-2 mt-30 mb-50 partner-logo">
                           <a href="https://www.benzinga.com/pressreleases/22/05/27064007/yuse-prepares-to-launch-its-digital-wallet-and-nft-marketplace" target="_blank">
                              <img src="images/benzinga.webp" alt="" />
                           </a>
                        </div>

                        <div className="col-6 col-md-2 col-lg-2 mt-30 mb-50 partner-logo">
                           <a href="https://www.bloomberg.com/press-releases/2022-05-07/yuse-prepares-to-launch-its-digital-wallet-and-nft-marketplace" target="_blank">
                              <img src="images/bloomberge.png" alt="" />
                           </a>
                        </div>

                        <div className="col-6 col-md-2 col-lg-2 mt-30 mb-50 partner-logo">
                           <a href="https://news.yahoo.com/news/yuse-prepares-launch-digital-wallet-222800323.html" target="_blank">
                              <img src="images/yahoo_news.png" alt="" />
                           </a>
                        </div>

                        <div className="col-6 col-md-2 col-lg-2 mt-16 mb-50 partner-logo">
                           <a href="https://www.marketwatch.com/press-release/yuse-the-worlds-first-immersive-ecosystem-launched-yuse-token-yuse-on-pancake-swap-2022-03-24" target="_blank">
                              <img src="assets/images/feature3.png" alt="" style={{ paddingTop: "25px" }} />
                           </a>
                        </div>

                        <div className="col-6 col-md-2 col-lg-2 mt-16 mb-50 partner-logo">
                           <a href="https://www.investingcube.com/yuse-protocol-launches-own-wallet-plans-increased-use-for-yuse-token/" target="_blank">
                              <img src="images/investingcube.png" alt="" class="col-6 col-md-2 col-lg-2 mt-16 mb-50 partner-logo pt-4" />
                           </a>
                        </div>


                        {/* <div className="col-6 col-md-2 col-lg-2 mt-30 mb-50 partner-logo">
                           <a href="https://money.yahoo.com/news/yuse-prepares-launch-digital-wallet-222800323.html" target="_blank">
                              <img src="images/yahoo_money.png" alt="" />
                           </a>
                        </div> */}

                        <div className="col-6 col-md-2 col-lg-2 mt-3 partner-logo">
                           <a href="https://www.digitaljournal.com/pr/yuse-prepares-to-launch-its-digital-wallet-and-nft-marketplace" target="_blank">
                              <img src="images/Digital-Journal-NEW.jpg" alt="" />
                           </a>
                        </div>


                        {/* <div className="col-6 col-md-2 col-lg-2 mt-30 mb-50 partner-logo">
                           <a href="https://www.marketwatch.com/press-release/yuse-prepares-to-launch-its-digital-wallet-and-nft-marketplace-2022-05-07?mod=mw_quote_news_seemore"  target="_blank">
                              <img src="images/market_watch.png" alt="" />
                           </a>
                        </div> */}


                        {/* <div className="col-6 col-md-2 col-lg-2 mt-30 mb-50 partner-logo">
                           <a href="#">
                              <img src="assets/images/feature1.jpeg" alt="" />
                           </a>
                        </div> */}
                        {/* <div className="col-6 col-md-3 col-lg-2 mt-30 mb-50 partner-logo">
                           <a href="#">
                              <img src="assets/images/4.png" alt="" style={{ paddingTop: "25px" }} />
                           </a>
                        </div>
                        <div className="col-6 col-md-3 col-lg-2 mt-30 mb-50 partner-logo">
                           <a href="#">
                              <img src="assets/images/5.png" alt="" />
                           </a>
                        </div>
 */}

                     </div>
                  </div>
               </div>
               <div className="bottom_bg">
                  <div className="team overflow-hidden" id="team-id">
                     <div className="container">
                        <div className="row pt-110 mobile-pt-60 ">
                           <div className="col-12 big-title white-text text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s">
                              <h2 className="section-big-title-token text-center">OUR TEAM</h2>
                              <h2 className="section-title text-center">OUR TEAM</h2>
                              {/* <p className="text-center text-white">Lorem Ipsum is simply dummy text of the printing and been the</p> */}
                              {/* <h6 className="sub-title2 text-center">Our Team</h6> */}
                              {/* <h1 className="text-white">OUR TEAM</h1> */}
                              {/* <p className="text-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the</p> */}
                           </div>
                        </div>
                        <div className="row justify-content-center team-col-area pt-40 pb-60 mobile-pt-10 mobile-pb-20">
                           <div className="col-10 col-md-4 col-lg-4 team-col pb-50 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                              <div className="team-bg">
                                 <div className="team-mem">
                                    <div className="team-mem-info">
                                       <a href="#"><img src="assets/images/umesh.png" alt="" /></a>
                                    </div>
                                    <div className="team_social_s2 list_none">
                                       <ul>
                                          <li><a onClick={e => this.setState({ modalOpen: true })}><i className="fa fa-info-circle"></i></a></li>
                                          <li><a target="_blank" href="https://www.linkedin.com/in/umesh75/"><i className="fab fa-linkedin"></i></a></li>
                                          {/* <li><a target="_blank" href="https://twitter.com/YuseToken"><i className="fab fa-twitter"></i></a></li> */}
                                          {/* <li><a target="_blank" href="https://www.instagram.com/yusetoken/"><i className="fab fa-instagram"></i></a></li>
                                       <li><a target="_blank" href="https://t.me/joinchat/f4jxc9SAgbcxMTdl"><i className="fab fa-telegram"></i></a></li> */}
                                       </ul>
                                    </div>
                                 </div>
                                 <div className="team-cont">
                                    <h3><a href="google.com">Umesh Pandey</a></h3>
                                    <small>COO</small>
                                 </div>

                              </div>
                           </div>
                           {/* <div className="col-10 col-md-4 col-lg-4 team-col pb-50 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                              <div className="team-bg">
                                 <div className="team-mem">
                                    <div className="team-mem-info">
                                       <a href="#"><img src="assets/images/LordDesai.png" alt="" /></a>
                                    </div>
                                    <div className="team_social_s2 list_none">
                                       <ul>

                                          <li><a onClick={e => this.setState({ modalOpen6: true })}><i className="fa fa-info-circle"></i></a></li>
                                          <li><a target="_blank" href="#"><i className="fab fa-linkedin"></i></a></li>
                                         
                                       </ul>
                                    </div>
                                 </div>
                                 <div className="team-cont">
                                    <h3><a href="#">Lord Meghnad Desai</a></h3>
                                    <small>Mentor</small>
                                 </div>
                              </div>
                           </div> */}
                           <div className="col-10 col-md-4 col-lg-4 team-col pb-50 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                              <div className="team-bg">
                                 <div className="team-mem">
                                    <div className="team-mem-info">
                                       <a href="#"><img src="assets/images/Arvind_Vohra.png" alt="" /></a>
                                    </div>
                                    <div className="team_social_s2 list_none">
                                       <ul>
                                          <li><a onClick={e => this.setState({ modalOpen1: true })}><i className="fa fa-info-circle"></i></a></li>
                                          <li><a target="_blank" href="https://www.linkedin.com/in/arvind-r-vohra-3352177/"><i className="fab fa-linkedin"></i></a></li>
                                          {/* <li><a target="_blank" href="#"><i className="fab fa-facebook"></i></a></li>
                                       <li><a target="_blank" href="#"><i className="fab fa-twitter"></i></a></li>
                                       <li><a target="_blank" href="#"><i className="fab fa-instagram"></i></a></li>
                                       <li><a target="_blank" href="#"><i className="fab fa-linkedin"></i></a></li> */}
                                       </ul>
                                    </div>
                                 </div>
                                 <div className="team-cont">
                                    <h3><a href="#"> Arvind Vohra</a></h3>
                                    <small>Business Advisor</small>
                                 </div>
                              </div>
                           </div>

                           <div className="col-10 col-md-4 col-lg-4 team-col pb-50 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                              <div className="team-bg">
                                 <div className="team-mem">
                                    <div className="team-mem-info">
                                       <a href="#"><img src="assets/images/herbert.png" alt="" /></a>
                                    </div>
                                    <div className="team_social_s2 list_none">
                                       <ul>

                                          <li><a onClick={e => this.setState({ modalOpen2: true })}><i className="fa fa-info-circle"></i></a></li>
                                          <li><a target="_blank" href="#"><i className="fab fa-linkedin"></i></a></li>
                                          {/* <li><a target="_blank" href="#"><i className="fab fa-facebook"></i></a></li>
                                       <li><a target="_blank" href="#"><i className="fab fa-twitter"></i></a></li>
                                       <li><a target="_blank" href="#"><i className="fab fa-instagram"></i></a></li>
                                       <li><a target="_blank" href="#"><i className="fab fa-linkedin"></i></a></li> */}
                                       </ul>
                                    </div>
                                 </div>
                                 <div className="team-cont">
                                    <h3><a href="#">Herbert R. Sim</a></h3>
                                    <small>Investor and Advisor</small>
                                 </div>
                              </div>
                           </div>
                           <div className="col-10 col-md-4 col-lg-4 team-col pb-50 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                              <div className="team-bg">
                                 <div className="team-mem">
                                    <div className="team-mem-info">
                                       <a href="#"><img src="assets/images/timo-trippler1.png" alt="" /></a>
                                    </div>
                                    <div className="team_social_s2 list_none">
                                       <ul>
                                          <li><a onClick={e => this.setState({ modalOpen3: true })}><i className="fa fa-info-circle"></i></a></li>
                                          <li><a target="_blank" href="https://www.linkedin.com/in/timotrippler/"><i className="fab fa-linkedin"></i></a></li>
                                          {/* <li><a target="_blank" href="#"><i className="fab fa-facebook"></i></a></li>
                                       <li><a target="_blank" href="#"><i className="fab fa-twitter"></i></a></li>
                                       <li><a target="_blank" href="#"><i className="fab fa-instagram"></i></a></li>
                                       <li><a target="_blank" href="#"><i className="fab fa-linkedin"></i></a></li> */}
                                       </ul>
                                    </div>
                                 </div>
                                 <div className="team-cont">
                                    <h3><a href="#">Timo Trippler</a></h3>
                                    <small>Project Advisor</small>
                                 </div>
                              </div>
                           </div>
                           <div className="col-10 col-md-4 col-lg-4 team-col pb-50 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                              <div className="team-bg">
                                 <div className="team-mem">
                                    <div className="team-mem-info">
                                       <a href="#"><img src="assets/images/Athur_Zablocki.png" alt="" /></a>
                                    </div>
                                    <div className="team_social_s2 list_none">
                                       <ul>
                                          <li><a onClick={e => this.setState({ modalOpen4: true })}><i className="fa fa-info-circle"></i></a></li>
                                          <li><a target="_blank" href="https://www.linkedin.com/in/arthurzablocki/"><i className="fab fa-linkedin"></i></a></li>
                                          {/* <li><a target="_blank" href="#"><i className="fab fa-facebook"></i></a></li>
                                       <li><a target="_blank" href="#"><i className="fab fa-twitter"></i></a></li>
                                       <li><a target="_blank" href="#"><i className="fab fa-instagram"></i></a></li>
                                       <li><a target="_blank" href="#"><i className="fab fa-linkedin"></i></a></li> */}
                                       </ul>
                                    </div>
                                 </div>
                                 <div className="team-cont">
                                    <h3><a href="#">Arthur Zablocki</a></h3>
                                    <small>NFT Project Advisor</small>
                                 </div>
                              </div>
                           </div>
                           <div className="col-10 col-md-4 col-lg-4 team-col pb-50 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                              <div className="team-bg">
                                 <div className="team-mem">
                                    <div className="team-mem-info">
                                       <a href="#"><img src="assets/images/rafaella-mendes.png" alt="" /></a>
                                    </div>
                                    <div className="team_social_s2 list_none">
                                       <ul>
                                          <li><a onClick={e => this.setState({ modalOpen5: true })}><i className="fa fa-info-circle"></i></a></li>
                                          <li><a target="_blank" href="https://www.linkedin.com/in/kingdavid-ehindero-882793177/"><i className="fab fa-linkedin"></i></a></li>
                                          {/* <li><a target="_blank" href="#"><i className="fab fa-facebook"></i></a></li>
                                       <li><a target="_blank" href="#"><i className="fab fa-twitter"></i></a></li>
                                       <li><a target="_blank" href="#"><i className="fab fa-instagram"></i></a></li>
                                       <li><a target="_blank" href="#"><i className="fab fa-linkedin"></i></a></li> */}
                                       </ul>
                                    </div>
                                 </div>
                                 <div className="team-cont">
                                    <h3><a href="#"> Ehindero Kingdavid</a></h3>
                                    <small>Sr. Content Manager</small>
                                 </div>
                              </div>
                           </div>

                           <Modal show={this.state.modalOpen} onHide={e => this.setState({ modalOpen: false })}>
                              <Modal.Header closeButton>
                                 <Modal.Title>Umesh Pandey, COO</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>With an experience of 2 decades, Umesh is credited with having held prominent positions in some of the most reputed brands which include Telecom, Petrochemical and Cosmetics industry. With his vision, Umesh aims to make YUSE a household name in the coming years.</Modal.Body>
                              <Modal.Footer>
                                 <Button variant="secondary" onClick={e => this.setState({ modalOpen: false })}>
                                    Close
                                 </Button>

                              </Modal.Footer>
                           </Modal>


                           <Modal show={this.state.modalOpen1} onHide={e => this.setState({ modalOpen1: false })}>
                              <Modal.Header closeButton>
                                 <Modal.Title>Arvind R. Vohra, Business Advisor</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>Arvind Vohra is a household name in the Indian retail and consumer markets. He started his career with Samsung & Salora international, handling the distribution operations of global brands and products. His joined venture with Gionee Mobiles back in 2013 turned many heads and he had appeared in many press releases and TV shows. He also owns BansiVidya Memorial Trust where they help children treat cancers. Arvind is a chief business advisor for YUSE and aims to take YUSE to another level.</Modal.Body>
                              <Modal.Footer>
                                 <Button variant="secondary" onClick={e => this.setState({ modalOpen1: false })}>
                                    Close
                                 </Button>

                              </Modal.Footer>
                           </Modal>

                           <Modal show={this.state.modalOpen2} onHide={e => this.setState({ modalOpen2: false })}>
                              <Modal.Header closeButton>
                                 <Modal.Title>Allan Jackson, CMO</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>Allan is an MBA in Marketing from the University of Lincoln in the UK.  He also has an accomplished and result-driven 32-year career in the Interior Design, Logistics, Air Express as well as Media and Advertising industry. Allan is a very effective communicator, with a consultative style, strong negotiation skills and good problem-solving abilities.</Modal.Body>
                              <Modal.Footer>
                                 <Button variant="secondary" onClick={e => this.setState({ modalOpen2: false })}>
                                    Close
                                 </Button>

                              </Modal.Footer>
                           </Modal>

                           <Modal show={this.state.modalOpen3} onHide={e => this.setState({ modalOpen3: false })}>
                              <Modal.Header closeButton>
                                 <Modal.Title>Timo Trippler, Project Advisor</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>Timo bags 12 years of Advisory and Entrepreneurial experience in the field of Fin-tech and cryptocurrency. He has advised several ICO projects and oversaw multiple crypto fund portfolios. Some of his projects involve AirPod, Loyakk, and many others. He is currently connected to over 300 institutional investors worldwide and uses his extensive business network to raise funds.</Modal.Body>
                              <Modal.Footer>
                                 <Button variant="secondary" onClick={e => this.setState({ modalOpen3: false })}>
                                    Close
                                 </Button>

                              </Modal.Footer>
                           </Modal>

                           <Modal show={this.state.modalOpen4} onHide={e => this.setState({ modalOpen4: false })}>
                              <Modal.Header closeButton>
                                 <Modal.Title>Arthur Zablocki</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>Arthur has been working as an NFT advisor and consultant for blockchain start-ups for the last 7 years. Arthur has an excellent understanding of NFT and its marketplace. His strong adaptive nature helps the team understand trends and complicated futuristic innovations like Metaverse. Arthur is leading the NFT platform development for YUSE.</Modal.Body>
                              <Modal.Footer>
                                 <Button variant="secondary" onClick={e => this.setState({ modalOpen4: false })}>
                                    Close
                                 </Button>

                              </Modal.Footer>
                           </Modal>

                           <Modal show={this.state.modalOpen5} onHide={e => this.setState({ modalOpen5: false })}>
                              <Modal.Header closeButton>
                                 <Modal.Title>Ehindero Kingdavid, Sr. Content Manager</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>Popularly called David, has 6+ years of industry experience. Some of his projects include XcelDefi, Infinity Skies, YFDAI, and BigONE. He is a senior content manager for YUSE and takes care of content development across various platforms and marketing.</Modal.Body>
                              <Modal.Footer>
                                 <Button variant="secondary" onClick={e => this.setState({ modalOpen5: false })}>
                                    Close
                                 </Button>

                              </Modal.Footer>
                           </Modal>
                           <Modal show={this.state.modalOpen6} onHide={e => this.setState({ modalOpen6: false })}>
                              <Modal.Header closeButton>
                                 <Modal.Title>Lord Meghnad Desai, Mentor</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                 Meghnad Jagdishchandra Desai is a British economist and Member of the House of Lords of the United Kingdom. He has been awarded the Padma Bhushan, the third-highest civilian award in the Republic of India, in 2008. He is Professor Emeritus of the London School of Economics.
                              </Modal.Body>
                              <Modal.Footer>
                                 <Button variant="secondary" onClick={e => this.setState({ modalOpen6: false })}>
                                    Close
                                 </Button>

                              </Modal.Footer>
                           </Modal>
                        </div>
                     </div>

                  </div>
                  <Footer1 />
               </div>



            </div>
         </>

      )
   }
}